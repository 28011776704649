import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { l2l1Url } from 'components/nav/path-helpers';
import { JwSymbol } from 'components/branding/jw-symbol';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import { appConfig } from 'app/env';

const { showEnEsCatalogChoice } = appConfig;

const Wrapper = styled('div', {
  background: '$blue-800',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 'var(--sab)',
  paddingTop: 'var(--sat)',

  '& .inner': {
    width: 'min(100% - 32px, 360px)',
    paddingBottom: 16,
    '& .logo': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 40,
      '& img': {
        width: 151,
      },
    },
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      borderTop: '1px solid $white-alpha-20',

      '& li': {
        margin: 0,
        padding: '12px 0',
        borderBottom: '1px solid $white-alpha-20',
        '& a': {
          textDecoration: 'none',
          padding: '4px 0',
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          alignItems: 'center',
          '& .label': {
            color: '$white',
            textStyle: 'small-heading',
            flex: 1,
          },
          '& .icon': {
            width: 32,
            height: 32,
            padding: 6,
            backgroundColor: '$teal-500',
            color: '$white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
          },
        },
      },
    },
  },
});

export const L2Choose = () => {
  // if (forcedL2) {
  //   // const path = `/${forcedL2}`;
  //   // return <Navigate to={path} replace />;
  //   return <ForcedL2WelcomeScreen />;
  // }

  return (
    <Wrapper>
      <div className="inner">
        <div className="logo">
          <JwSymbol
            presentation="colorOnDark"
            css={{ width: 'auto', height: 'auto' }}
          />
        </div>
        <ul>
          <li>
            <a href={'/es' /*l2Url('es')*/}>
              <span className="label">I'm learning Spanish</span>
              <span className="icon">
                <ArrowRightIcon />
              </span>
            </a>
          </li>
          <li>
            <a
              href={
                l2l1Url({ l2: 'en', l1: 'pt' }) /* todo: make slash relative */
              }
            >
              <span className="label">
                Eu falo português
                <br />
                Eu estou aprendendo inglês
              </span>
              {/* <a href={'/en'}>
              <span className="label">Eu estou aprendendo inglês</span> */}
              <span className="icon">
                <ArrowRightIcon />
              </span>
            </a>
          </li>

          {showEnEsCatalogChoice ? (
            <li>
              <a href={l2l1Url({ l2: 'en', l1: 'es' })}>
                <span className="label">
                  Yo hablo español
                  <br />
                  Estoy aprendiendo inglés
                </span>
                <span className="icon">
                  <ArrowRightIcon />
                </span>
              </a>
            </li>
          ) : null}
        </ul>
      </div>
    </Wrapper>
  );
};
