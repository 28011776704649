import React from 'react';
import { openUrl } from '@naan/primitives/text/external-link';
import { appConfig } from 'app/env';
import { NoLink } from '@naan/primitives/text/no-link';

const { emails, emailOptions } = appConfig.website;

// TODO: replace with forms
export const FeedbackLink: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const href = `mailto:${emails.feedback}?subject=${emailOptions.subject}&body=${emailOptions.body}`;
  return (
    // TODO - refactor all window.open usages
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <NoLink onClick={() => openUrl(href)}>{children}</NoLink>
  );
};
