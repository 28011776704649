import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { NotationsList } from './notations-list';
import { SentenceId } from '@tikka/basic-types';
// import { usePlayerModel } from 'player/views/player-model-context';

// import { NotationControls } from './notation-controls';
// import { reaction } from 'mobx';
// import { StudyModel } from 'study/models/study-model';
// import {
//   adjustTopPosForNotationPanel,
//   scrollToCurrentLine,
//   // lineScrollObserver,
// } from '../fx/scrolling';
// import { SentenceId } from '@tikka/basic-types';
// import { SentenceIdContext } from './sentence-id-context';

// const slideIn = keyframes({
//   '0%': { transform: 'translateY(100%)' },
//   '100%': { transform: 'translateY(0)' },
// });

const Wrapper = styled('div', {});

export const SimpleNotationsListPanel = observer(
  ({ sentenceId }: { sentenceId: SentenceId }) => {
    return (
      <Wrapper>
        <div className="inner">
          <NotationsList sentenceId={sentenceId} />
        </div>
      </Wrapper>
    );
  }
);
