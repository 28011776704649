import React from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  id?: string;
  children: React.ReactNode;
};

export class Portal extends React.Component<PortalProps> {
  element: HTMLElement | null = null;

  constructor(props: PortalProps) {
    super(props);
    this.element = document.getElementById(props.id || '');
    if (!this.element) {
      this.element = document.createElement('div');
      this.element.id = props.id || '';
      document.body.appendChild(this.element);
    }
  }

  render() {
    if (this.element) {
      return ReactDOM.createPortal(this.props.children, this.element);
    }
    return null;
  }
}
