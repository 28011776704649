// import { AppFactory } from '@app/app-factory';
import { Story } from '@core/models/story-manager';
import * as React from 'react';

const CurrentStoryContext = React.createContext<Story>({} as any);

export const CurrentStoryProvider: React.FC<
  React.PropsWithChildren<{ story: Story }>
> = ({ story, children }) => {
  // React.useEffect(() => {
  //   if (story) {
  //     AppFactory.root.storyManager.setCurrentStory(story);
  //   }
  // }, [story]);

  return (
    <CurrentStoryContext.Provider value={story}>
      {children}
    </CurrentStoryContext.Provider>
  );
};

export const useCurrentStory = () => React.useContext(CurrentStoryContext);
