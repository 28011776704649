import * as React from 'react';
import { observer } from 'mobx-react';
import { useTitle } from 'common/hooks/use-title';
import { PointsProgressChart } from 'components/ui/points-progress-chart';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { AppFactory } from '@app/app-factory';
import { SimpleStat } from 'components/stats/simple-stat';
import { StreakIcon } from '@naan/icons/streak-icon';
import { TrophyIcon } from '@naan/icons/trophy-icon';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { PageHeading } from 'components/page-heading';
import { PointsIcon } from '@naan/icons/points-icon';
import { HeadphonesIcon } from '@naan/icons/headphones-icon';
import { ListIcon } from '@naan/icons/list-icon';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { styled } from '@naan/stitches.config';
import { useScrollContainerToTop } from 'lib/scroll-utils';
import { useEnableUpdatePrompt } from '@common/hooks/use-enable-update-prompt';
import { daysCount } from '@core/lib/localization-helpers';
import {
  storiesPrimaryFiltered,
  storiesTagFiltered,
} from 'components/nav/path-helpers';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';
import { useNavigate } from 'react-router-dom';
import {
  BookmarkCheckmarkLargeIcon,
  BookmarkFilledLargeIcon,
} from '@naan/icons/bookmark-icon';
import {
  SoundbiteFilterValues,
  vocabToReviewFilterLabelFn,
} from '@core/models/story-manager/story';

import __ from 'core/lib/localization';

const TopStatsGrid = styled('div', {
  display: 'grid',
  gap: 16,
  '@medium': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 24,
    marginBottom: 24,
  },
});

const BottomStatsGrid = styled('div', {
  display: 'grid',
  // gap: 16,

  '@medium': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: '224px',
    gap: 24,
  },
  '@large': {
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridAutoRows: '296px',
    // gap: 24,
  },
});

const TopRow = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const { totalPoints } = userData;
  return (
    <TopStatsGrid>
      <PointsProgressChart />
      <SimpleStat
        icon={<PointsIcon />}
        stat={totalPoints.toString()}
        label={__('Total points', 'totalPoints')}
        presentation="yellow"
        isLarge={true}
      />
    </TopStatsGrid>
  );
});

const MiddleRow = observer(() => {
  const { userManager } = AppFactory.root;
  const { userData } = userManager;
  const navigate = useNavigate();

  const { currentStreak, longestStreak, completedSoundbitesCount } =
    userManager.userData;

  const {
    totalListenedPretty,
    completedStories,
    completedChapters,
    totalSavedVocabs,
    totalLearnedVocab,
  } = userData.storyStats;

  return (
    <>
      <SimpleStat
        icon={<StreakIcon />}
        stat={daysCount(currentStreak)}
        label={__('Current streak', 'currentStreak')}
        presentation="yellow"
      />
      <SimpleStat
        icon={<TrophyIcon />}
        stat={daysCount(longestStreak)}
        label={__('Longest streak', 'longestStreak')}
        presentation="yellow"
      />
      <SimpleStat
        icon={<CheckmarkCircleIcon />}
        stat={completedStories.toString()}
        label={__('Stories completed', 'storiesCompleted')}
        presentation="teal"
        action={() => {
          navigate(storiesPrimaryFiltered(PrimaryFilterKeys.COMPLETED));
        }}
      />

      <SimpleStat
        icon={<ListIcon />}
        stat={completedChapters.toString()}
        label={__('Chapters completed', 'chaptersCompleted')}
        presentation="teal"
      />
      <SimpleStat
        icon={<HeadphonesIcon />}
        stat={totalListenedPretty}
        label={__('Listened', 'listened')}
        presentation="teal"
      />
      <SimpleStat
        icon={<BookmarkCheckmarkLargeIcon />}
        stat={String(totalLearnedVocab)}
        label={__('Vocab learned', 'vocabLearned')}
        presentation="green"
      />
      <SimpleStat
        icon={<BookmarkFilledLargeIcon />}
        stat={String(totalSavedVocabs)}
        label={__('Vocab to review', 'vocabToReview')}
        presentation="purple"
        action={() => {
          navigate(storiesTagFiltered('vocab', vocabToReviewFilterLabelFn()));
        }}
      />

      <SimpleStat
        icon={<SoundbiteIcon />}
        stat={completedSoundbitesCount.toString()}
        label={__('Soundbites completed', 'soundbitesCompleted')}
        presentation="orange"
        action={() => {
          navigate(
            storiesTagFiltered('sb', SoundbiteFilterValues.withCompletedFn())
          );
        }}
      />
    </>
  );
});

export const ProgressScreen = observer(() => {
  useTitle(__('My stats', 'myStats'));
  useEnableUpdatePrompt();
  useScrollContainerToTop();
  // const { hasAdminAccess, userData } = AppFactory.root.userManager;

  return (
    <CenterColumnLayout>
      <PageHeading title={__('My stats', 'myStats')} />
      <TopRow />
      <BottomStatsGrid>
        <MiddleRow />
      </BottomStatsGrid>
      <MediumGap />
    </CenterColumnLayout>
  );
});
