import * as React from 'react';
import classNames from 'classnames';
import { styled } from 'naan/stitches.config';
import { paletteKey } from '../../../study/views/study-palette';
import { PlainMarkdown } from '@naan/primitives/text';
import { SimpleNotationsListPanel } from 'study/views/notations/simple-notations-list';
import { SentenceId } from '@tikka/basic-types';
import { usePlayerModel } from '../player-model-context';
import {
  SoundbiteSentenceToolbar,
  StudySentenceToolbar,
} from './sentence-toolbar';
import { StudyModel } from 'study/models/study-model';
import {
  isSoundbiteModel,
  isStudyModel,
} from 'player/models/player-model-handle';

import { useReaction } from '@common/hooks/use-reaction';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { OnboardingService } from '@app/onboarding/onboarding-service';

const Wrapper = styled('div', {
  '& > .l1': {
    '& .translation-inner': {
      padding: '0 16px 16px 16px',
      // background: 'cyan',
      textStyle: 'body',
      color: paletteKey('$$sentence__inlineTranslationColor'),
    },
  },

  '& .expandable': {
    display: 'grid',
    gridTemplateRows: '0fr',
    transition: 'grid-template-rows .3s',
    '& > div.expandable-inner': {
      gridRow: '1 / span 2',
      overflow: 'hidden',
    },

    '&.expanded': {
      gridTemplateRows: '1fr',
    },
  },
});

const StudySentenceExtras: React.FC<
  React.PropsWithChildren<{
    model: StudyModel;
    translation: string;
    sentenceId: SentenceId /*onUnredact: () => void*/;
  }>
> = ({ model, translation, sentenceId }) => {
  const translationRef = React.useRef<HTMLDivElement>(null);
  const notationsRef = React.useRef<HTMLDivElement>(null);

  useReaction(
    () => model.shouldDisplaySentenceInlineTranslation(sentenceId),
    showing => {
      const element = translationRef.current;
      if (element) {
        if (showing) {
          element.classList.add('expanded');
        } else {
          element.classList.remove('expanded');
        }
      }
    }
  );

  useReaction(
    () => model.shouldDisplaySentenceInlineNotations(sentenceId),
    showing => {
      const element = notationsRef.current;
      if (element) {
        if (showing) {
          // @elliottjf I noticed that the vocab list tip was not working
          // since we switched to the inline notations.
          // There was a nasty bug, so maybe that's why it was disabled
          // if that's the case. I solved the bug and it's working now.
          OnboardingService.instance.onVocabListSeen();
          element.classList.add('expanded');
        } else {
          element.classList.remove('expanded');
        }
      }
    }
  );

  return (
    <Wrapper>
      <div ref={translationRef} className={classNames('l1 expandable')}>
        <div className="expandable-inner">
          <div className="translation-inner">
            <PlainMarkdown source={translation} />
          </div>
        </div>
      </div>
      <div ref={notationsRef} className={classNames('notations expandable')}>
        <div className="expandable-inner">
          <SimpleNotationsListPanel sentenceId={sentenceId} />
        </div>
      </div>
      <StudySentenceToolbar model={model} sentenceId={sentenceId} />
    </Wrapper>
  );
};

const SoundbiteSentenceExtras: React.FC<
  React.PropsWithChildren<{
    model: SoundbiteModel;
    translation: string;
    sentenceId: SentenceId /*onUnredact: () => void*/;
  }>
> = ({ model, translation, sentenceId }) => {
  const translationRef = React.useRef<HTMLDivElement>(null);
  // const notationsRef = React.useRef<HTMLDivElement>(null);

  useReaction(
    () => model.shouldDisplaySentenceInlineTranslation(sentenceId),
    showing => {
      const element = translationRef.current;
      if (element) {
        if (showing) {
          element.classList.add('expanded');
        } else {
          element.classList.remove('expanded');
        }
      }
    }
  );

  return (
    <Wrapper>
      <div ref={translationRef} className={classNames('l1 expandable')}>
        <div className="expandable-inner">
          <div className="translation-inner">
            <PlainMarkdown source={translation} />
          </div>
        </div>
      </div>
      <SoundbiteSentenceToolbar model={model} sentenceId={sentenceId} />
    </Wrapper>
  );
};

export const SentenceExtrasGeneral: React.FC<
  React.PropsWithChildren<{
    translation: string;
    sentenceId: SentenceId /*onUnredact: () => void*/;
  }>
> = ({ translation, sentenceId }) => {
  const model = usePlayerModel();
  // if (!isStudyModel(model)) {
  //   // don't show anything if we're not in study mode
  //   return null;
  // }

  if (isSoundbiteModel(model)) {
    return (
      <SoundbiteSentenceExtras
        translation={translation}
        sentenceId={sentenceId}
        model={model}
      />
    );
  }

  if (isStudyModel(model)) {
    return (
      <StudySentenceExtras
        translation={translation}
        sentenceId={sentenceId}
        model={model}
      />
    );
  }

  // shuld never reach this state, but it's for TS benefit
  return null;
};
