import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { createLogger } from 'app/logger';
import { PlayerMode } from '@common/misc-types';
import { StudyData } from '@tikka/client/catalog-types';
import { GenericError } from '@core/lib/errors';
import { Story } from '@core/models/story-manager/story';
import { VocabReviewModel } from '@core/models/ui/vocab-review-model';

import __ from '@core/lib/localization';

const log = createLogger('study-screen');

export interface LoaderState {
  model?: VocabReviewModel;
  failure?: Error;
}

export const useVocabReviewLoader = (story: Story) => {
  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { model, failure } = loaderState;

  React.useEffect(() => {
    if (model === undefined) {
      prepareModel(story)
        .then(model => setLoaderState({ model }))
        .catch(failure => setLoaderState({ failure }));
      return;
    }

    if (!model || failure) {
      return;
    }
  }, [model, failure, story]);

  return {
    model,
    failure,
  };
};

const prepareModel = async (story: Story): Promise<VocabReviewModel> => {
  log.info(`prepareModel: ${story.slug}`);

  const { storyManager } = AppFactory.root;

  if (!story) {
    return null;
  }

  if (story.locked) {
    throw new GenericError(`study screen - invalid access (story locked)`, {
      userMessage: __('Subscription required', 'subscriptionRequired'),
    });
  }

  const storyProgress = story.progressMayBeNull;
  const volumeData = await storyManager.loadVolumeDataUrl(story.volumeDataUrl);

  let vocabReviewModel = new VocabReviewModel({
    volumeData,
    storyProgress,
  });

  // chapter level resume state already set during construction
  await initializeChapterReviewModel(
    vocabReviewModel,
    vocabReviewModel.currentChapterPosition
  );

  return vocabReviewModel;
};

// beware, this is used for both the initial load (chapterPosition = 0) and
// for loading subsequent chapters (chapterPosition > 0)
export const initializeChapterReviewModel = async (
  vocabReviewModel: VocabReviewModel,
  chapterPosition: number = 0
): Promise<void> => {
  log.info(
    `initializeChapterReviewModel: ${vocabReviewModel.currentChapterPosition}`
  );

  const chapterData = vocabReviewModel.chapters[chapterPosition]?.chapterData;
  if (!chapterData) {
    log.error(`initializeChapterReviewModel - no chapter data`);
    throw new GenericError(`initializeChapterReviewModel - no chapter data`);
  }

  const response = await AppFactory.assetCacher.maybeCachedResponse(
    chapterData.playerDataUrl
  );
  const studyData = (await response.json()) as StudyData;
  const chapterReviewModel = AppFactory.newChapterReviewModel();
  await chapterReviewModel.initFromStudyData(studyData);

  await chapterReviewModel.initChapterSession({
    chapter: chapterData,
    playerMode: PlayerMode.VOCAB_REVIEW,
    startMillis: 0,
    sessionCounter: 0,
  });
  log.debug('after initChapterSession');

  // always go stright to chapter view when resuming
  // if (chapterPosition === 0 && vocabReviewModel.currentChapterHasSkipped) {
  //   log.debug('skipped start of chapter modal');
  //   chapterReviewModel.hideOverlay();
  // }

  vocabReviewModel.setChapterReviewModel(chapterReviewModel, chapterPosition);

  chapterReviewModel.setReady(); // if not set, then model.dispose is short circuited. @jason, please confirm if this makes sense or isn't relevant

  if (chapterPosition === 0 && vocabReviewModel.hasSkipped) {
    chapterReviewModel.hideOverlay();
    vocabReviewModel.showResumeModal();
    // chapterReviewModel.showing
    // AppFactory.dialogPresenter.present(onDismiss => {
    //   return (
    //     <ResumeReviewDialog
    //       vocabReviewModel={vocabReviewModel}
    //       onDismiss={onDismiss}
    //     />
    //   );
    // });
  }
};
