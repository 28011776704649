import * as React from 'react';

import { CloseIcon } from '@naan/icons/close-icon';
import { PlayTriangleIcon } from '@naan/icons/play-triangle-icon';
import { RewindIcon } from '@naan/icons/rewind-icon';
import { styled } from '@naan/stitches.config';
import { PlayerViewController } from 'components/ui/video-player/player-view-controller';
import { observer } from 'mobx-react';
import { OverlayButton } from 'components/ui/video-player/video-player2/overlay-button';
import { Button } from '@naan/primitives/button';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  padding: 48,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  background: 'rgba(0, 0, 0, 0.40)',
  backdropFilter: 'blur(6px)',
  zIndex: 2,

  '& > .skip-button': {
    position: 'absolute',
    bottom: 16,
    right: 16,
  },

  '& > .complete-button': {
    position: 'absolute',
    bottom: 16,
    right: 16,
    left: 16,
  },
});

export const PausedOverlay = observer(
  ({
    player,
    videoWatched,
    onExit,
    onSkip,
    onComplete,
  }: {
    player: PlayerViewController;
    videoWatched: boolean;
    onExit: () => void;
    onSkip: () => void;
    onComplete: () => void;
  }) => {
    return (
      <Wrapper className="paused-overlay">
        <OverlayButton
          presentation={'teal'}
          leftIcon={<PlayTriangleIcon />}
          label={__('Resume', 'resume')}
          onClick={() => {
            void player.videoElement.play();
          }}
        />
        <OverlayButton
          leftIcon={<RewindIcon />}
          label={__('Restart', 'restart')}
          onClick={() => {
            player.videoElement.currentTime = 0;
            void player.videoElement.play();
          }}
        />
        <OverlayButton
          leftIcon={<CloseIcon />}
          label={__('Exit', 'exit')}
          onClick={() => {
            player.videoElement.pause();
            onExit();
          }}
        />
        {videoWatched ? (
          <div className="complete-button">
            <OverlayButton
              presentation="teal"
              label={__("Let's go", 'letsGo')}
              rightIcon={<ArrowRightIcon />}
              onClick={() => {
                onComplete();
              }}
            />
          </div>
        ) : (
          <div className="skip-button">
            <Button
              presentation="whiteDarken"
              size="small"
              label={__('Skip video', 'skipVideo')}
              onClick={() => {
                onSkip();
              }}
            />
          </div>
        )}
      </Wrapper>
    );
  }
);
