import * as React from 'react';

export function CheckmarkCircleSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.75 7.25516L12.6715 6.5L9.0216 11.7126L6.50516 9.9506L5.75 11.0291L9.34492 13.5463L13.75 7.25516Z"
        fill={color}
      />
    </svg>
  );
}

export function CheckmarkCircleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.8284 8.67738L15.5034 7.74963L11.0193 14.1536L7.92775 11.9889L7 13.3138L11.4165 16.4063L16.8284 8.67738Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function CheckmarkCircleExtraExtraLargeIcon({
  width = 40,
  height = 40,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 20C37 29.3888 29.3888 37 20 37C10.6112 37 3 29.3888 3 20C3 10.6112 10.6112 3 20 3C29.3888 3 37 10.6112 37 20ZM28.2083 14.3516L25.9559 12.7744L18.3329 23.6612L13.0772 19.9811L11.5 22.2335L19.0081 27.4908L28.2083 14.3516Z"
        fill={color}
      />
    </svg>
  );
}
