import * as React from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from '@common/hooks/use-onclick-outside';
import { keyboardService } from 'lib/services/keyboard-service';
import { HtmlContent } from '../../naan/primitives/modals/dialog/dialog-content';
import { OverlayDiv } from '../../naan/primitives/modals/dialog/dialog-overlay';
import { useKeyboardBinding } from '@common/hooks/use-keyboard-binding';

type ContainerProps = {
  onDismiss: () => void;
  contentComponent?: typeof HtmlContent;
};

function createDiv() {
  const id = 'dialog-portal';
  const existingDiv = document.getElementById(id);
  if (existingDiv) {
    return existingDiv;
  }
  const element = document.createElement('div');
  document.body.appendChild(element);
  element.id = id;
  element.classList.add('fsportal');
  return element;
}

function Portal(props: any) {
  const node = React.useRef(createDiv());

  React.useEffect(() => {
    const reset = keyboardService.stopCurrentShortcutSet();
    const element = node.current;
    return () => {
      if (element) {
        /// under some circumstances the element reference we keep has been removed from the DOM Tree
        /// so document.body.removeChild fails with an error
        // document.body.removeChild(element);
        const parent = element.parentElement;
        parent?.removeChild(element);
        reset();
      }
    };
  }, []);

  return ReactDOM.createPortal(props.children, node.current);
}

type HTMLContentProps = React.ComponentProps<typeof HtmlContent>;

export const DialogHtmlContainer: React.FC<
  React.PropsWithChildren<ContainerProps & HTMLContentProps>
> = ({
  onDismiss,
  children,
  contentComponent: Content = HtmlContent,
  ...props
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onDismiss);
  useKeyboardBinding('Escape', onDismiss);
  React.useLayoutEffect(() => {
    const element = ref.current;
    if (element) {
      // This is a hack to get the focus to work properly.
      window.setTimeout(() => {
        element.focus();
      }, 100);
    }
  }, []);

  return (
    <Portal>
      <OverlayDiv>
        <Content
          ref={ref}
          id="element"
          tabIndex={-1}
          {...props}
          // css={{
          //   '&:focus': {
          //     outline: '$colors$black-alpha-10 solid 2px',
          //   },
          // }}
        >
          {children}
        </Content>
      </OverlayDiv>
    </Portal>
  );
};
