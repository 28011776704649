import * as React from 'react';
import { createLogger } from 'app/logger';
import { Navigate } from 'react-router-dom';
import { FullScreenLoader } from 'components/ds/modals';
import { learnStorySlugPath } from 'components/nav/path-helpers';
import { isNetworkError } from '@core/lib/error-handling';
import { bugsnagNotify, sanitizeErrorMessage } from '@app/notification-service';
import { presentSimpleAlert } from 'components/ui/simple-dialogs';
import { NotFoundScreen } from 'routes/not-found-screen';
import { VocabReviewUI } from 'vocab-review/review-ui';
import { Story } from '@core/models/story-manager/story';
import { useVocabReviewLoader } from 'vocab-review/use-vocab-review-loader';
import { LoadingStatus } from 'player/models/player-model';
import { stopPlayerKeyboardControls } from 'player/views/player-controls/player-keyboard-controls';
import { BlockingInterstitial } from '@app/onboarding/blocking-interstitial';
import { getSourceForId } from 'components/ui/video-player/player-view-controller';
import { VideoPlayer2 } from 'components/ui/video-player/video-player2/player';
import { achieve, didAchieve } from '@app/onboarding/achievements';
// import { observer } from 'mobx-react';
import { useSwitch2 } from '@naan/hooks/use-switch-2';

import { appConfig } from 'app/config';
import { getVideoAssets } from 'video-assets/vocab-review';

// import subtitles from 'video-assets/subtitles.vtt?url';

const log = createLogger('study-screen');

const VocabReviewModalContent = ({
  story,
  onDismiss,
}: {
  story: Story;
  onDismiss: () => void;
}) => {
  const { model: vocabReviewModel, failure } = useVocabReviewLoader(story);

  const handleClosing = React.useCallback(() => {
    stopPlayerKeyboardControls();
    onDismiss();
  }, [onDismiss]);

  /// call handleClosing when the browser back button is pressed
  /// so we can avoid the modal being left open
  React.useEffect(() => {
    window.addEventListener(
      'popstate',
      () => {
        handleClosing();
      },
      { once: true }
    );
  }, [handleClosing]);

  log.info(
    `VocabReviewScreen render - model: ${String(
      !!vocabReviewModel
    )}, failure: ${String(failure)}`
  );

  if (failure) {
    // beware, duplicated code from vocab content error handling
    // todo: figure out how to better factor this

    const error = failure instanceof Error ? failure : Error(String(failure));

    log.error(`prepareModel failed: ${error?.stack}`);
    if (!isNetworkError(error)) {
      bugsnagNotify(error);
    }
    const message = sanitizeErrorMessage(error);
    handleClosing();
    presentSimpleAlert(<>{message}</> /*, onErrorAlertDismiss*/);

    return <Navigate to={learnStorySlugPath(story?.slug)} replace />;
  }

  const chapterReviewModel = vocabReviewModel?.chapterReviewModel;
  if (
    /*chapterReviewModel === undefined ||*/ chapterReviewModel?.loadingStatus !==
    LoadingStatus.READY
  ) {
    return <FullScreenLoader />;
  }

  if (chapterReviewModel === null) {
    return <NotFoundScreen reportError={false} />;
  }

  return (
    <VocabReviewUI
      vocabReviewModel={vocabReviewModel}
      onDismiss={handleClosing}
    />
  );
};

const VocabReviewVideoInterstitial = ({
  onForcePass,
  onDismiss,
}: {
  onForcePass: () => void;
  onDismiss: () => void;
}) => {
  const { videoId, subtitles } = getVideoAssets();

  React.useEffect(() => {
    window.addEventListener(
      'popstate',
      e => {
        e.preventDefault();
        onDismiss();
      },
      { once: true }
    );
  }, [onDismiss]);

  const videoSrc = getSourceForId(videoId);
  return (
    <VideoPlayer2
      videoSrc={videoSrc}
      subtitlesUrl={subtitles}
      videoWatchedFn={() => didAchieve(['video:vocab-review'])}
      onEnded={() => {
        achieve('video:vocab-review');
      }}
      onComplete={() => {
        onForcePass();
      }}
      onExit={() => {
        onDismiss();
      }}
      onSkip={() => {
        achieve('video:vocab-review');
      }}
    />
  );
};

export const VocabReviewModal =
  // @armando, this observer was triggering a rerender when we marked the video watched,
  // but i don't think there's any legitimate reason to rerender at this level.
  /*observer(*/
  ({ story, onDismiss }: { story: Story; onDismiss: () => void }) => {
    /// this condition allows for temoporarily forcing the pass condition
    /// so the user can do the secondary achievement
    /// but it will be reset on the next page load
    const forcePass = useSwitch2(false);

    if (!appConfig.enableVideoOnboarding) {
      return <VocabReviewModalContent story={story} onDismiss={onDismiss} />;
    }

    return (
      <BlockingInterstitial
        passCondition={
          forcePass.value ||
          didAchieve(['video:vocab-review', 'action:reviewed-an-item'])
        }
        renderInterstitial={() => (
          <VocabReviewVideoInterstitial
            onForcePass={() => forcePass.on()}
            onDismiss={onDismiss}
          />
        )}
      >
        <VocabReviewModalContent story={story} onDismiss={onDismiss} />;
      </BlockingInterstitial>
    );
  };
/*)*/
