import { millisToClockLikeDuration } from './../../lib/pretty-duration';
import { getParentOfType, ModelTreeNode } from 'ts-state-tree/tst-core';
import { createLogger } from 'app/logger';
import { UnitCatalogData } from './unit-catalog-data';
import { Story } from '../story-manager/story';
import { millisToPrettyDuration } from 'core/lib/pretty-duration';
import { ChapterRef, LocationPointer } from '../user-manager/location-pointer';
import { msToPoints } from '../user-manager/listening-stats';
import { bugsnagNotify } from '@app/notification-service';
import { Soundbite } from '../story-manager/soundbite';
import { formatProgress } from '@utils/string-utils';

import __ from 'core/lib/localization';
// import { AssetCacher } from 'lib/asset-cacher';

const log = createLogger('chapter-catalog-data');

export type ChapterNote = {
  key: string;
  unit: number;
  chapter: number;
  index: number;
  body: string;
};

// correlates to ChapterCaliData masala schema
export class ChapterCatalogData extends ModelTreeNode {
  static CLASS_NAME = 'ChapterCatalogData' as const;

  // beware, there is no 'slug' property in the currently generated bogota data
  // @identifier
  // slug: string = '';

  title: string = '';
  storySlug: string = '';
  // unitSlug: string = '';
  unitNumber: number = 1;
  position: number = 0; // '1' based chapter position // rename?
  durationMillis: number = 0;
  markCompleteMillis: number = 0;
  normalAudioUrl: string = '';
  noMusicAudioUrl: string = null;
  playerDataUrl: string = null;

  notes: { id: string; body: string /*head: string*/ }[];
  passages: { id: string; hint: string }[];

  static create(snapshot: any) {
    return super.create(ChapterCatalogData, snapshot) as ChapterCatalogData;
  }

  get story(): Story {
    return this.unitData?.story;
  }

  get unitData(): UnitCatalogData {
    return getParentOfType(this, UnitCatalogData);
  }

  get chapterRef(): ChapterRef {
    return { unit: this.unitNumber, chapter: this.position };
  }

  get sortingRef(): number {
    return this.unitNumber * 100 + this.position;
  }

  get chapterNotes(): ChapterNote[] {
    return this.notes.map((data, index) => ({
      key: `${this.unitNumber}-${this.position}-${index}`,
      unit: this.unitNumber,
      chapter: this.position,
      index: index,
      body: data.body,
    }));
  }

  get place(): 'first' | 'last' | undefined {
    return this.position === 1 ? 'first' : this.isLastOne ? 'last' : undefined;
  }

  unlock() {
    log.info(`unlock chapter ${this.position}`);
    this.story.progress.unlockChapter(this.chapterRef);
  }

  // return to a completed chapter
  review() {
    this.story.progress.reviewChapter(this.chapterRef);
  }

  resumeStudy() {
    this.story.progress.resumeStudy();
  }

  markComplete() {
    log.info(`markComplete chapter ${this.position}`);
    this.story.progress.markCompleteChapter(this.chapterRef);
  }

  restartChapter() {
    log.info(`restart chapter ${this.position}`);
    this.story.progress.restartAtChapter(this.chapterRef);
  }

  get timeLeftMillis(): number {
    if (!this.story?.hasProgress) return 0;
    return this.durationMillis - this.currentPoint?.millisPlayed || 0;
  }

  // todo: confirm exact definition of progress
  // i.e. study/fluent listen
  get progressMillis(): number {
    if (!this.story?.hasProgress) return 0;
    if (this.isCompleted) {
      return this.durationMillis;
    }
    // return this.isFurthest ? this.furthestPoint.millisPlayed : 0;
    if (this.isFurthest) {
      return this.furthestPoint?.iteration === 1
        ? this.furthestPoint?.millisPlayed
        : this.durationMillis; // give full credit for chapter progress while relistening
    } else {
      return 0;
    }
  }

  get currentMillis(): number {
    if (!this.story?.hasProgress) return 0;
    return this.isCurrent ? this.currentPoint?.millisPlayed || 0 : 0;
  }

  get midListen(): boolean {
    return this.currentMillis > 0;
  }

  get atEnd(): boolean {
    return this.currentMillis >= this.durationMillis;
  }

  get currentPercentage(): number {
    return Math.round((100 * this.currentMillis) / this.durationMillis);
  }

  // todo: reflect relation to this chapter
  get currentPoint(): LocationPointer | null {
    return this.story.progressMayBeNull?.currentPoint; // || LocationPointer.create({});
  }

  get furthestPoint(): LocationPointer | null {
    return this.story.progressMayBeNull?.furthestPoint; // || LocationPointer.create({});
  }

  get isCurrent(): boolean {
    if (!this.story?.hasProgress) return false;
    if (this.story.unstarted) return false;
    return this.currentPoint?.matchesChapter(this.chapterRef) || false;
  }

  get isFurthest(): boolean {
    // todo: refactor to use matchesPoint
    if (!this.story?.hasProgress) return false;
    if (this.story.unstarted) return false;
    return this.furthestPoint?.matchesChapter(this.chapterRef) || false;
  }

  get unstarted(): boolean {
    return this.story.unstarted || this.story.locked;
  }

  get isLastOne(): boolean {
    return this.position === this.unitData.chapterCount;
  }

  matchesPoint(point: ChapterRef): boolean {
    if (!point) {
      bugsnagNotify('matchesPoint - null param');
      return false;
    }
    return point.chapter === this.position && point.unit === this.unitNumber;
  }

  // true if this chapter is before the referenced location
  isBefore(point: ChapterRef): boolean {
    if (!point) {
      bugsnagNotify('isBefore - null param');
      return false;
    }
    return (
      this.unitNumber < point.unit ||
      (this.unitNumber === point.unit && this.position < point.chapter)
    );
  }

  get isFirstListenComplete(): boolean {
    return (
      this.isCompleted ||
      (this.isCurrent && (this.furthestPoint?.iteration || 1) > 1)
    );
  }

  get isSecondListenComplete(): boolean {
    return (
      this.isCompleted ||
      (this.isCurrent && (this.furthestPoint?.iteration || 1) > 2)
    );
  }

  get showMarkCompleteAction(): boolean {
    return this.isCurrent && this.isFurthest && this.isFirstListenComplete;
  }

  get defaultMarkCompleteAction(): boolean {
    return this.showMarkCompleteAction && this.isSecondListenComplete;
  }

  get isReviewing(): boolean {
    return this.isCurrent && !this.isFurthest;
  }

  get atBeginning(): boolean {
    // if (!this.isCurrent) {
    //   return true; // not sure about this case, or if even relevant
    // }
    return this.currentMillis === 0;
  }

  // get studyActionLabel(): string {
  //   if (this.isCurrent) {
  //     if (this.atBeginning) {
  //       if (this.isFirstListenComplete) {
  //         return __('Study', 'study');
  //       } else {
  //         return __('Start study', 'startStudy');
  //       }
  //     } else {
  //       return __('Continue study', 'continueStudy');
  //     }
  //   } else {
  //     if (this.isCompleted) {
  //       return __('Study again', 'studyAgain');
  //     } else {
  //       return __('Start chapter', 'startChapter');
  //     }
  //   }
  //   // const studyLabel = this.isReviewing
  //   // ? 'Study'
  //   // : studyComplete
  //   // ? 'Study again'
  //   // : chapter.studyInProgress
  //   // ? 'Continue studying'
  //   // : 'Start study';
  // }

  get chapterLabel(): string {
    // todo: support a content level override
    return __('Chapter %{position}', 'chapterPosition', {
      position: this.position,
    });
  }

  get partChapterLabel(): string {
    const joinChar = ' • ';
    return this.story.multiUnit
      ? this.unitData.partLabel + joinChar + this.chapterLabel
      : this.chapterLabel;
  }

  get inProgress(): boolean {
    return this.isCurrent && this.currentMillis > 0;
  }

  get isStarted(): boolean {
    return this.isFirstListenComplete || this.inProgress; // could be misleading if furthest and not current, but probably not relevant
  }

  get isCompleted(): boolean {
    if (!this.story?.hasProgress) return false;
    const { furthestPoint } = this.story.progress;
    return this.isBefore(furthestPoint);
  }

  get currentPlayProgress(): number {
    if (!this.story?.hasProgress) return 0;
    if (!this.isCurrent) {
      return 0;
    }
    const { currentPoint } = this.story.progress;
    return currentPoint.millisPlayed / this.durationMillis;
  }

  get furthestPlayProgress(): number {
    if (!this.story?.hasProgress) return 0;
    if (!this.isFurthest) {
      return 0;
    }
    if (!this.story) return 0;
    const { furthestPoint } = this.story.progress;
    // not sure why this wasn't working in web-proto - revisit later
    // this.$log.info(`furthestPlayProgress - millisPlayed ${furthestPoint.millisPlayed}, duration: ${this.durationMillis}`);
    return furthestPoint.millisPlayed / this.durationMillis;
  }

  // points to be awarded when chapter completed
  get progressPoints(): number {
    return msToPoints(this.durationMillis);
  }

  get durationInWords(): string {
    if (!this.story?.hasProgress) return '';
    if (this.isCurrent) {
      const durationString = millisToPrettyDuration(this.timeLeftMillis);
      if (this.story.progress.currentPoint.millisPlayed > 0.0) {
        return __('%{duration} remaining', 'durationRemaining', {
          duration: durationString,
        });
      }
      return durationString;
    }

    return millisToPrettyDuration(this.durationMillis);
  }

  get prettyDuration(): string {
    return millisToPrettyDuration(this.durationMillis);
  }

  get clockLikeDuration() {
    return millisToClockLikeDuration(this.durationMillis);
  }

  // get showUnlock() {
  //   return this.isLocked || this.isSemiLocked;
  // }

  get showMarkComplete() {
    return this.isFurthest && !this.isCompleted; // furthest can probably never be complete, but no harm in the redundancy
  }

  get hasPreviousChapter() {
    return this.position > 1 || this.unitNumber > 1;
  }

  get hasPreviousUnitChapter() {
    return this.position > 1;
  }

  get hasNextChapter() {
    const unitData = getParentOfType(this, UnitCatalogData);
    return (
      this.position < unitData.chapterCount ||
      this.unitNumber < (unitData?.story?.unitCount || 1) // being paranoid
    );
  }

  get hasNextUnitChapter() {
    const unitData = getParentOfType(this, UnitCatalogData);
    return this.position < unitData.chapterCount;
  }

  get nextChapterPath() {
    if (!this.hasNextChapter) {
      return null;
    }
    const unitData = getParentOfType(this, UnitCatalogData);
    if (this.position < unitData.chapterCount) {
      return `${unitData.slug}/${this.position + 1}`;
    } else {
      // todo: resolve by unitNumber match, not order
      const nextUnit = unitData.story.unitDataByNumber(this.unitNumber + 1);
      if (!nextUnit) {
        log.error(
          `nextChapterPath - didn't resolve unit num: ${this.unitNumber + 1}`
        );
        return null;
      }
      return `${nextUnit.slug}/1`;
    }
  }

  get chapterSoundbites() {
    return this.story?.visibleSoundbites?.filter(
      sb => sb.chapterPos === this.position && sb.unitNumber === this.unitNumber
    );
  }

  get completedSoundbitesCount(): number {
    return this.chapterSoundbites.filter(soundbite => soundbite.completed)
      .length;
  }

  get incompleteSoundbitesCount(): number {
    return this.chapterSoundbites.filter(soundbite => !soundbite.completed)
      .length;
  }

  get hasSoundbites() {
    return this.soundbitesCount > 0;
  }

  get soundbitesCount(): number {
    return this.chapterSoundbites.length;
  }

  get firstIncompleteSoundbiteIndex(): number {
    return this.chapterSoundbites.findIndex((soundbite: Soundbite) => {
      return !soundbite.completed;
    });
  }

  get firstIncompleteSoundbite(): Soundbite {
    return this.chapterSoundbites[this.firstIncompleteSoundbiteIndex];
  }

  get hasAnyIncompleteSoundbite() {
    return this.incompleteSoundbitesCount > 0;
  }

  soundbiteDisplayProgress(omitZero = true): string {
    return formatProgress(
      this.completedSoundbitesCount,
      this.chapterSoundbites.length,
      omitZero
    );
  }

  get hasIncompleteSoundbites(): boolean {
    return this.completedSoundbitesCount < this.chapterSoundbites.length;
  }

  get soundbiteDisplayProgressBadge(): string {
    return `${this.completedSoundbitesCount}/${this.chapterSoundbites.length}`;
  }

  get allChapterSoundbitesCompleted(): boolean {
    return this.chapterSoundbites.every(soundbite => soundbite.completed);
  }
}
