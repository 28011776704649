import * as React from 'react';

export const ShortcutItem = ({
  title,
  shortcut,
}: {
  title: string;
  shortcut: string | React.ReactNode[];
}) => {
  return (
    <div className="shortcut">
      <span className="description">{title}</span>
      {Array.isArray(shortcut) ? (
        shortcut.map((s, i) => (
          <kbd className="key" key={i}>
            {s}
          </kbd>
        ))
      ) : (
        <kbd className="key">{shortcut}</kbd>
      )}
    </div>
  );
};
