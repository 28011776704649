import { scrollContainerToTop } from 'lib/scroll-utils';
import {
  scrollToCurrentChapter,
  // scrollToTabBar,
} from 'routes/learn/scrolling-fx';
import { Story } from '@core/models/story-manager';
import { action, makeObservable, observable } from 'mobx';

// import { createLogger } from 'app/logger';

// const log = createLogger('story-detail-ui-model');

// const autoscrollTypes = ['top', 'tabBar', 'chapter', 'min'];

/// Lifter up the state because there was a rerender that was ruining the scrolling effect
class StoryDetailUiModel {
  // @armando, restoring here the previous observable properties to fix the manage vocab modal,
  // but not sure if all of these observables are actually needed
  @observable
  public story: Story | null = null;

  @observable
  public showTitleInHeader = true;
  @observable
  public solidHeader = true;
  public currentWrapper: HTMLDivElement | null = null;
  @observable
  public pendingExpandTransition: boolean = false;

  @observable
  public showingSoundbitesModal = false;
  @observable
  public showingVocabListModal = false;

  @observable
  showingVocabReviewModal = false;
  @observable
  public showingChannelInfoModal = false;

  // public currentTab: string | null = null;
  // public firstLanding: boolean = true;

  // public nextAutoscroll: typeof autoscrollTypes[number] = 'top';

  constructor() {
    makeObservable(this);
    this.resolveQueryParams();
  }

  resolveQueryParams() {
    const params = new URLSearchParams(window.location.search);
    const showSoundbites = params.get('soundbites');
    if (showSoundbites) {
      this.showSoundbitesModal();
    }
  }

  @action
  public setShowTitleInHeader(showTitleInHeader: boolean) {
    this.showTitleInHeader = showTitleInHeader;
  }

  @action
  public setSolidHeader(solidHeader: boolean) {
    this.solidHeader = solidHeader;
  }

  public executeAutoscroll() {
    const started = this.story?.started;
    if (started) {
      scrollToCurrentChapter();
    } else {
      scrollContainerToTop();
    }
  }

  @action
  public setStory(story: Story) {
    this.story = story;
  }

  @action
  public setCurrentWrapper(wrapper: HTMLDivElement | null) {
    this.currentWrapper = wrapper;
  }

  imperativeCollapseChapterContainer = () => {
    const element = this.currentWrapper;
    return new Promise<void>(resolve => {
      element.ontransitionend = () => {
        resolve();
      };
      // element.classList.toggle('collapsed');
      element.style.gridTemplateRows = '0fr';
    });
  };

  imperativeExpandChapterContainer = () => {
    const element = this.currentWrapper;
    return new Promise<void>(resolve => {
      element.ontransitionend = () => {
        resolve();
      };
      // element.classList.toggle('collapsed');
      element.style.gridTemplateRows = '1fr';
    });
  };

  @action
  setPendingExpandTransition = (pending: boolean) => {
    this.pendingExpandTransition = pending;
  };

  @action
  showSoundbitesModal = () => {
    this.showingSoundbitesModal = true;
  };

  @action
  hideSoundbitesModal = () => {
    this.showingSoundbitesModal = false;
  };

  @action
  showVocabListModal = () => {
    this.showingVocabListModal = true;
  };

  @action
  hideVocabListModal = () => {
    this.showingVocabListModal = false;
  };

  @action
  showVocabReviewModal = () => {
    this.showingVocabReviewModal = true;
  };

  @action
  hideVocabReviewModal = () => {
    this.showingVocabReviewModal = false;
  };

  @action
  showChannelInfoModal = () => {
    this.showingChannelInfoModal = true;
  };

  @action
  hideChannelInfoModal = () => {
    this.showingChannelInfoModal = false;
  };

  public reset() {
    this.story = null;
    this.showTitleInHeader = true;
    this.solidHeader = true;
    // this.currentTab = null;
    // this.firstLanding = true;
  }
}

export const storyDetailUiModel = new StoryDetailUiModel();

(window as any).storymodel = storyDetailUiModel;
