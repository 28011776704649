import * as React from 'react';

export function SoundbiteIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9 4C14.9 3.44772 15.3477 3 15.9 3H16.7C17.2523 3 17.7 3.44772 17.7 4V20C17.7 20.5523 17.2523 21 16.7 21H15.9C15.3477 21 14.9 20.5523 14.9 20V4ZM6.29999 7C6.29999 6.44772 6.7477 6 7.29999 6H8.09999C8.65227 6 9.09999 6.44772 9.09999 7V17C9.09999 17.5523 8.65227 18 8.09999 18H7.29999C6.7477 18 6.29999 17.5523 6.29999 17V7ZM2 11C2 10.4477 2.44772 10 3 10H3.8C4.35229 10 4.8 10.4477 4.8 11V13C4.8 13.5523 4.35228 14 3.8 14H3C2.44771 14 2 13.5523 2 13V11ZM11.6 8C11.0477 8 10.6 8.44772 10.6 9V15C10.6 15.5523 11.0477 16 11.6 16H12.4C12.9523 16 13.4 15.5523 13.4 15V9C13.4 8.44772 12.9523 8 12.4 8H11.6ZM20.2 10C19.6477 10 19.2 10.4477 19.2 11V13C19.2 13.5523 19.6477 14 20.2 14H21C21.5523 14 22 13.5523 22 13V11C22 10.4477 21.5523 10 21 10H20.2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function SoundbiteIconExtraSmall({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2C12.4477 2 12 2.44772 12 3L12 13C12 13.5523 12.4477 14 13 14C13.5523 14 14 13.5523 14 13L14 3C14 2.44772 13.5523 2 13 2ZM6.33301 4C5.78072 4 5.33301 4.44772 5.33301 5L5.33301 11C5.33301 11.5523 5.78072 12 6.33301 12C6.88529 12 7.33301 11.5523 7.33301 11L7.33301 5C7.33301 4.44772 6.88529 4 6.33301 4ZM3 6.5C2.44772 6.5 2 6.94772 2 7.5L2 8.5C2 9.05228 2.44772 9.5 3 9.5C3.55228 9.5 4 9.05228 4 8.5V7.5C4 6.94772 3.55228 6.5 3 6.5ZM8.66602 6C8.66602 5.44772 9.11373 5 9.66602 5C10.2183 5 10.666 5.44772 10.666 6L10.666 10C10.666 10.5523 10.2183 11 9.66602 11C9.11373 11 8.66602 10.5523 8.66602 10V6Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

/*
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M13 2C12.4477 2 12 2.44772 12 3L12 13C12 13.5523 12.4477 14 13 14C13.5523 14 14 13.5523 14 13L14 3C14 2.44772 13.5523 2 13 2ZM6.33301 4C5.78072 4 5.33301 4.44772 5.33301 5L5.33301 11C5.33301 11.5523 5.78072 12 6.33301 12C6.88529 12 7.33301 11.5523 7.33301 11L7.33301 5C7.33301 4.44772 6.88529 4 6.33301 4ZM3 6.5C2.44772 6.5 2 6.94772 2 7.5L2 8.5C2 9.05228 2.44772 9.5 3 9.5C3.55228 9.5 4 9.05228 4 8.5V7.5C4 6.94772 3.55228 6.5 3 6.5ZM8.66602 6C8.66602 5.44772 9.11373 5 9.66602 5C10.2183 5 10.666 5.44772 10.666 6L10.666 10C10.666 10.5523 10.2183 11 9.66602 11C9.11373 11 8.66602 10.5523 8.66602 10V6Z" fill="white"/>
</svg>

*/
