import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { ChevronRightSmallIcon } from '@naan/icons/chevron-right-icon';

const Container = styled('div', {
  $$iconSize: '48px',
  $$columns: 3,
  height: '100%',
  position: 'relative',
  padding: '16px 0',
  paddingLeft: 'calc( 48px + 12px)',

  borderBottom: '1px solid $colors$gray-100',

  '& > .icon': {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '@large': {
    // height: 296,
  },

  '& > .icon svg': {
    width: '$$iconSize',
    height: '$$iconSize',
    color: '$$iconColor',
  },

  '& > .stat': {
    textStyle: 'medium-heading',
    gridColumn: '2 / 3',
  },
  '& > .label': {
    textStyle: 'small-caps',
    color: '$black-alpha-50',
    // textAlign: 'center',
    gridColumn: '2 / 3',
  },

  '& .action': {
    all: 'unset',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    borderRadius: 16,
    // boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
    '& svg path': {
      fill: '$black-alpha-40',
    },
  },

  '@medium': {
    borderRadius: 16,
    $$iconSize: '72px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 0',
    justifyContent: 'center',
    backgroundColor: '$$backgroundColor',
    borderBottom: 'none',

    '& > .icon': {
      position: 'static',
      marginBottom: '$space$2',
    },
    '& > .action': {
      paddingRight: 16,
    },
  },

  variants: {
    presentation: {
      yellow: {
        $$backgroundColor: '$colors$yellow-100',
        $$iconColor: '$colors$yellow-500',
      },
      orange: {
        $$backgroundColor: '$colors$orange-100',
        $$iconColor: '$colors$orange-500',
      },
      green: {
        $$backgroundColor: '$colors$green-100',
        $$iconColor: '$colors$green-500',
      },
      blue: {
        $$backgroundColor: '$colors$blue-100',
        $$iconColor: '$colors$blue-500',
      },
      purple: {
        $$backgroundColor: '$colors$purple-50',
        $$iconColor: '$colors$purple-500',
      },
      teal: {
        $$backgroundColor: '$colors$teal-100',
        $$iconColor: '$colors$teal-500',
      },
    },
    isLarge: {
      true: {
        $$height: '296px',
        $$columns: 2,
        '@medium': {
          $$iconSize: '96px',
        },
      },
    },
  },
  defaultVariants: {
    presentation: 'yellow',
    isLarge: false,
  },
});

type ContainerProps = React.ComponentProps<typeof Container>;

export const SimpleStat = ({
  icon,
  stat,
  label,
  isLarge = false,
  action,
  ...props
}: {
  icon: React.ReactNode;
  stat: string;
  label: string;
  isLarge?: boolean;
  action?: () => void;
} & ContainerProps) => {
  return (
    <Container isLarge={isLarge} {...props}>
      <div className="icon">{icon}</div>
      <div className="stat">{stat}</div>
      <div className="label">{label}</div>
      {action ? (
        <button className="action" onClick={action}>
          <ChevronRightSmallIcon />
        </button>
      ) : null}
    </Container>
  );
};
