import * as React from 'react';
import { FilterDisclosureButton } from 'components/filters';
import { useRadioReturnType } from 'common/hooks/use-radio';
import { HSpacer } from 'naan/primitives/spacer';
import { SearchBox } from './search-box';
import { TFilterTabsIds } from './filters-ui';
import { useFiltering } from 'common/filtering/use-filtering';
import { ActiveFiltersBar } from './active-filters-bar';
import { Responsive } from 'naan/primitives/responsive';
import { styled } from 'naan/stitches.config';
import { scrollContainerToTop } from 'lib/scroll-utils';
import { classroomPortalMode } from 'components/nav/return-nav-state';
import { AppFactory } from '@app/app-factory';
import { SoundbitesDropdownButton } from 'components/filters/soundbites-dropdown';
import { ChannelDropdownButton } from 'components/filters/channel-dropdown';

import __ from 'core/lib/localization';

type FilterAndSearchProps = {
  radio: useRadioReturnType<TFilterTabsIds>;
};

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
  flex: 1,
  borderBottom: '1px solid $gray-100',
  background: '$white',

  position: 'relative',

  '@medium': {
    borderBottom: 'none',
  },
});

export const FilterAndSearchSection = ({ radio }: FilterAndSearchProps) => {
  const { resetAllFilters, replaceFilter, filter } = useFiltering();
  const { isMultiChannel, countryTagsEnabled, apIbTagsEnabled } =
    AppFactory.root;

  const toggleFilter = React.useCallback(
    (tabName: TFilterTabsIds) => (e: any) => {
      // console.log(e.target);
      const toggle = radio.toggler(tabName);
      scrollContainerToTop();
      toggle();
    },
    [radio]
  );

  const searchQuery = React.useMemo(() => {
    if (filter?.queryType === 'search') {
      return filter.value[0];
    }
    return null;
  }, [filter]);

  return (
    <Wrapper>
      <ActiveFiltersBar />

      {countryTagsEnabled ? (
        <FilterDisclosureButton
          selected={radio.isCurrent('country')}
          onClick={toggleFilter('country') as any}
          label={__('Country', 'country')}
        />
      ) : null}
      {isMultiChannel ? (
        <ChannelDropdownButton onMenuOpen={radio.resetCurrent} />
      ) : null}
      <FilterDisclosureButton
        selected={radio.isCurrent('topic')}
        onClick={toggleFilter('topic') as any}
        label={__('Topic', 'topic')}
      />
      {/* // only show the AP/IB tag UI to teachers */}
      {classroomPortalMode() && apIbTagsEnabled ? (
        <>
          <FilterDisclosureButton
            selected={radio.isCurrent('theme')}
            onClick={toggleFilter('theme') as any}
            label="AP/IB"
          />
        </>
      ) : null}
      <SoundbitesDropdownButton
        onMenuOpen={radio.resetCurrent}
        inClassroomMode={classroomPortalMode()}
      />
      <Responsive
        renderDefault={() => <HSpacer expand />}
        renderMediumAndUp={() => null}
      />
      <SearchBox
        initialQuery={searchQuery}
        onChangeQuery={query => {
          if (query) {
            replaceFilter('search', query);
            // horrible hack to enable pi menu on mobile devices w/o logging in
            if (query === '~pi') {
              AppFactory.root.togglePiMenu();
            }
          }
        }}
        onClearQuery={resetAllFilters}
        onEnterSearchMode={radio.resetCurrent}
        onExitSearchMode={resetAllFilters}
      />
    </Wrapper>
  );
};
