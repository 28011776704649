import React from 'react';
import { observer } from 'mobx-react';
import {
  PlayControlIcon,
  SimplePauseControlIcon,
} from '../../../study/views/player-icons';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';
import { createLogger } from 'app/logger';
import { ChapterReviewModel } from 'vocab-review/chapter-review-model';

const log = createLogger('player-controls:play-pause-button');

export const PlayModeIcon = observer(() => {
  const model = usePlayerModel();
  const {
    player: { transportState },
  } = model;

  const paused = !transportState.isPlaying;
  // const pendingPause = transportState.pendingPause;

  if (paused) {
    return <PlayControlIcon />;
  }

  return <SimplePauseControlIcon />;
});

export const ReviewPlayPauseButton = observer(() => {
  const reviewModel = usePlayerModel() as ChapterReviewModel;
  const {
    player: { transportState },
    playActionDisabled,
  } = reviewModel;

  React.useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('pause', function () {
        log.info('activated hardware pause');
        reviewModel.pause();
      });
      navigator.mediaSession.setActionHandler('play', function () {
        log.info('activated hardware play');
        // reviewModel.play();
        reviewModel.reviewPlaySentence(reviewModel.currentSentenceElementId);
      });

      return () => {
        navigator.mediaSession.setActionHandler('pause', null);
        navigator.mediaSession.setActionHandler('play', null);
      };
    }
  }, [reviewModel, transportState]);

  return (
    <ControlButton
      onClick={() => {
        if (reviewModel.isPlaying) {
          reviewModel.pause();
        } else {
          reviewModel.reviewPlaySentence(reviewModel.currentSentenceElementId);
        }
      }}
      disabled={playActionDisabled}
    >
      <PlayModeIcon />
    </ControlButton>
  );
});
