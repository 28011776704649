import * as React from 'react';
import * as Dialog from '@naan/primitives/modals';
import { keyboardService } from 'lib/services/keyboard-service';
import __ from '@core/lib/localization';

export const ChapterNotesDialog: React.FC<
  React.PropsWithChildren<{
    onDismiss: () => void;
    trigger: React.ReactNode;
    defaultOpen?: boolean;
  }>
> = ({ children, onDismiss, trigger, defaultOpen }) => {
  /// @joseph this momentarily stops the current keyboard shortcuts set while the dialog is mounted.
  React.useEffect(() => {
    return keyboardService.stopCurrentShortcutSet();
  }, []);

  React.useEffect(() => {
    return () => {
      // console.log();
      setTimeout(() => {
        document
          .querySelectorAll('[data-radix-focus-guard]')
          .forEach(node => node.remove());
      }, 1000);
    };
  }, []);

  return (
    <Dialog.PureContainer
      onDismiss={onDismiss}
      trigger={trigger}
      defaultOpen={defaultOpen}
    >
      <Dialog.Heading withBorder>{__('Notes', 'notes')}</Dialog.Heading>
      <Dialog.Body css={{ paddingTop: 0 }}>{children}</Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Close asChild>
          <Dialog.Button
            presentation="teal"
            label={__('Got it', 'gotIt')}
            onClick={onDismiss}
            fullWidth
          />
        </Dialog.Close>
      </Dialog.ButtonsContainer>
    </Dialog.PureContainer>
  );
};
