import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { PlayerViewController } from '../player-view-controller';

const ProgressbarWrapper = styled('div', {
  '--progress': '50%',
  background: 'rgba(255, 255, 255, 0.20)',
  width: 160,
  height: 8,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',

  '& > .progress': {
    background: 'rgba(255, 255, 255, 0.80)',
    height: '100%',
    width: 'var(--progress)',
  },
});

export const ProgressBar = ({ player }: { player: PlayerViewController }) => {
  const barRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const video = player.videoElement;
    const bar = barRef.current;

    if (video) {
      const update = () => {
        const duration = video.duration;
        const currentTime = video.currentTime;
        const progress = (currentTime / duration) * 100;

        if (bar) {
          bar.style.setProperty('--progress', `${progress}%`);
        }

        window.requestAnimationFrame(update);
      };

      window.requestAnimationFrame(update);
    }
  }, [player.videoElement]);
  return (
    <ProgressbarWrapper ref={barRef}>
      <div className="progress" />
    </ProgressbarWrapper>
  );
};
