import { TSortingDescription } from '../filtering-core';
import { TSortingKey } from './sorting-keys';

export const sortingDefinitions: Record<TSortingKey, TSortingDescription> = {
  title: {
    name: 'title',
    keys: ['sortTitle'],
    order: 'asc',
  },
  duration: {
    name: 'duration',
    keys: ['sortDurationMinutes'],
    order: 'asc',
  },
  release_date: {
    name: 'release_date',
    keys: ['releaseDate'],
    order: 'desc',
  },
  broadcast_date: {
    name: 'broadcast_date',
    keys: ['originalBroadcastDate'],
    order: 'desc',
  },
  // vocab: {
  //   name: 'vocab',
  //   keys: ['vocabFilterStatuses'],
  //   order: 'desc',
  // },
};
