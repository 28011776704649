import * as React from 'react';
import '@common/bugsnag/bug-reporting-init';
import { BugReportingBoundary } from '@common/bugsnag/bug-reporting-boundary';
// import App from './app';
import { createRoot } from 'react-dom/client';
import { configure } from 'mobx';
import { SpaGlobalStyles } from 'components/ui/spa-global-styles';
import { createLogger } from '@common/log';
import { UpdateManager } from 'pwa/window/update-manager';
import { appConfig } from '@app/config';
import { L2Router } from 'l2-router';
import 'common/analytics/meta-analytics';

// only imported now when localization is enabled
// import 'lib/services/localization-service';

const log = createLogger('index');

log.debug('loading src/index.tsx');

/// hack to mock window.process, for some reason our markdown library requires it
(window as any).process = { cwd: () => '' };

const { updatePollingEnabled } = appConfig;

log.debug(`updatePollingEnabled: ${String(updatePollingEnabled)}`);

// disable mobx strict-mode
configure({
  enforceActions: 'never',
});
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BugReportingBoundary>
    <SpaGlobalStyles />
    {/* <App /> */}
    {/* <React.StrictMode> */}
    <L2Router />
    {/* </React.StrictMode> */}
    {updatePollingEnabled ? <UpdateManager /> : null}
  </BugReportingBoundary>
);
// // useful for isolated scratch code testing
// ReactDOM.render(
//   <BugReportingBoundary bugsnagStatic={Bugsnag} bugsnagEnabled={bugsnagEnabled}>
//     <h2>scratch</h2>
//   </BugReportingBoundary>,
//   document.getElementById('root')
// );

// (window as any).initMp = () => {
//   (window as any).mp = new MixpanelAnalytics();
// };
