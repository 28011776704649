import { styled } from 'naan/stitches.config';

export const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '$space$4',
  gap: '12px',
  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
        gap: '0',
        '& > *': {
          width: '100%',
        },
        '& > * + *': {
          marginTop: '$space$2',
        },
      },
    },
  },
});
