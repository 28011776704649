import * as React from 'react';

export function PlayTriangleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0537 10.7406L7.31488 2.49786C6.31683 1.85207 5 2.56846 5 3.75722V20.2428C5 21.4315 6.31683 22.1479 7.31488 21.5021L20.0537 13.2594C20.9672 12.6683 20.9672 11.3317 20.0537 10.7406Z"
        fill={color}
      />
    </svg>
  );
}
