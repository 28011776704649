//
// data layer behind he vocab review UI
//

// import { makeObservable, observable } from 'mobx';
import { ChapterCatalogData } from '../catalog';
import { Story } from '../story-manager';

import { createLogger } from 'app/logger';
import { Notation } from '@tikka/client/client-aliases';
import { ChapterRef } from '../user-manager/location-pointer';
import { track } from '@app/track';
import {
  ChapterReviewModel,
  NotationStatus,
} from 'vocab-review/chapter-review-model';
import { ObservableMap, action, makeObservable, observable } from 'mobx';
import {
  StoryProgress,
  VocabReviewPointer,
} from '../user-manager/story-progress';
import { initializeChapterReviewModel } from 'vocab-review/use-vocab-review-loader';
import { bugsnagNotify } from '@app/notification-service';
import { LoadingStatus } from 'player/models/player-model';
import { AppFactory } from '@app/app-factory';
import {
  startPlayerKeyboardControls,
  stopPlayerKeyboardControls,
} from 'player/views/player-controls/player-keyboard-controls';
import { GenericError } from '@core/lib/errors';

const log = createLogger('vocab-list-model');

export const PROMOTED_REVIEW_TRESHOLD = 20;

// export type VocabListChapter = {
//   chapterData: ChapterCatalogData;
//   items: Notation[];
//   // key is notation id, value is tri-state review status: true->'got it', false->'didn't get', undefined->'no choice yet'
//   // statuses: Map<string,boolean>[];

//   // todo: should be observable
//   // statuses: { [index: string]: NotationStatus };
//   statusMap: ObservableMap<NotationStatus> = snap({}); // similar set representing vocabs marked as 'learned'
// };

export class VocabListChapter {
  chapterData: ChapterCatalogData;
  items: Notation[] = []; // to-review
  previouslyLearned: Notation[] = [];

  // key is notation id, value is tri-state review status: true->'got it', false->'didn't get', undefined->'no choice yet'
  @observable
  statusMap: Map<string, NotationStatus>;

  constructor({
    chapterData,
  }: // items,
  {
    chapterData: ChapterCatalogData;
    // items: Notation[];
  }) {
    this.chapterData = chapterData;
    this.items = [];
    this.statusMap = new ObservableMap();

    makeObservable(this);
  }

  initLearnedStatuses(story: Story) {
    const storyProgress = story.progressMayBeNull;
    if (!storyProgress) {
      return;
    }

    for (const notation of this.previouslyLearned) {
      const slug = notation.id;
      const toReview = storyProgress.vocabExists(slug);
      if (!toReview) {
        // guard against potentially dirty dev/test data - likely not needed
        this.statusMap.set(slug, 'previously-learned');
      }
    }
  }

  get chapterRef(): ChapterRef {
    return this.chapterData.chapterRef;
  }

  status(notationId: string): NotationStatus {
    return this.statusMap.get(notationId);
  }

  @action
  setStatus(notationId: string, status: NotationStatus): void {
    this.statusMap.set(notationId, status);
  }
}

export class VocabReviewModel {
  // data needed to resolve the vocab details
  volumeData: Story;

  // storyProgress: StoryProgress;

  // player model for currently active chapter
  @observable
  chapterReviewModel: ChapterReviewModel;

  @observable
  currentChapterPosition = 0;

  @observable
  showingResumeModal = false;

  // UI backing data
  // one item per chapter for which to-review vocab exist
  chapters: VocabListChapter[] = [];

  // drives the display of the end/exit review interstitial
  @observable
  showingSummary: boolean = true;

  // true when previous session was exited before compleation
  @observable
  resumeSessionPointer: VocabReviewPointer;

  // number of active vocab skipped because of resuming a previous session
  // note, we may need to distinguish between previous chapter skips and current chapter skips
  skippedCount: number = 0;
  currentChapterHasSkipped: boolean = false; // when true, then bypass the start-of-chapter view

  constructor({
    volumeData,
  }: // storyProgress,
  {
    volumeData: Story;
    storyProgress: StoryProgress;
  }) {
    makeObservable(this);
    this.volumeData = volumeData;
    // this.storyProgress = storyProgress;

    this.init();
  }

  init() {
    this.chapterReviewModel = undefined;
    this.chapters = [];
    this.currentChapterPosition = 0;
    this.showingSummary = false;
    this.resumeSessionPointer = undefined;
    this.skippedCount = 0;
    this.currentChapterHasSkipped = false;

    const vocabSlugs = this.storyProgress?.vocabList ?? [];
    const learnedSlugs = this.storyProgress?.learnedVocabList ?? [];
    // const learnedVocabSlugs = storyProgress?.learnedVocab || [];

    this.resumeSessionPointer = this.storyProgress?.vocabReviewPointer;

    if (this.volumeData) {
      this.initSections(
        vocabSlugs,
        learnedSlugs
        // storyProgress.vocabReviewPointer
      );

      if (this.chapters.length === 0) {
        if (this.resumeSessionPointer) {
          log.warn(
            'VocabReviewModel - no chapters - resetting resumeSessionPointer'
          );
          this.resumeSessionPointer = undefined;
          this.skippedCount = 0;
          this.storyProgress?.clearVocabReviewPointer();
          this.initSections(
            vocabSlugs,
            learnedSlugs
            // storyProgress.vocabReviewPointer
          );
        } else {
          log.warn('VocabReviewModel - no chapters');
          throw new GenericError('No review data');
        }
      }

      track('vocabreview__opened', {
        storySlug: this.volumeData?.slug,
        count: this.allItemCount,
      });
    } else {
      log.error('no volumeData');
    }
  }

  initSections(vocabSlugs: string[], learnedSlugs: string[]): void {
    this.chapters = [];
    for (const rawSlug of vocabSlugs) {
      let slug = rawSlug;
      const notation = this.volumeData.vocab(slug);
      if (notation) {
        if (this.includeNotation(notation)) {
          const section = this.sectionForNotation(notation, true);
          section.items.push(notation);
          section.statusMap.set(notation.id, 'pending');
        } else {
          log.info(`skipping vocab: ${slug}`);
          this.skippedCount++;
        }
      } else {
        log.warn(`vocab slug not resolved: ${slug}`);
      }
    }

    for (const rawSlug of learnedSlugs) {
      let slug = rawSlug;
      const notation = this.volumeData.vocab(slug);
      if (notation) {
        const section = this.sectionForNotation(notation, false);
        if (section) {
          section.previouslyLearned.push(notation);
        }
      } else {
        log.warn(`vocab slug not resolved: ${slug}`);
      }
    }

    this.chapters = this.chapters.sort(
      (a, b) => a.chapterData.sortingRef - b.chapterData.sortingRef
    );
    // tag the first included chapter for each unit
    let lastUnit = -1;
    for (const chapter of this.chapters) {
      if (
        this.volumeData.multiUnit &&
        chapter.chapterData.unitNumber !== lastUnit
      ) {
        // section.unitHeader = true;
        lastUnit = chapter.chapterData.unitNumber;
      }
      // sort by order of appearance
      chapter.items = chapter.items.sort((a, b) => a.address - b.address);
      chapter.initLearnedStatuses(this.volumeData);
    }
  }

  includeNotation(notation: Notation): boolean {
    if (this.resumeSessionPointer) {
      const chapterData = this.volumeData.chapterForPoint(
        notation as ChapterRef
      );
      if (!chapterData) {
        log.error(`includeNotation - no chapter data: ${notation.id}`);
        return false;
      }

      if (chapterData.isBefore(this.resumeSessionPointer)) {
        return false;
      }
      if (chapterData.matchesPoint(this.resumeSessionPointer)) {
        if (
          notation.chapterAddress < this.resumeSessionPointer.sentenceAddress
        ) {
          this.currentChapterHasSkipped = true;
          return false;
        }
      }
    }
    return true;
  }

  // reuse an existing chapter section or create new section as needed
  sectionForNotation(notation: Notation, allocate: boolean): VocabListChapter {
    const chapterData = this.volumeData.chapterForPoint(
      notation as ChapterRef /* cast needed because of optional params*/
    );
    let result = this.chapters.find(
      section => section.chapterData === chapterData
    );
    if (!result && allocate) {
      result = new VocabListChapter({
        chapterData,
        // items: [] as Notation[],
      });
      this.chapters.push(result);
    }
    return result;
  }

  clearSessionPointer() {
    this.storyProgress.clearVocabReviewPointer();
  }

  get hasSkipped() {
    return this.skippedCount > 0;
  }

  get isFirstChapter() {
    return this.currentChapterPosition === 0;
  }

  // used when panel closed to get the list of slugs to remove from StoryProgress data
  // get removalSlugs(): string[] {
  //   const result: string[] = [];
  //   for (const section of this.chapters) {
  //     for (const row of section.items) {
  //       if (row.remove) {
  //         result.push(row.slug);
  //       }
  //     }
  //   }
  //   return result;
  // }

  get story(): Story {
    return this.volumeData;
  }

  get storyProgress(): StoryProgress {
    // return this.story.progressMayBeNull;
    // paranoia since volumeData isn't part of the normal TST root tree
    return AppFactory.root.userManager.userData.storyProgress(this.story.slug);
  }

  showSummary() {
    stopPlayerKeyboardControls();
    this.showingSummary = true;
  }

  // used when undoing the review exit
  hideSummary() {
    this.showingSummary = false;
  }

  get chapterCount() {
    return this.chapters.length;
  }

  get currentChapter() {
    return this.chapters?.[this.currentChapterPosition];
  }

  // used to determine resume point when at the end of the previous chapter
  get nextChapterRef() {
    return this.chapters[this.currentChapterPosition + 1]?.chapterRef;
  }

  get isLastChapter() {
    return this.currentChapterPosition === this.chapters.length - 1;
  }

  get reviewComplete() {
    return this.isLastChapter && this.chapterReviewModel.endOfChapter;
  }

  @action
  nextChapter() {
    if (this.isLastChapter) {
      this.showSummary();
    } else {
      // @armando, todo: show the proper background image as discussed instead of flashing story detail screen while loading
      this.chapterReviewModel.loadingStatus = LoadingStatus.LOADING;
      initializeChapterReviewModel(this, this.currentChapterPosition + 1).catch(
        bugsnagNotify
      );
    }
  }

  @action
  setChapterReviewModel(model: ChapterReviewModel, chapterPosition: number) {
    model.vocabReviewModel = this;
    this.chapterReviewModel = model;
    this.currentChapterPosition = chapterPosition;
  }

  @action
  restartSession() {
    this.chapterReviewModel.loadingStatus = LoadingStatus.LOADING;

    this.clearSessionPointer();
    this.init();

    initializeChapterReviewModel(this, 0).catch(bugsnagNotify);
  }

  get currentItems() {
    return this.currentChapter?.items;
  }

  get currentItemCount() {
    return this.currentItems?.length;
  }

  get remainingChapters() {
    return this.chapters.slice(this.currentChapterPosition);
  }

  get allItemCount() {
    return this.chapters.reduce((sum, section) => {
      // wondering if section deserves to be promoted to a class
      // return sum + section.items.filter(row => !row.remove).length;
      return sum + section.items.length;
    }, 0);
  }

  // value displayed at the start of a chapter
  get remainingItemCount() {
    return this.remainingChapters.reduce((sum, section) => {
      return sum + section.items.length;
    }, 0);
  }

  get remainingChapterCount() {
    return this.chapterCount - this.currentChapterPosition;
  }

  get chapterLearnedCount() {
    return matchedValueCount(this.currentChapter?.statusMap, 'got-it');
  }

  get chapterReviewAgainCount() {
    return matchedValueCount(this.currentChapter?.statusMap, 'not-got-it');
  }

  get chapterJustAddedCount() {
    return matchedValueCount(this.currentChapter?.statusMap, 'just-added');
  }

  get sessionLearnedCount() {
    return this.sessionMatchedValueCount('got-it');
  }

  get sessionReviewAgainCount() {
    return this.sessionMatchedValueCount('not-got-it');
  }

  get sessionJustAddedCount() {
    return this.sessionMatchedValueCount('just-added');
  }

  // value displayed when exiting the review session
  get sessionPendingCount() {
    return this.sessionMatchedValueCount('pending');
  }

  // if false, then bypass review summary view
  get anyItemsReviewed() {
    return this.sessionLearnedCount + this.sessionReviewAgainCount;
  }

  sessionMatchedValueCount(value: NotationStatus) {
    let result = 0;
    for (const chapter of this.chapters) {
      result += matchedValueCount(chapter.statusMap, value);
    }
    return result;
  }

  @action
  showResumeModal() {
    stopPlayerKeyboardControls();
    this.showingResumeModal = true;
  }

  @action
  hideResumeModal() {
    startPlayerKeyboardControls();
    this.showingResumeModal = false;
  }
}

function matchedValueCount(map: Map<any, any>, value: any): number {
  let count = 0;
  if (map) {
    for (const v of map.values()) {
      if (v === value) {
        count++;
      }
    }
  }
  return count;
}
