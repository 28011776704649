import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
import { Box } from '@naan/primitives/box/box';
import { ChapterItemContents } from './chapter-item-contents/chapter-item-contents';
import { ChapterSoundbites } from './chapter-item-contents/chapter-soundbites';
import { ReviewChapterIcon } from './components/review-chapter-icon';
import { ChapterReviewCard } from './chapter-item-contents/chapter-review-card';

// special review mode
export const CurrentNotFurthest = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    return (
      <Box style={{ overflowY: 'clip' }}>
        <ChapterListHeader
          chapter={chapter}
          presentation="current"
          stationIcon={<ReviewChapterIcon />}
        />
        <ChapterItemContents>
          <ChapterSoundbites chapter={chapter} forceOpen />
          <ChapterReviewCard chapter={chapter} />
        </ChapterItemContents>
      </Box>
    );
  }
);
