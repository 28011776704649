import * as React from 'react';
import { pick } from './pick';

type TLargePresentation = 'color' | 'grayscale';

export const VocabularyIconLarge = ({
  presentation = 'color',
}: {
  presentation?: TLargePresentation;
}) => {
  const color1 = pick(presentation, {
    color: '#44C560',
    grayscale: '#999',
  });

  const color2 = pick(presentation, {
    color: '#8BDA98',
    grayscale: '#b3b3b3',
  });

  const color3 = pick(presentation, {
    color: '#B4E8B6',
    grayscale: '#ccc',
  });

  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="24.9923"
        y="4.93726"
        width="40"
        height="54"
        rx="4"
        transform="rotate(10 24.9923 4.93726)"
        fill={color3}
      />
      <rect x="14" y="6" width="40" height="54" rx="4" fill={color2} />
      <rect
        x="3.61536"
        y="12.8831"
        width="40"
        height="54"
        rx="4"
        transform="rotate(-10 3.61536 12.8831)"
        fill={color1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4745 23.1432C22.5076 23.1639 25.655 25.2423 25.8456 29.2532C25.0684 29.3534 24.8354 29.3023 24.5569 29.2404L24.5561 29.2403C24.1815 29.1576 23.7152 29.0548 22.3968 29.1481C19.4325 29.361 17.4165 32.2309 17.3135 32.3841C16.0005 34.6384 15.7376 37.0517 16.532 39.5583C18.09 44.4776 23.2006 48.1261 24.8859 48.8987C25.7753 49.3067 26.7066 49.4287 27.6539 49.2616C28.7023 49.0768 29.5183 48.5822 29.9734 48.2467C30.5156 48.4064 31.4517 48.592 32.5002 48.4071C33.447 48.2402 34.2817 47.8068 34.977 47.1186C36.2964 45.8167 39.8517 40.6406 39.6332 35.4852C39.523 32.8582 38.4505 30.6797 36.4122 28.9852L36.4121 28.9845C36.2994 28.9031 33.4312 26.8867 30.5631 27.7082C29.293 28.0711 28.8896 28.3273 28.566 28.5328L28.5644 28.5338C28.3307 28.6828 28.1339 28.8076 27.4238 28.9717C27.3435 27.801 27.0578 26.774 26.672 25.8906C33.1599 27.2108 34.0243 20.2831 34.0243 20.2831C27.4095 20.385 26.2094 23.1591 26.0591 24.7181C24.9061 22.8392 23.4382 21.8701 23.3393 21.8061C22.9699 21.568 22.4765 21.6732 22.2385 22.0428C21.9998 22.4123 22.1055 22.905 22.4745 23.1432Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};

export const VocabularyIconSmall = ({
  presentation = 'color',
}: {
  presentation?: 'color' | 'grayscale' | 'dark';
}) => {
  const color1 = pick(presentation, {
    color: '#44C560',
    grayscale: '#999',
    dark: 'white',
  });

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.57832 1.99395C2.94664 2.28166 1.85714 3.83763 2.14485 5.46932L4.97283 21.5076C5.26054 23.1393 6.81651 24.2288 8.4482 23.9411L19.4218 22.0062C21.0535 21.7184 22.143 20.1625 21.8552 18.5308L19.0273 2.49249C18.7396 0.860807 17.1836 -0.228699 15.5519 0.059011L4.57832 1.99395ZM12.0101 9.33477C11.9467 8.90726 11.8483 8.34925 11.629 7.80582C14.418 8.39798 14.7944 5.46545 14.7944 5.46545C11.9439 5.51489 11.4681 6.71878 11.4183 7.36285C11.0345 6.67459 10.3998 6.07277 9.36131 5.86502L9.16516 6.8456C9.89868 6.99233 10.3143 7.40591 10.5794 7.9138C10.8399 8.41311 10.9488 8.99418 11.024 9.5031C10.8487 9.51455 10.7705 9.49771 10.6814 9.4783L10.6811 9.47823C10.5227 9.44402 10.3256 9.40144 9.76789 9.44163C8.51391 9.53329 7.65864 10.7348 7.61495 10.799C7.05757 11.7426 6.94423 12.7517 7.27798 13.7989C7.93255 15.854 10.0909 17.3747 10.803 17.6962C11.1788 17.8659 11.5726 17.9161 11.9734 17.8454C12.4171 17.7672 12.7626 17.5597 12.9554 17.4191C13.1846 17.4853 13.5804 17.5621 14.024 17.4839C14.4246 17.4132 14.778 17.2313 15.0727 16.943C15.632 16.3976 17.1403 14.2305 17.0525 12.0755C17.0082 10.9773 16.5566 10.0676 15.696 9.36103L15.6959 9.36074C15.6483 9.32679 14.437 8.48639 13.2232 8.83238C12.6856 8.98524 12.5148 9.09269 12.3777 9.17888L12.3771 9.17932C12.2922 9.23293 12.2188 9.27906 12.0101 9.33477Z"
        fill={color1}
      />
    </svg>
  );
};
