import * as React from 'react';

import { LayoutContainer, ControlsContainer } from './study-layout';
import { Sentence } from 'player/views/player-ui-elements/review-sentence-element';
import {
  PlayerUIConfig,
  usePlayerUIConfig,
} from 'player/views/player-ui-config';
import { useModelSetup } from 'player/views/player-ui-model-setup';

import { SCRIPT_SCROLLING_CONTAINER_ID } from 'player/views/player-ui-components';
import { useOldIosScrollFix } from 'player/views/use-old-ios-scroll-fix';
import { createLogger } from 'app/logger';
import { ReviewHeader } from './review-header';
import { useWakeLock } from '@core/lib/wake-lock/use-wakelock';
import { styled } from '@naan/stitches.config';
import { useReaction } from '@common/hooks/use-reaction';
import { ReviewOverlay } from './review-overlay';
import { VocabReviewModel } from '@core/models/ui/vocab-review-model';
import { observer } from 'mobx-react';
import { ElementNode } from '@tikka/client/client-aliases';
import { ReviewEndCard } from './review-end-card';
import { usePlayerModel } from 'player/views/player-model-context';
import { ChapterReviewModel } from './chapter-review-model';
import { LoadingStatus } from 'player/models/player-model';
import { ReviewExitOverlay } from './review-exit/review-exit-overlay';
import { ReviewPlayerControls } from 'player/views/player-controls/review-player-controls';
import { ResumeReviewDialog } from './resume-review-dialog';
import { KeyboardHelpOverlay } from 'components/ui/keyboard-help-overlay';

const log = createLogger('study-ui');

const ReviewLayoutContainer = styled(LayoutContainer, {
  '--transport-bar-height': '80px',
  '--top-bar-height': '60px',
  '--scroller-height':
    'calc(100vh - var(--transport-bar-height) - var(--top-bar-height))',
  '--page-width': 'min(100%, 616px)',
  '--current-page': '0',
});

const ScriptOuterContainer = styled('div', {
  overflow: 'hidden',
  width: 'var(--page-width)',
  margin: 'var(--top-bar-height) auto 0',
});

const PlayerUIBody = observer(() => {
  // const model = usePlayerModel() as StudyModel;
  const reviewModel = usePlayerModel() as ChapterReviewModel;
  // const elementNodes = model.elementNodes.filter((node: ElementNode) => {
  // return node.kind === 'sentence';
  //   const notationCount = model.getNotationCountForSentence(node.element.id);
  //   return node.element.kind === 'SENTENCE' && notationCount > 0;
  // });

  if (reviewModel?.loadingStatus !== LoadingStatus.READY) {
    return null; // hack
  }

  return (
    <>
      {reviewModel.sentenceNodes.map((node: ElementNode) => {
        return <Sentence key={node.element.id} node={node} />;
      })}
    </>
  );
});

const ReviewHorizontalScrollContainer = styled('div', {
  '--page-width': 'min(100%, 616px)',
  '--offset': 'calc(var(--current-page) * var(--page-width))',
  display: 'grid',
  gridAutoFlow: 'column',
  // gridAutoColumns: '616px',
  gridAutoColumns: 'var(--page-width)',
  translate: 'calc(var(--offset) * -1)',
  transition: 'translate 0.3s',
  height: '100%',
});

const elementTypeMap: { [index: string]: any } = {
  SENTENCE: Sentence,
};

const config: PlayerUIConfig = { elementTypeMap };

const ScriptInner = React.memo(() => {
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <ReviewHorizontalScrollContainer ref={ref}>
      <PlayerUIBody />
      <ReviewEndCard />
    </ReviewHorizontalScrollContainer>
  );
});

// export const VocabReviewUI = React.memo( // @armando, what's the implication of using React.memo here?
export const VocabReviewUI = observer(
  ({
    vocabReviewModel,
    onDismiss,
  }: {
    vocabReviewModel: VocabReviewModel;
    onDismiss: () => void;
  }) => {
    log.debug('VocabReviewUI - render');
    // const chapterReviewModel = AppFactory.chapterReviewModel;
    const { chapterReviewModel, currentChapterPosition } = vocabReviewModel;
    log.info('currentChapterPosition', currentChapterPosition); // make sure touched to trigger observer refresh

    if (chapterReviewModel?.loadingStatus !== LoadingStatus.READY) {
      return null;
    }

    return (
      <VocabChapterReviewUI
        chapterReviewModel={chapterReviewModel}
        onDismiss={onDismiss}
      />
    );
  }
);

export const VocabChapterReviewUI = ({
  chapterReviewModel,
  onDismiss,
}: {
  chapterReviewModel: ChapterReviewModel;
  onDismiss: () => void;
}) => {
  log.debug('VocabChapterReviewUI - render');
  const { vocabReviewModel } = chapterReviewModel;

  (window as any).model = chapterReviewModel;

  usePlayerUIConfig(config); // injects flavor specific behavior into player-ui-components
  useModelSetup(); // setups up membership reconcilers and reaction behaviors shared with both players
  useOldIosScrollFix();

  const containerRef = React.useRef<HTMLDivElement>(null);

  useWakeLock();

  useReaction(
    () => chapterReviewModel.cssCurrentPageIndex,
    pageIndex => {
      log.debug('currentPageIndex', pageIndex);
      const container = containerRef.current;
      if (!container) {
        return;
      }
      container.style.setProperty('--current-page', pageIndex.toString());
    }
  );

  // @armando, i'll let you propertly memoize this
  const showSummaryOrDismiss = React.useCallback(() => {
    if (vocabReviewModel.anyItemsReviewed) {
      vocabReviewModel.showSummary();
    } else {
      onDismiss();
    }
  }, [vocabReviewModel, onDismiss]);

  return (
    <ChapterReviewProvider model={chapterReviewModel}>
      <ReviewOverlay
        onDismiss={showSummaryOrDismiss} /* "Start of chapter" modal */
      />
      <ReviewExitOverlay onDismiss={onDismiss} />
      <ReviewLayoutContainer ref={containerRef}>
        <ReviewHeader onDismiss={showSummaryOrDismiss} />
        <ScriptOuterContainer id={SCRIPT_SCROLLING_CONTAINER_ID}>
          <ScriptInner />
        </ScriptOuterContainer>
        <ControlsContainer className="controls-container">
          <ReviewPlayerControls />
        </ControlsContainer>
        <KeyboardHelpOverlay mode={'review'} />
      </ReviewLayoutContainer>
      <ResumeReviewDialog vocabReviewModel={vocabReviewModel} />
    </ChapterReviewProvider>
  );
};

export const ChapterReviewContext =
  React.createContext<ChapterReviewModel | null>(null);

export const ChapterReviewProvider = ({ model, children }: any) => {
  return (
    <ChapterReviewContext.Provider value={model}>
      {children}
    </ChapterReviewContext.Provider>
  );
};

export const useChapterReviewModel = () => {
  const model = React.useContext(ChapterReviewContext);
  if (!model) {
    throw new Error(
      'useChapterReviewModel must be used within a ChapterReviewProvider'
    );
  }
  return model;
};
