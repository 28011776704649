import * as React from 'react';
import {
  BookmarkCheckmarkLargeIcon,
  BookmarkPlusLargeIcon,
  BookmarkXLargeIcon,
} from '@naan/icons/bookmark-icon';
import { styled } from '@naan/stitches.config';
import classNames from 'classnames';

import __ from '@core/lib/localization';

const ProgressGaugeWrapper = styled('div', {
  $$backgroundColor: '$colors$white',

  display: 'flex',
  flexDirection: 'column',
  background: '$$backgroundColor',

  '& .icons': {
    flex: 1,
    gap: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 8,
  },

  '& .section': {
    $$sectionIconColor: '$colors$purple-500',
    $$numberColor: '$colors$textPrimary',
    $$labelColor: '$colors$textSecondary',
    $$justifyContent: 'flex-end',
    justifyContent: '$$justifyContent',
    // alignItems: 'flex-end',
    // flex: 1,

    '& .number': {
      justifyContent: '$$justifyContent',
      color: '$$numberColor',
      display: 'flex',
      textStyle: 'medium-heading',
      alignItems: 'center',
      '& .icon': {
        color: '$$sectionIconColor',
        marginLeft: -3,
        '& svg': {
          width: 24,
          height: 24,
        },
      },
    },

    '& .label': {
      display: 'block',
      color: '$$labelColor',
      textStyle: 'small-text-bold',
    },

    '&.learned-section': {
      $$justifyContent: 'flex-start',
      $$sectionIconColor: '$colors$green-500',
      marginRight: 'auto',
    },

    '&.disabled': {
      $$sectionIconColor: '$colors$black-alpha-20',
      $$numberColor: '$colors$black-alpha-20',
      $$labelColor: '$colors$black-alpha-20',
    },
  },
  variants: {
    presentation: {
      default: {},
      dark: {
        $$backgroundColor: '$colors$transparent',
        '.section': {
          $$sectionIconColor: '$colors$purple-400',
          $$numberColor: '$colors$white',
          $$labelColor: '$colors$white-alpha-80',
          '&.disabled': {
            $$sectionIconColor: '$colors$white-alpha-50',
            $$numberColor: '$colors$white-alpha-50',
            $$labelColor: '$colors$white-alpha-50',
          },
        },
      },
    },
  },
});

const BarWrapper = styled('div', {
  // height: 40,
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: 4,
  gap: 1,
  '& > .bar': {
    height: 12,
    width: '100%',
    flex: 'var(--n)',
    background: '$colors$purple-500',

    '&.bar-learned': {
      background: '$colors$green-500',
    },
  },
});

const BarSegment = ({
  n,
  type,
}: {
  n: number;
  type: 'learned' | 'review' | 'added';
}) => {
  return (
    <div
      className={'bar bar-' + type}
      style={
        {
          '--n': n,
        } as any
      }
    />
  );
};

const Bar = ({
  learned,
  toReview,
  added,
}: {
  learned: number;
  toReview: number;
  added: number;
}) => {
  return (
    <BarWrapper>
      <BarSegment n={learned} type="learned" />
      <BarSegment n={toReview} type="review" />
      {added > 0 ? <BarSegment n={added} type="added" /> : null}
    </BarWrapper>
  );
};

const IconSection = ({
  icon,
  number,
  label,
  disabled,
  type,
}: {
  icon: React.ReactNode;
  number: number;
  label: string;
  disabled?: boolean;
  type: 'learned' | 'review' | 'added';
}) => {
  return (
    <div
      className={classNames('section', type + '-section', {
        disabled,
      })}
    >
      <div className="number">
        <div className="icon">{icon}</div>
        {number}
      </div>
      <span className="label">{label}</span>
    </div>
  );
};

export const ProgressGaugeBar = ({
  learned,
  toReview,
  added,
  presentation,
}: {
  learned: number;
  toReview: number;
  added: number;
  presentation?: 'default' | 'dark';
}) => {
  return (
    <ProgressGaugeWrapper presentation={presentation}>
      <div className="icons">
        <IconSection
          type="learned"
          icon={<BookmarkCheckmarkLargeIcon />}
          number={learned}
          label={__('Learned', 'learned')}
          disabled={learned === 0}
        />
        <IconSection
          type="review"
          icon={<BookmarkXLargeIcon />}
          number={toReview}
          label={__('To review again', 'toReviewAgain')}
          disabled={toReview === 0}
        />
        {added > 0 ? (
          <IconSection
            type="added"
            icon={<BookmarkPlusLargeIcon />}
            number={added}
            label={__('Added', 'added')}
          />
        ) : null}
      </div>
      <Bar learned={learned} toReview={toReview} added={added} />
    </ProgressGaugeWrapper>
  );
};
