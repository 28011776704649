import * as React from 'react';
import { pick } from './pick';

type TLargePresentation = 'color' | 'grayscale';

export const CultureIconLarge = ({
  presentation = 'color',
}: {
  presentation?: TLargePresentation;
}) => {
  const color1 = pick(presentation, {
    color: '#3971A0',
    grayscale: '#999',
  });

  const color2 = pick(presentation, {
    color: '#6699C3',
    grayscale: '#B3B3B3',
  });

  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.5715 11.5714C37.2857 11.5714 30.8572 9 30.8572 9V27C30.8572 27.6817 30.876 28.3484 30.9126 29H37V42H34.8227C38.2776 47.4286 43.3769 50.1429 47.5715 50.1429C54.6429 50.1429 64.2857 42.4286 64.2857 27V9C64.2857 9 57.8572 11.5714 47.5715 11.5714ZM51.668 21.6156C52.1399 22.3443 52.9685 23.1475 53.9007 23.3793C54.3448 23.4898 54.8259 23.4756 55.3459 23.2477C55.8761 23.0153 56.4904 22.5407 57.148 21.6573C57.3954 21.3251 57.8653 21.2562 58.1975 21.5036C58.5298 21.7509 58.5986 22.2208 58.3512 22.553C57.5846 23.5828 56.7791 24.2572 55.9481 24.6215C55.1068 24.9903 54.285 25.0206 53.5387 24.835C52.0899 24.4747 50.9872 23.3239 50.409 22.431C50.1838 22.0833 50.2831 21.6189 50.6308 21.3937C50.9785 21.1686 51.4429 21.2679 51.668 21.6156ZM55.9519 39.1812C51.7455 38.2139 43.6089 38.4571 39.6162 39.6438C40.9703 35.9117 43.4104 33.9766 48.1537 33.5593C52.1491 33.2078 54.9122 37.0133 55.9519 39.1812ZM37.9152 21.9357C38.3871 22.6643 39.2157 23.4676 40.1479 23.6994C40.5919 23.8098 41.0731 23.7957 41.5931 23.5678C42.1233 23.3353 42.7376 22.8607 43.3952 21.9774C43.6426 21.6451 44.1124 21.5763 44.4447 21.8237C44.7769 22.071 44.8458 22.5409 44.5984 22.8731C43.8318 23.9029 43.0263 24.5773 42.1953 24.9416C41.354 25.3104 40.5322 25.3407 39.7859 25.1551C38.3371 24.7948 37.2344 23.6439 36.6562 22.751C36.431 22.4033 36.5303 21.939 36.878 21.7138C37.2257 21.4887 37.6901 21.588 37.9152 21.9357Z"
        fill={color1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.68591 27.9581C5.68591 27.9581 12.5525 28.8461 22.5136 26.2824C32.4747 23.7188 38.0595 19.6262 38.0595 19.6262L42.5459 37.0582C46.3914 51.9998 38.9756 61.8741 32.1273 63.6366C25.279 65.3991 14.0178 60.3317 10.1723 45.39L5.68591 27.9581ZM29.4974 36.9825C29.7728 36.1592 30.3751 35.1748 31.22 34.718C31.6226 34.5003 32.092 34.3941 32.6524 34.4852C33.2239 34.5782 33.9371 34.8847 34.7941 35.5762C35.1165 35.8364 35.5887 35.7859 35.8488 35.4635C36.1089 35.1412 36.0584 34.669 35.7361 34.4089C34.737 33.6027 33.7888 33.1503 32.8932 33.0047C31.9865 32.8572 31.1832 33.0327 30.5066 33.3985C29.1934 34.1085 28.4124 35.4978 28.0749 36.5067C27.9435 36.8995 28.1554 37.3245 28.5483 37.4559C28.9411 37.5873 29.3661 37.3753 29.4974 36.9825ZM37.0388 49.0966C33.2063 51.0818 25.2659 52.8742 21.1034 52.7202C23.3449 55.997 26.1904 57.2628 30.888 56.4847C34.8449 55.8293 36.5723 51.4553 37.0388 49.0966ZM16.2584 40.7203C16.5338 39.897 17.1361 38.9126 17.981 38.4557C18.3836 38.2381 18.853 38.1319 19.4134 38.223C19.9849 38.316 20.6981 38.6225 21.5551 39.314C21.8775 39.5742 22.3496 39.5237 22.6098 39.2013C22.8699 38.879 22.8194 38.4068 22.4971 38.1467C21.4979 37.3405 20.5498 36.8881 19.6542 36.7425C18.7475 36.595 17.9442 36.7705 17.2676 37.1363C15.9544 37.8463 15.1734 39.2356 14.8359 40.2445C14.7045 40.6373 14.9164 41.0623 15.3093 41.1936C15.7021 41.325 16.127 41.1131 16.2584 40.7203Z"
        fill={color2}
      />
    </svg>
  );
};

export const CultureIconSmall = ({
  presentation = 'color',
}: {
  presentation?: 'color' | 'grayscale' | 'dark';
}) => {
  const color1 = pick(presentation, {
    color: '#3971A0',
    grayscale: '#999',
    dark: '#ffffff80',
  });

  const color2 = pick(presentation, {
    color: '#6699C3',
    grayscale: '#B3B3B3',
    dark: '#ffffff',
  });

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7052 3.50185C13.2711 3.50185 11.1247 2.64331 11.1247 2.64331V8.65312C11.1247 8.88074 11.131 9.10334 11.1432 9.32091H11.4614V11.3715C12.347 14.7105 14.8044 16.38 16.7052 16.38C19.0662 16.38 22.2858 13.8044 22.2858 8.65312V2.64331C22.2858 2.64331 20.1394 3.50185 16.7052 3.50185ZM18.2825 6.71979C18.4223 6.93575 18.6534 7.1461 18.8787 7.20213C18.9766 7.22649 19.0806 7.22447 19.2007 7.1718C19.3277 7.11613 19.501 6.99092 19.7024 6.72035C19.8673 6.49885 20.1806 6.45297 20.4021 6.61787C20.6236 6.78277 20.6695 7.09601 20.5046 7.31751C20.2305 7.6857 19.9297 7.94411 19.6022 8.08767C19.2679 8.23424 18.9368 8.24705 18.6373 8.17257C18.0677 8.0309 17.6539 7.58882 17.4431 7.26337C17.293 7.03159 17.3592 6.72201 17.591 6.5719C17.8228 6.4218 18.1324 6.48801 18.2825 6.71979ZM19.5033 12.7201C18.0989 12.3971 15.3823 12.4783 14.0492 12.8745C14.5013 11.6284 15.316 10.9823 16.8997 10.843C18.2337 10.7257 19.1562 11.9962 19.5033 12.7201ZM13.6906 6.82647C13.8305 7.04243 14.0615 7.25278 14.2868 7.30881C14.3848 7.33317 14.4887 7.33115 14.6089 7.27848C14.7359 7.22281 14.9092 7.09759 15.1106 6.82703C15.2755 6.60553 15.5887 6.55965 15.8102 6.72455C16.0317 6.88945 16.0776 7.20269 15.9127 7.42419C15.6386 7.79237 15.3379 8.05079 15.0104 8.19435C14.676 8.34092 14.345 8.35373 14.0455 8.27925C13.4758 8.13758 13.062 7.6955 12.8513 7.37005C12.7012 7.13827 12.7674 6.82869 12.9992 6.67858C13.2309 6.52848 13.5405 6.59469 13.6906 6.82647Z"
        fill={color1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.857117 8.9729C0.857117 8.9729 3.14973 9.26938 6.47552 8.41344C9.80132 7.55749 11.666 6.19108 11.666 6.19108L13.1639 12.0112C14.4478 16.9999 11.9718 20.2967 9.68532 20.8852C7.39884 21.4736 3.63894 19.7817 2.35502 14.793L0.857117 8.9729ZM9.04391 12.0651C9.12553 11.8211 9.29685 11.5598 9.50105 11.4494C9.58986 11.4014 9.69103 11.3774 9.82052 11.3985C9.9574 11.4207 10.1564 11.4988 10.4189 11.7106C10.6338 11.884 10.9486 11.8504 11.122 11.6355C11.2954 11.4206 11.2618 11.1058 11.0469 10.9324C10.6897 10.6441 10.334 10.4688 9.98105 10.4114C9.62072 10.3528 9.29693 10.4229 9.02545 10.5697C8.50909 10.8489 8.21856 11.3802 8.09556 11.7479C8.00796 12.0097 8.14925 12.293 8.41113 12.3806C8.67301 12.4682 8.95632 12.327 9.04391 12.0651ZM11.3252 16.0307C10.0456 16.6935 7.39445 17.2919 6.00467 17.2405C6.75309 18.3345 7.70312 18.7572 9.27155 18.4974C10.5927 18.2786 11.1694 16.8182 11.3252 16.0307ZM4.62379 13.3129C4.70541 13.0689 4.87673 12.8076 5.08094 12.6972C5.16974 12.6492 5.27091 12.6253 5.4004 12.6463C5.53728 12.6686 5.73629 12.7467 5.9988 12.9585C6.21371 13.1319 6.52849 13.0982 6.7019 12.8833C6.87531 12.6684 6.84167 12.3536 6.62677 12.1802C6.26955 11.892 5.91388 11.7167 5.56093 11.6593C5.2006 11.6007 4.87681 11.6708 4.60533 11.8176C4.08897 12.0967 3.79844 12.628 3.67544 12.9957C3.58784 13.2576 3.72913 13.5409 3.99101 13.6285C4.25289 13.7161 4.5362 13.5748 4.62379 13.3129Z"
        fill={color2}
      />
    </svg>
  );
};
