import * as React from 'react';
import { styled } from '@stitches/react';

const ImageWrapper = styled('img', {
  textStyle: 'small-text',
  color: '$colors$white-alpha-70',
  textAlign: 'center',
});

export const Image = (props: React.ComponentProps<typeof ImageWrapper>) => {
  return <ImageWrapper crossOrigin="anonymous" {...props} />;
};
