import * as React from 'react';
import { PointsIcon } from '@naan/icons/points-icon';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  background: '$yellow-200',
  padding: '0 16px',
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  color: '$colors$textPrimary',

  '& > div': {
    paddingBlock: 20,
  },

  '& > div + div': {
    borderTop: '1px solid $black-alpha-06',
  },

  '& .latest': {
    display: 'flex',
    justifyContent: 'space-between',
    '& .heading': {
      color: '$textPrimary',
      textStyle: 'small-heading',
    },
  },

  '& .history': {
    display: 'flex',
    justifyContent: 'center',
    gap: 12,
    paddingBlock: 16,
  },

  '& .points-display': {
    display: 'flex',
    flexDirection: 'row',
    textStyle: 'body-bold',
    '& .points-icon': {
      color: '$yellow-500',
      svg: {
        width: 20,
      },
    },

    '&.larger': {
      textStyle: 'small-heading',
      svg: {
        width: 24,
      },
    },

    '& .title': {
      color: '$black-alpha-50',
      margin: '3px 0 0 4px',
      textStyle: 'small-text',
    },
  },
});

export const ProgressCard = ({
  itemsReviewed,
  itemPoints,
  today,
  last7Days,
}: {
  itemsReviewed: number;
  itemPoints: number;
  today: number;
  last7Days: number;
}) => {
  return (
    <Wrapper>
      <div className="latest">
        <div className="heading">
          {__('%{n} items reviewed', 'nItemsReviewed', {
            n: itemsReviewed,
          })}
        </div>
        <span className="points-display larger">
          <span className="points-icon">
            <PointsIcon />
          </span>
          <span className="count">{itemPoints}</span>
        </span>
      </div>

      <div className="history">
        <div>
          <span className="points-display">
            <span className="points-icon">
              <PointsIcon />
            </span>
            <span className="count">{today}</span>
            <span className="title">{__('today', 'todayLowercase')}</span>
          </span>
        </div>
        <div>
          <span className="points-display">
            <span className="points-icon">
              <PointsIcon />
            </span>
            <span className="count">{last7Days}</span>
            <span className="title">
              {__('last %{n} days', 'lastNDays', {
                n: 7,
              })}
            </span>
          </span>
        </div>
      </div>
    </Wrapper>
  );
};
