import React from 'react';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import {
  ChapterReviewModel,
  NotationStatus,
} from 'vocab-review/chapter-review-model';
import { IconButton } from '@naan/primitives/button/icon-button';
import {
  BookmarkCheckmarkLargeIcon,
  BookmarkFilledLargeIcon,
  BookmarkLargeIcon,
  BookmarkXLargeIcon,
  BookmarkQuestionmarkLargeIcon,
} from '@naan/icons/bookmark-icon';
import { usePlayerModel } from 'player/views/player-model-context';

const Icon = styled('div', {
  color: '$$color',
  '&.not-added': {
    $$color: '$colors$gray-400',
  },
  '&.just-added': {
    $$color: '$colors$purple-500',
  },
  '&.got-it': {
    $$color: '$colors$green-500',
  },
  '&.not-got-it': {
    $$color: '$colors$purple-500',
  },
  '&.pending': {
    $$color: '$colors$purple-500',
  },
  '&.previously-learned': {
    $$color: '$colors$green-500',
  },
});

const BookmarkIconMap: Record<NotationStatus, React.ReactNode> = {
  'not-added': <BookmarkLargeIcon />,
  'just-added': <BookmarkFilledLargeIcon />,
  'got-it': <BookmarkCheckmarkLargeIcon />,
  'not-got-it': <BookmarkXLargeIcon />,
  pending: <BookmarkQuestionmarkLargeIcon />,
  'previously-learned': <BookmarkCheckmarkLargeIcon />,
};

const BookmarkButton = observer(
  ({
    onClick,
    notation,
    notationStatus,
  }: Pick<React.ComponentProps<typeof IconButton>, 'onClick'> & {
    notation: ClientNotation;
    notationStatus: NotationStatus;
  }) => {
    return (
      <IconButton
        presentation={'grayTransparent'}
        size={'large'}
        onClick={onClick}
        icon={
          <Icon className={notationStatus}>
            {BookmarkIconMap[notationStatus]}
          </Icon>
        }
        testId={`bookmark-button-${notation.id}`}
      />
    );
  }
);

const StatusIconWrapper = styled('div', {
  color: '$$color',
  display: 'flex',
  width: 48,
  height: 48,
  alignItems: 'center',
  justifyContent: 'center',
});

export const ItemStatusIcon = observer(
  ({ status }: { notation: ClientNotation; status: NotationStatus }) => {
    return (
      <div>
        <StatusIconWrapper>
          <Icon className={status}>{BookmarkIconMap[status]}</Icon>
        </StatusIconWrapper>
      </div>
    );
  }
);

export const ItemActionButton = observer(
  ({
    notation,
    status,
  }: {
    notation: ClientNotation;
    status: NotationStatus;
  }) => {
    const model = usePlayerModel() as ChapterReviewModel;

    const handleClick = React.useCallback(
      (notationId: ClientNotation['id']) => {
        const notationStatus = model.vocabStatus(notationId);

        switch (notationStatus) {
          case 'previously-learned':
          case 'not-added': {
            return model.markJustAdded(notationId);
          }

          case 'just-added': {
            return model.undoJustAdded(notationId);
          }
        }
      },
      [model]
    );

    return (
      <BookmarkButton
        onClick={() => handleClick(notation.id)}
        notation={notation}
        notationStatus={status}
      />
    );
  }
);
