import React from 'react';
import { createLogger } from 'app/logger';
import { observer } from 'mobx-react';
// import { AppFactory } from 'app/app-factory';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import { IconButton } from '@naan/primitives/button/icon-button';
import { PlainMarkdown } from '@naan/primitives/text';
import { AddToVocabTip } from 'components/ui/onboarding/add-to-vocab-tip';
import {
  BookmarkCheckmarkLargeIcon,
  BookmarkFilledLargeIcon,
  BookmarkLargeIcon,
} from '@naan/icons/bookmark-icon';
import { usePlayerModel } from 'player/views/player-model-context';
import { StudyModel } from 'study/models/study-model';

const log = createLogger('sentence-notations-list-item');

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 36px',
  padding: '8px 0 12px',
  '& .head': {
    cursor: 'default',
    '& dt': {
      textStyle: 'serif',
      // color: 'blue',
      marginBottom: -2,
    },
    '& dd': {
      textStyle: 'body',
      color: '$colors$textSecondary',
    },
  },
  '& .actions': {
    display: 'flex',
    alignItems: 'center',
  },
});

const ItemButton = observer(({ notation }: { notation: ClientNotation }) => {
  const model = usePlayerModel() as StudyModel;
  const progress = model.chapter.story.progress;

  const addVocab = React.useCallback(
    (slug: string) => {
      log.info(`addVocab(${slug})`);
      progress.addVocab(slug, { persist: true });
    },
    [progress]
  );

  const removeVocab = React.useCallback(
    (slug: string) => {
      log.info(`removeVocab(${slug})`);
      progress.removeVocab(slug, { persist: true });
    },
    [progress]
  );

  return (
    <>
      {progress.vocabExists(notation.id) ? (
        <IconButton
          presentation={'purple'}
          size={'large'}
          onClick={() => removeVocab(notation.id)}
          icon={<BookmarkFilledLargeIcon />}
          testId={`list-remove-from-vocab-${notation.id}`}
        />
      ) : progress.vocabLearned(notation.id) ? (
        // @armando, hacked placeholder
        <IconButton
          presentation={'greenTransparent'}
          size={'large'}
          onClick={() => addVocab(notation.id)}
          icon={<BookmarkCheckmarkLargeIcon />}
          testId={`list-add-to-vocab-${notation.id}`}
        />
      ) : (
        <IconButton
          presentation={'grayTransparent'}
          size={'large'}
          onClick={() => addVocab(notation.id)}
          icon={<BookmarkLargeIcon />}
          testId={`list-add-to-vocab-${notation.id}`}
        />
      )}
    </>
  );
});

export const NotationsListItem = observer(
  ({
    notation,
    withOnboardingTip: withOnboardingtip,
  }: {
    notation: ClientNotation;

    withOnboardingTip?: boolean;
  }) => {
    // todo: factor with vocab review and notatil detail
    const text = !!notation.usageText
      ? `${notation.usageText} (${notation.headword})`
      : notation.headword;

    return (
      <Wrapper>
        <div
          className="head"
          // onClick={() => model.selectNotationId(notation.id)}
        >
          <dl>
            <dt>
              <PlainMarkdown source={text} />
            </dt>
            {notation.note ? (
              <dd>
                <PlainMarkdown source={notation.note} />
              </dd>
            ) : null}
          </dl>
        </div>

        <div className="actions">
          {withOnboardingtip ? (
            <AddToVocabTip>
              <ItemButton notation={notation} />
            </AddToVocabTip>
          ) : (
            <ItemButton notation={notation} />
          )}
        </div>
      </Wrapper>
    );
  }
);
