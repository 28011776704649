import { styled } from 'naan/stitches.config';
import { Insets } from 'native-support/insets';
import { palette, paletteKey, darkPalette } from 'study/views/study-palette';

export const LayoutContainer = styled('section', {
  ...palette,
  position: 'fixed',
  left: 0,
  bottom: 0,
  right: 0,
  // top: 'var(--statusbarHeight,0px)',
  top: Insets.top,

  backgroundColor: paletteKey('$$script__backgroundColor_default'),
  zIndex: 2,
  display: 'grid',
  gridTemplateRows: '1fr auto',

  '&::before': {
    content: '""',
    display: 'block',
    height: Insets.top,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    // background: 'red',
    zIndex: 100,
    background: 'var(--statusbarBgColor, $color$gray-800)',
  },

  '&.listen-mode': {
    ...darkPalette,
  },
});

LayoutContainer.displayName = 'LayoutContainer';
// LayoutContainer.defaultProps = {
//   id: 'study-layout-container',
// };

export const HeaderContainer = styled('div', {
  $$backgroundColor: paletteKey('$$header__backgroundColor'),

  position: 'absolute',
  top: '0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '56px', // TODO: put in a variable
  padding: '0 8px',
  flexShrink: '0',
  background: '$$backgroundColor',
  zIndex: '2',
  transition: 'transform .3s, background .3s',

  '& > .center': {
    overflow: 'hidden',
    display: 'flex',
    '@medium': {
      position: 'absolute',
      alignItems: 'center',
      left: '50%',
      transform: 'translateX(-50%)',
      top: 0,
      bottom: 0,
    },
  },

  '& > .right': {
    marginLeft: 'auto',
  },
  '&.playing': {
    transform: 'translateY(-100%)',
    $$backgroundColor: paletteKey('$$script__backgroundColor_playing'),
  },
});

export const ScriptOuterContainer = styled('div', {
  '--top-notation-pos': 0,

  overflow: 'hidden',
  overflowY: 'auto',
  overscrollBehaviorY: 'contain',
  transition: 'background .3s',
  backgroundColor: paletteKey('$$script__backgroundColor_default'),
  '&.playing': {
    backgroundColor: paletteKey('$$script__backgroundColor_playing'),
  },
});

ScriptOuterContainer.displayName = 'ScriptOuterContainer';

export const ScriptInnerContainer = styled('div', {
  width: 'min( calc( 100% - $space$4), 600px)',
  margin: '0 auto',
});

ScriptInnerContainer.displayName = 'ScriptInnerContainer';

export const ControlsContainer = styled('div', {
  background: '$colors$gray-25',
  display: 'flex',
  flexShrink: '0',
  position: 'relative',
  zIndex: '2',
});
