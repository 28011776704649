import ptSubtitles from './vocab-review-pt.vtt?url';
import enSubtitles from './vocab-review-en.vtt?url';
import esSubtitles from './vocab-review-es.vtt?url';

import { pickLocalizedValue } from '@core/lib/localization';

// for now we have only the one video asset
const videoId = '556a13523d25a08dd955f3d9274a6a93';

const en = {
  subtitles: enSubtitles,
  videoId,
};

const pt = {
  subtitles: ptSubtitles,
  videoId,
};

const es = {
  subtitles: esSubtitles,
  videoId,
};

export function getVideoAssets() {
  return pickLocalizedValue({ en, pt, es });
}
