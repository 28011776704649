import * as React from 'react';

export type Switch = {
  value: boolean;
  toggle: () => void;
  on: () => void;
  off: () => void;
};

export function useSwitch2(initialState: boolean): Switch {
  const [value, setValue] = React.useState(initialState);
  const toggle = () => setValue(!value);
  const on = () => setValue(true);
  const off = () => setValue(false);
  return { value, toggle, on, off };
}
