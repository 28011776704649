import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';

// unfactored this component. It has too many subtle variatons to make it worth having a separate component.
const BadgeWrapper = styled('div', {
  $$color: '$colors$gray-400',
  // all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  textStyle: 'small-text-bold',
  padding: '8px 0',
  color: '$$color',
  gap: 2,
  // background: '$yellow-100',
  variants: {
    presentation: {
      lightGray: {
        $$color: '$colors$gray-200',
      },
      gray: {
        $$color: '$colors$gray-400',
      },
      purple: {
        $$color: '$colors$purple-500',
      },
      orange: {
        $$color: '$colors$orange-500',
      },
    },
  },
});

type BadgeWrapperProps = React.ComponentProps<typeof BadgeWrapper>;

export const ChapterSoundbiteBadge = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const story = chapter.story;
    if (chapter.chapterSoundbites.length === 0) {
      return null;
    }
    /// @frankharrison this doesn't feel right to me. But I double checked and the code looks right.
    /// taken from the pseudocode here: https://jiveworld.slite.com/app/docs/4syUA4DXT2RUUs#781ec556
    let presentation: BadgeWrapperProps['presentation'] = 'gray';
    if (
      story.inProgress &&
      !chapter.isCompleted &&
      !chapter.isFurthest &&
      chapter.completedSoundbitesCount === 0
    ) {
      presentation = 'lightGray';
    } else if (
      story.inProgress &&
      chapter.isCompleted &&
      chapter.hasIncompleteSoundbites
    ) {
      presentation = 'orange';
    }
    return (
      <BadgeWrapper presentation={presentation}>
        <SoundbiteIcon />
        {chapter.soundbiteDisplayProgress()}
      </BadgeWrapper>
    );
  }
);
