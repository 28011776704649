import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager';
import { LockSmallIcon } from '@naan/icons/lock-icon';
import { ProgressPie } from 'components/ui/progress-pie';
import { ClockSmallIcon } from '@naan/icons/clock-icon';
import { CheckmarkCircleSmallIcon } from '@naan/icons/checkmark-circle-icon';
import { MarkSimple } from '@naan/primitives/text';

const Title = styled('h2', {
  textStyle: 'small-heading',
  color: '$colors$textPrimary',
  marginBottom: '$1',
  // display: 'flex',
  '&:hover': {
    color: '$colors$teal-500',
  },
  // we nudge the TitleStatus icons from here for the story cards, so it doesn't effect layout when we pull just TitleStatus in to other components
  '& > span': {
    marginBottom: '-3px',
  },
});

const IconWrapper = styled('span', {
  variants: {
    presentation: {
      white: {
        color: '$colors$white',
      },
      darkGray: {
        color: '$colors$gray-800',
      },
      teal: {
        color: '$colors$teal-500',
      },
      orange: {
        color: '$colors$orange-500',
      },
      gray: {
        color: '$colors$gray-500',
      },
    },
  },
});

const StatusWrapper = styled(IconWrapper, {
  marginRight: -2,
  display: 'inline-block',
  position: 'relative',
  left: -2,
  variants: {
    size: {
      small: {},
      medium: {
        '& svg': {
          width: 24,
          height: 24,
        },
      },
    },
  },
});

const ProgressPieWrapper = styled(IconWrapper, {
  padding: 2,
  marginRight: -2,
  display: 'inline-block',
  position: 'relative',
  left: -2,
});

export const TitleStatus = observer(
  ({
    story,
    presentation = 'colored',
    size = 'small',
  }: {
    story: Story;
    presentation?: 'white' | 'colored';
    size?: 'small' | 'medium';
  }) => {
    const { locked, studyProgressPercentage } = story;
    const colored = presentation === 'colored';

    if (locked) {
      return (
        <StatusWrapper
          presentation={colored ? 'darkGray' : 'white'}
          size={size}
        >
          <LockSmallIcon />
        </StatusWrapper>
      );
    }
    const { inProgress, queued, completed } = story;

    if (inProgress && !completed) {
      return (
        <ProgressPieWrapper presentation={colored ? 'teal' : 'white'}>
          <ProgressPie
            current={studyProgressPercentage / 100.0}
            size={size === 'small' ? 16 : 20}
          />
        </ProgressPieWrapper>
      );
    }
    if (queued) {
      return (
        <StatusWrapper presentation={colored ? 'orange' : 'white'} size={size}>
          <ClockSmallIcon />
        </StatusWrapper>
      );
    }

    if (completed) {
      return (
        <StatusWrapper presentation={colored ? 'gray' : 'white'} size={size}>
          <CheckmarkCircleSmallIcon />
        </StatusWrapper>
      );
    }

    return null;
  }
);

export const StoryTitle = observer(({ story }: { story: Story }) => (
  <Title>
    <TitleStatus story={story} />
    <MarkSimple source={story.title} />
  </Title>
));
