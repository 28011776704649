import * as React from 'react';
import { Button } from '@naan/primitives/button';
import { SoundbiteIconExtraSmall } from '@naan/icons/soundbite-icon';
import { useCurrentStory } from '../current-story-context';
import { elementIds } from 'components/dom-utils/element-ids';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { SoundbitesModalTip } from 'components/ui/onboarding/soundbites-modal-tip';
import { styled } from '@naan/stitches.config';
import { storyDetailUiModel } from '../story-detail-ui-model';

import __ from '@core/lib/localization';

const ToolipPositioner = styled('span', {
  zIndex: 1,
  '@large': {
    marginRight: 0,
  },
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: 12,
  },
});

const SoundbitesTooltip: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const dismissed = OnboardingService.instance.isDismissed('soundbitesModal');
  const showPopover = OnboardingService.instance.showSoundbitesModalTip;

  return (
    <OnboardingPopover
      dismissed={dismissed}
      showPopover={showPopover}
      renderAnchor={({ ref, getProps }) => (
        <ToolipPositioner
          ref={ref}
          {...getProps()}
          className={showPopover ? 'highlight' : null}
        >
          {children}
        </ToolipPositioner>
      )}
      renderBody={() => {
        return <SoundbitesModalTip />;
      }}
      onResolvePopover={
        OnboardingService.instance.onSoundbitesModalTipDismissed
      }
    />
  );
};

export const SoundbitesModalButton = () => {
  const story = useCurrentStory();

  if (!story.hasVisibleSoundbites) {
    return null;
  }

  return (
    <SoundbitesTooltip>
      <Button
        leftIcon={<SoundbiteIconExtraSmall />}
        size="small"
        onClick={storyDetailUiModel.showSoundbitesModal}
        testId={'soundbites-button'}
        id={elementIds.SOUNDBITES_MODAL_BUTTON}
        label={__('%{count} Soundbites', 'nSoundbites', {
          count: story.soundbiteDisplayProgress(),
        })}
        presentation="whiteTransparent"
        css={{
          boxShadow: 'inset 0px 0px 0px 1px $colors$white-alpha-30',
        }}
      />
    </SoundbitesTooltip>
  );
};
