import * as React from 'react';

import { CloseIcon } from '@naan/icons/close-icon';
import { RewindIcon } from '@naan/icons/rewind-icon';
import { Button, IconButton } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { PlayerViewController } from 'components/ui/video-player/player-view-controller';
import { observer } from 'mobx-react';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import { OverlayButton } from 'components/ui/video-player/video-player2/overlay-button';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  padding: 24,
  paddingBottom: 'calc(24px + var(--sab))',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  zIndex: 2,

  '& > .exit-buttons': {
    position: 'absolute',
    left: 16,
    top: 16,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
});

export const EndOverlay = observer(
  ({
    player,
    onComplete,
    onExit,
  }: {
    player: PlayerViewController;
    onComplete: () => void;
    onExit: () => void;
  }) => {
    return (
      <Wrapper className="final-overlay">
        <div className="exit-buttons">
          <IconButton
            presentation={'whiteDarken'}
            size="small"
            testId={'video-exit-button'}
            icon={<CloseIcon />}
            onClick={() => {
              onExit();
            }}
          />
          <Button
            label={__('Replay', 'replay')}
            leftIcon={<RewindIcon />}
            presentation={'whiteDarken'}
            size="small"
            alignment={'unset'}
            fullWidth
            onClick={() => {
              player.videoElement.currentTime = 0;
              void player.videoElement.play();
            }}
          />
        </div>
        <OverlayButton
          presentation={'teal'}
          rightIcon={<ArrowRightIcon />}
          label={__("Let's go", 'letsGo')}
          onClick={() => {
            // void player.videoElement.play();
            onComplete();
          }}
        />
      </Wrapper>
    );
  }
);
