import * as React from 'react';
import { Button } from '@naan/primitives/button';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { BookmarkFilledLargeIcon } from '@naan/icons/bookmark-icon';
import { useCurrentStory } from './current-story-context';
import { StoryPointsDisplay } from './story-points-display';
import classNames from 'classnames';
import { storyDetailUiModel } from './story-detail-ui-model';
import { elementIds } from 'components/dom-utils/element-ids';
import { PROMOTED_REVIEW_TRESHOLD } from '@core/models/ui/vocab-review-model';

import __ from '@core/lib/localization';
import { LongVocabTip } from 'components/ui/onboarding/long-vocab-tip';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { AppFactory } from '@app/app-factory';
// import { AppFactory } from '@app/app-factory';

const Outer = styled('div', {
  position: 'sticky',
  zIndex: 1,
  backgroundColor: '$white',
  top: 'calc($$assignmentBannerHeight + $$storyHeaderHeight )',
  borderBottom: '1px solid $gray-100',
});

const ButtonWrapper = styled('div', {
  $$contextualButtonBackgroundColor: '$colors$black-alpha-06',
  $$contextualButtonTextColor: '$colors$black-alpha-50',
  $$contextualButtonBackgroundColorHover: '$colors$black-alpha-10',
  $$contextualButtonTextColorHover: '$colors$black-alpha-50',

  '&.zero': {
    opacity: 0.4,
  },

  '&.promoted': {
    $$contextualButtonBackgroundColor: '$colors$purple-500',
    $$contextualButtonTextColor: '$colors$white',
    $$contextualButtonBackgroundColorHover: '$colors$purple-600',
    $$contextualButtonTextColorHover: '$colors$white',
  },
});

const VocabReviewButton = observer(
  ({
    vocabCount,
    promoted,
    onClick,
  }: {
    vocabCount: number;
    promoted: boolean;
    onClick: () => void;
  }) => {
    const haveVocab = vocabCount > 0;
    const maybeAction = React.useCallback(() => {
      if (haveVocab) {
        onClick();
      }
    }, [haveVocab, onClick]);

    return (
      <LongVocabTip>
        <ButtonWrapper
          className={classNames({
            zero: !haveVocab,
            promoted: haveVocab && promoted,
          })}
        >
          <Button
            label={__('Vocab review', 'vocabReview') + ' (' + vocabCount + ')'}
            presentation={'contextual'}
            size={'small'}
            leftIcon={<BookmarkFilledLargeIcon />}
            onClick={maybeAction}
          />
        </ButtonWrapper>
      </LongVocabTip>
    );
  }
);

const HeaderWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBlock: 12,
});
export const ChapterListHeader = observer(() => {
  const story = useCurrentStory();

  if ((!story.started && !story.completed) || story.locked) {
    return null;
  }

  // const vocabReviewModel =
  //   AppFactory.chapterReviewModel?.vocabReviewModel || null;
  // const vocabCount = vocabReviewModel?.allItemCount || 0;
  const vocabCount = story.vocabCount; // todo: consider vocab resume flow and if the count shown here should reflect that

  const promoted =
    vocabCount > PROMOTED_REVIEW_TRESHOLD ||
    (story.completed && vocabCount > 0);

  const { hasDoneVocabReview } = AppFactory.root.userManager.userData;

  if (promoted && story.inProgress && !hasDoneVocabReview) {
    OnboardingService.instance.onTooLongVocab();
  }

  // TODO: use dynamic data instead of cardcoded values
  return (
    <Outer id={elementIds.CHAPTER_LIST_TOOLBAR}>
      <CenterColumnLayout narrow>
        <HeaderWrapper>
          <div>
            <VocabReviewButton
              promoted={promoted}
              vocabCount={vocabCount}
              onClick={() => {
                storyDetailUiModel.showVocabReviewModal();
              }}
            />
          </div>
          <div>
            <StoryPointsDisplay story={story} />
          </div>
        </HeaderWrapper>
      </CenterColumnLayout>
    </Outer>
  );
});
