import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { Button } from '@naan/primitives/button';
import { RewindIcon } from '@naan/icons/rewind-icon';
import { CloseIcon } from '@naan/icons/close-icon';
import { StudyAndListenSection } from './study-and-listen-section';
import { VocabularySection } from './vocabulary-section';
import { SoundbiteSection } from './soundbites-section';
import { scrollToCurrentChapter } from 'routes/learn/scrolling-fx';
import { elementIds } from 'components/dom-utils/element-ids';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  backgroundColor: '$colors$gray-50',
  paddingBottom: 48,

  '& .heading': {
    display: 'flex',
    height: 56,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid $colors$black-alpha-10',
    h2: {
      textStyle: 'small-heading',
    },
    '& button': {
      alignSelf: 'center',
    },
  },

  '& .content': {
    '& .section': {},
  },
});

const RelistenButton = () => {
  const story = useCurrentStory();

  const relisten = story.progressMayBeNull?.inReviewMode;

  if (!story.completed) {
    return null;
  }

  return (
    <>
      {relisten ? (
        <Button
          presentation="grayDark"
          size="small"
          label={__('Exit relisten', 'exitRelisten')}
          leftIcon={<CloseIcon />}
          onClick={() => {
            story.exitRelisten();
          }}
        />
      ) : (
        <Button
          presentation="grayDark"
          size="small"
          label={__('Relisten', 'relisten')}
          leftIcon={<RewindIcon />}
          onClick={() => {
            story.relisten();
            setTimeout(() => {
              scrollToCurrentChapter(true);
              /// we do a timeout for scrollign because a reaction was too complex
            }, 100);
          }}
        />
      )}
    </>
  );
};

const InnerProgressPanel = () => {
  const story = useCurrentStory();

  // TODO: add rest of conditions from here https://jiveworld.slite.com/app/docs/l60_anG6o2kOfY#ae26629f
  const allDone =
    story.completed && story.allSoundbitesCompleted && story.vocabCount === 0;

  return (
    <Wrapper id={elementIds.PROGRESS_PANEL}>
      <CenterColumnLayout narrow>
        <div className="heading">
          <h2>
            {allDone
              ? __('All done!', 'allDone')
              : __('My story progress', 'myStoryProgress')}
          </h2>
          <RelistenButton />
        </div>
        <div className="content">
          <StudyAndListenSection />
          <VocabularySection />
          <SoundbiteSection />
        </div>
      </CenterColumnLayout>
    </Wrapper>
  );
};

export const ProgressPanel = observer(() => {
  const story = useCurrentStory();

  if ((!story.inProgress && !story.completed) || story.locked) {
    return null;
  }

  // little hack to avoid wrapping the whole thing in observer
  return <InnerProgressPanel />;
});
