import * as React from 'react';

export const ShortcutSection: React.FC<
  React.PropsWithChildren<{
    title: string;
    children?: React.ReactNode;
  }>
> = ({ children, title }) => {
  return (
    <div className="section">
      <h5 className="title">{title}</h5>
      {children}
    </div>
  );
};
