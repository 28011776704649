import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { CheckmarkHeavyIcon } from '@naan/icons/checkmark-heavy-icon';
import { SectionItem } from './section-item';

const SectionHeadingWrapper = styled('div', {
  '& .section-header': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '32px 0 10px',

    '& .icon': {
      color: '$colors$green-500',
      marginRight: 3,
      svg: {
        width: 16,
        height: 16,
      },
    },

    '& .label': {
      textStyle: 'small-caps',
      color: '$colors$gray-600',
    },

    '& .action': {
      marginLeft: 'auto',
    },
  },

  '& .section-content': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 12,
  },
});

interface IProgressPanelSection {
  completed?: boolean;
  title: string;
  action?: React.ReactNode;
  children:
    | React.ReactElement<typeof SectionItem>
    | Array<React.ReactElement<typeof SectionItem>>;
}

export const ProgressPanelSection: React.FC<IProgressPanelSection> = ({
  completed = false,
  title,
  action,
  children,
}) => {
  return (
    <SectionHeadingWrapper>
      <div className="section-header">
        {completed ? (
          <div className="icon">
            <CheckmarkHeavyIcon />
          </div>
        ) : null}
        <div className="label">{title}</div>
        {action ? <div className="action">{action}</div> : null}
      </div>
      <div className="section-content">{children}</div>
    </SectionHeadingWrapper>
  );
};
