import * as React from 'react';

export function RewindIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.84953 3.05061C9.45901 3.44113 9.45901 4.0743 9.84953 4.46482L12.6073 7.22254C12.9978 7.61306 13.6309 7.61306 14.0215 7.22254C14.412 6.83201 14.412 6.19885 14.0215 5.80833L12.8624 4.64922C16.571 5.0763 19.4508 8.22682 19.4508 12.0499C19.4508 16.1645 16.1153 19.4999 12.0008 19.4999C7.88626 19.4999 4.55078 16.1645 4.55078 12.0499C4.55078 11.4977 4.10307 11.0499 3.55078 11.0499C2.9985 11.0499 2.55078 11.4977 2.55078 12.0499C2.55078 17.269 6.78169 21.4999 12.0008 21.4999C17.2199 21.4999 21.4508 17.269 21.4508 12.0499C21.4508 7.19199 17.7851 3.19019 13.0689 2.65964L14.0215 1.70711C14.412 1.31658 14.412 0.683417 14.0215 0.292893C13.6309 -0.0976311 12.9978 -0.097631 12.6073 0.292893L9.84953 3.05061Z"
        fill={color}
      />
    </svg>
  );
}
export function RewindExtraSmallIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6101 2.17879C6.27763 2.42584 6.20839 2.89564 6.45544 3.22811L8.23618 5.62453C8.48323 5.957 8.95303 6.02624 9.2855 5.77919C9.61797 5.53214 9.68722 5.06234 9.44016 4.72987L8.57635 3.56739C10.7894 3.85033 12.5 5.74082 12.5 8.03083C12.5 10.5161 10.4853 12.5308 8 12.5308C5.51472 12.5308 3.5 10.5161 3.5 8.03083C3.5 7.61661 3.16421 7.28083 2.75 7.28083C2.33579 7.28083 2 7.61661 2 8.03083C2 11.3445 4.68629 14.0308 8 14.0308C11.3137 14.0308 14 11.3445 14 8.03083C14 5.11769 11.9239 2.68944 9.17056 2.14495L9.90118 1.60204C10.2337 1.35499 10.3029 0.885188 10.0558 0.552717C9.80879 0.220245 9.33899 0.151 9.00652 0.398054L6.6101 2.17879Z"
        fill={color}
      />
    </svg>
  );
}
