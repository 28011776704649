import React from 'react';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

const ZeroStateWrapper = styled('div', {
  height: '100%',
  display: 'grid',
  placeItems: 'center',
  textAlign: 'center',
  textStyle: 'body-bold',
  color: '$colors$gray-300',
  flex: 1,
});

export const ZeroState = () => {
  return (
    <ZeroStateWrapper>
      {__('No vocabulary for this sentence', 'noVocabularyForThisSentence')}
    </ZeroStateWrapper>
  );
};
