import { __f } from '@core/lib/localization';

export const sortingKeysFn = {
  duration: __f('Duration', 'duration'),
  title: __f('A-Z', 'aToZ'),
  release_date: __f('Most recent', 'mostRecent'),
  broadcast_date: __f('Original air date', 'originalAirDate'),
  // vocab: __f('Vocabulary', 'vocabulary'), // todo: discuss how to better handle the vocab specific sort
} as const;

export type TSortingKey = keyof typeof sortingKeysFn;
