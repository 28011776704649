import * as React from 'react';
import * as Onboarding from 'components/ui/onboarding/components';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { PROMOTED_REVIEW_TRESHOLD } from '@core/models/ui/vocab-review-model';
import { ProgressPanelSection } from './panel-section';
import { SectionItem } from './section-item';
import {
  BookmarkCheckmarkIcon,
  BookmarkFilledIcon,
} from '@naan/icons/bookmark-icon';
import { SectionHeaderButton } from './section-header-button';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';
import { OnboardingService } from '@app/onboarding/onboarding-service';

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $purple-300',
    borderRadius: '8px',
  },
});

const VocabReviewOnboardingTip: React.FC<{
  children: React.ReactNode;
  storySlug: string;
  vocabCount: number;
}> = observer(({ children, storySlug, vocabCount }) => {
  const dismissed =
    OnboardingService.instance.isDismissed('vocabReviewTip') ||
    OnboardingService.instance.isDismissed('vocabReviewWithAdditionsTip');
  const showReviewTip =
    OnboardingService.instance.currentTipKey === 'vocabReviewTip';
  const showReviewWithAdditionsTip =
    OnboardingService.instance.currentTipKey === 'vocabReviewWithAdditionsTip';

  // const dismissed = false;
  const showPopover = showReviewTip || showReviewWithAdditionsTip;

  return (
    <OnboardingPopover
      dismissed={dismissed}
      showPopover={showPopover}
      renderAnchor={({ ref, getProps }) => (
        <ToolipPositioner
          ref={ref}
          {...getProps()}
          className={showPopover ? 'highlight' : null}
        >
          {children}
        </ToolipPositioner>
      )}
      renderBody={() => {
        return (
          <Onboarding.Container>
            <Onboarding.Content>
              {showReviewWithAdditionsTip
                ? __(
                    "Your vocabulary list was a bit short, so we've added some interesting items to it from this story. Tap to begin a review.",
                    'vocabReviewTipTooShort'
                  )
                : __(
                    "You've finished the story, but do you remember the vocabulary you saved? Tap to begin a review.",
                    'vocabReviewTipDoYouRemember'
                  )}
            </Onboarding.Content>
          </Onboarding.Container>
        );
      }}
      onResolvePopover={() =>
        OnboardingService.instance.onDismissVocabReviewTip({
          storySlug: storySlug,
          vocabCount: vocabCount,
        })
      }
    />
  );
});

const VocabReviewPanel = observer(() => {
  const story = useCurrentStory();
  const count = story.vocabCount;

  const disabled = count === 0;
  // https://jiveworld.slite.com/app/docs/l60_anG6o2kOfY#bb06ee46
  const promoted =
    count > PROMOTED_REVIEW_TRESHOLD || (story.completed && count > 0);

  return (
    <VocabReviewOnboardingTip storySlug={story.slug} vocabCount={count}>
      <SectionItem
        disabled={disabled}
        promoted={promoted}
        presentation="purple"
        icon={<BookmarkFilledIcon />}
        title={`${count}`}
        subtitle={__('To review', 'toReview')}
        action={
          !disabled
            ? () => {
                storyDetailUiModel.showVocabReviewModal();
              }
            : null
        }
      />
    </VocabReviewOnboardingTip>
  );
});

const VocabLearnedSection = observer(() => {
  const story = useCurrentStory();
  const count = story.progressMayBeNull?.learnedVocabCount || 0;

  return (
    <SectionItem
      presentation="green"
      icon={<BookmarkCheckmarkIcon />}
      title={`${count}`}
      subtitle={__('Learned', 'learned')}
    />
  );
});

export const VocabularySection = observer(() => {
  const story = useCurrentStory();
  const vocabReviewCount = story.vocabCount;

  const completed = story.completed && vocabReviewCount === 0;

  return (
    <>
      <ProgressPanelSection
        completed={completed}
        title={__('Vocabulary', 'vocabulary')}
        action={
          <SectionHeaderButton
            presentation="purple"
            onClick={() => {
              storyDetailUiModel.showVocabListModal();
            }}
          >
            {__('Manage list', 'manageList')}
          </SectionHeaderButton>
        }
      >
        <VocabReviewPanel />
        <VocabLearnedSection />
      </ProgressPanelSection>
    </>
  );
});
