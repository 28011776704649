import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { ControlButton } from './control-button';
import { SnailIcon } from '@naan/icons/snail-icon';
import { usePlayerModel } from '../player-model-context';
import { ChapterReviewModel } from 'vocab-review/chapter-review-model';

const TogglerWrapper = styled('span', {
  color: 'inherit',
  '& svg': {
    width: 32,
    height: 32,
  },
  variants: {
    active: {
      true: {
        // color: paletteKey('$$transport__buttonColor_active'),
        color: '$colors$orange-500',
      },
    },
  },
});

export const ReviewSnailButton = observer(
  ({ disabled }: { disabled?: boolean }) => {
    const reviewModel = usePlayerModel() as ChapterReviewModel;
    const isActive = reviewModel.inSlowMode;

    return (
      <ControlButton
        onClick={() => {
          reviewModel.toggleSlowMode();
        }}
        disabled={disabled}
      >
        <TogglerWrapper active={isActive}>
          <SnailIcon />
        </TogglerWrapper>
      </ControlButton>
    );
  }
);
