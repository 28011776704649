import * as React from 'react';
import { Button } from '@naan/primitives/button';

export const OverlayButton = ({
  presentation = 'whiteDarken',
  ...props
}: Pick<
  React.ComponentProps<typeof Button>,
  'leftIcon' | 'rightIcon' | 'label' | 'onClick'
> & {
  presentation?: 'teal' | 'whiteDarken';
}) => {
  return (
    <Button
      presentation={presentation}
      size={'extraLarge'}
      alignment={'unset'}
      fullWidth
      {...props}
    />
  );
};
