import * as React from 'react';

export function ChevronRightSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5858 10L5.29291 17.2929L6.70712 18.7072L15.4142 10L6.70712 1.29294L5.29291 2.70715L12.5858 10Z"
        fill={color}
      />
    </svg>
  );
}

export function ChevronRightExtraSmallIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.14286 8L4.5 3.41176L5.92857 2L12 8L5.92857 14L4.5 12.5882L9.14286 8Z"
        fill={color}
      />
    </svg>
  );
}
