import * as React from 'react';
import { ForwardButton } from './forward-button';
import { observer } from 'mobx-react';
import { paletteKey } from '../../../study/views/study-palette';
import { RewindButton } from './rewind-button';
import { styled } from '@naan/stitches.config';
import { ReviewSnailButton } from './review-snail-button';
import { ReviewPlayPauseButton } from './review-play-pause-button';
import { KeyboardHelpButton } from 'components/ui/keyboard-help-overlay';

const PlayerControlsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  background: paletteKey('$$transport__backgroundColor'),
  transition: 'background-color 0.2s ease-in',
  paddingBottom: 'var(--sab)',
  width: '100%',

  '& > .kbd-button': {
    position: 'absolute',
    left: 20,
    bottom: 'calc(21px + var(--sab))',
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'none',
    '@medium': {
      '@media (hover: hover) and (pointer: fine)': {
        display: 'flex',
      },
    },
  },

  '& > .buttons-wrapper': {
    display: 'grid',
    gridTemplateColumns: '64px 1fr 64px',
    width: '100%',
    padding: '0 16px',
    height: 'var(--transport-bar-height)',
    transition: 'background-color 0.2s ease-in',
    gap: 8,

    '& .center-controls': {
      gridColumn: 2 / 3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
    },

    '& .left-controls': {
      gridColumn: 1 / 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '@playerSmallAndUp': {
      margin: '0 auto',
      width: 616,
      position: 'relative',
    },
  },
});

export const ReviewPlayerControls = observer(() => {
  return (
    <PlayerControlsWrapper className="players-control-wrapper">
      <span className="kbd-button">
        <KeyboardHelpButton />
      </span>
      <div className="buttons-wrapper">
        <div className="left-controls">
          <ReviewSnailButton />
        </div>
        <div className="center-controls">
          <RewindButton />
          <ReviewPlayPauseButton />
          <ForwardButton />
        </div>
      </div>
    </PlayerControlsWrapper>
  );
});
