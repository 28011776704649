import * as React from 'react';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import { keyframes, styled } from '@naan/stitches.config';
import { PlainMarkdown } from '@naan/primitives/text';
import { ItemActionButton, ItemStatusIcon } from './item-action-button';
import { ItemButtonsContainer } from './item-buttons-container';
import { usePlayerModel } from 'player/views/player-model-context';
import {
  ChapterReviewModel,
  NotationStatus,
  statusIs,
} from 'vocab-review/chapter-review-model';
import classNames from 'classnames';
import { CheckmarkHeavySmallIcon } from '@naan/icons/checkmark-heavy-icon';

import __ from '@core/lib/localization';

const HeadWrapper = styled('div', {
  cursor: 'default',
  '& dt': {
    textStyle: 'serif',
    // color: 'blue',
    marginBottom: -2,
  },
  '& dd': {
    textStyle: 'body',
    color: '$colors$textSecondary',
  },
});

const VocabListItemHead = observer(
  ({ notation }: { notation: ClientNotation }) => {
    // todo: factor with vocab review and notatil detail
    const text = !!notation.usageText
      ? `${notation.usageText} (${notation.headword})`
      : notation.headword;

    return (
      <HeadWrapper>
        <dl>
          <dt>
            <PlainMarkdown source={text} />
          </dt>
          {notation.note ? (
            <dd>
              <PlainMarkdown source={notation.note} />
            </dd>
          ) : null}
        </dl>
      </HeadWrapper>
    );
  }
);

const ItemWrapper = styled('div', {
  padding: '8px 0 12px',
  display: 'flex',
  flexDirection: 'column',
  paddingInline: '$space$4',
  position: 'relative',
  transition: 'box-shadow .3s',

  '&.focus': {
    background: 'white',
    boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
    outline: '1px solid $colors$black-alpha-06',
    borderRadius: 16,
  },

  '& > .inner': {
    display: 'grid',
    gridTemplateColumns: '1fr 36px',
    '& .bookmark-icon': {
      display: 'flex',
      alignItems: 'center',
      '&.blocked': {
        pointerEvents: 'none',
      },
    },
  },
});

const LearnItem = observer(
  ({
    notation,
    status,
  }: {
    notation: ClientNotation;
    status: NotationStatus;
  }) => {
    const model = usePlayerModel() as ChapterReviewModel;
    const focused = model.currentNotationId === notation.id;

    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (model.currentNotationId === notation.id) {
          if (!statusIs(status, 'pending')) {
            model.waitAndMoveToNextReviewItem();
          }
        } else {
          model.setCurrentNotationId(notation.id);
        }
      },
      [model, notation.id, status]
    );

    return (
      <ItemWrapper
        className={classNames({
          focus: focused,
        })}
        onClick={handleClick}
      >
        <div className="inner">
          <VocabListItemHead notation={notation} />
          <div className="bookmark-icon">
            <ItemStatusIcon notation={notation} status={status} />
          </div>
        </div>
        <ItemButtonsContainer
          notation={notation}
          focused={focused}
          status={status}
        />
      </ItemWrapper>
    );
  }
);

const appearFromTheRight = keyframes({
  from: { opacity: 0, transform: 'translateX(10%)' },
  to: { opacity: 1, transform: 'translateX(0%)' },
});

const disappear = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
});

const ToastWrapper = styled('div', {
  textStyle: 'body-bold',
  display: 'flex',
  flexDirection: 'row',
  color: '$colors$purple-800',
  backgroundColor: '$colors$purple-100',
  gap: 4,
  padding: '8px 16px',
  borderRadius: 8,
  position: 'absolute',
  top: 'calc(50% - 20px)',
  right: 48,
  animation: `${appearFromTheRight} 0.3s ease-out`,
});

const InlineToast = ({
  status,
  callback,
}: {
  status: NotationStatus;
  callback: () => void;
}) => {
  const parent = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    function afterAppearingCallback() {
      parent.current.style.animation = '';
      parent.current.style.animation = `${disappear} 0.3s ease 1.5s forwards`;
      parent.current.removeEventListener(
        'animationend',
        afterAppearingCallback
      );

      function afterDisappearingCallback() {
        callback();
        parent.current.removeEventListener(
          'animationend',
          afterDisappearingCallback
        );
      }

      parent.current.addEventListener(
        'animationend',
        afterDisappearingCallback
      );
    }
    parent.current.addEventListener('animationend', afterAppearingCallback);
  }, [callback, status]);

  return (
    <ToastWrapper ref={parent}>
      <CheckmarkHeavySmallIcon />
      {__('Added for next review', 'addedForNextReview')}
    </ToastWrapper>
  );
};

const ManageItem = observer(
  ({
    notation,
    status,
  }: {
    notation: ClientNotation;
    status: NotationStatus;
  }) => {
    const [showToast, setShowToast] = React.useState(false);
    React.useEffect(() => {
      if (statusIs(status, 'just-added')) {
        setShowToast(true);
      }
    }, [status]);

    const toastAnimationCallback = React.useCallback(() => {
      setShowToast(false);
    }, []);

    return (
      <ItemWrapper>
        <div className="inner">
          <VocabListItemHead notation={notation} />
          {showToast ? (
            <InlineToast status={status} callback={toastAnimationCallback} />
          ) : null}
          <div className={classNames('bookmark-icon', { blocked: showToast })}>
            <ItemActionButton notation={notation} status={status} />
          </div>
        </div>
      </ItemWrapper>
    );
  }
);

export const VocabListItem = observer(
  ({ notation }: { notation: ClientNotation }) => {
    const model = usePlayerModel() as ChapterReviewModel;
    const status = model.vocabStatus(notation.id);
    const isManageable = statusIs(
      status,
      'not-added',
      'just-added',
      'previously-learned'
    );

    if (isManageable) {
      return <ManageItem notation={notation} status={status} />;
    } else {
      return <LearnItem notation={notation} status={status} />;
    }
  }
);
