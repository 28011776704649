import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Insets } from 'native-support/insets';

export const ResponsiveParentContainer = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  paddingTop: Insets.top,
  height: '100%',

  '&::before': {
    content: '""',
    display: 'block',
    height: Insets.top,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    // background: 'red',
    zIndex: 100,
    background: 'var(--statusbarBgColor, $colors$gray-800)',
  },

  '.notice-area': {
    gridRow: '1',
  },

  '.main-grid-area': {
    gridRow: '2',
    overflowY: 'auto',
  },
});

const ResponsiveGridLayoutWrapper = styled('div', {
  isolation: 'isolate',

  gridRow: '2',
  overflowY: 'auto',
  // position: 'fixed',
  // top: 0,
  // left: 0,
  // right: 0,
  // bottom: 0,
  height: '100% ',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  animation: 'grid-template-rows 2s ease-in-out',
  '&.hide-tabbar': {
    gridTemplateRows: '1fr 0',
    $$tabbarHeight: '0px',
  },
  '@large': {
    gridTemplateRows: 'none',
    gridTemplateColumns: '$$sidebarWidth auto',
    gridTemplateAreas: '"sidebar main"',
    '&.hide-tabbar': {
      gridTemplateRows: 'none',
      gridTemplateColumns: '1fr',
      gridTemplateAreas: '"main"',
    },
  },
});

export const ResponsiveGridLayout = (
  props: React.ComponentProps<typeof ResponsiveGridLayoutWrapper>
) => {
  return <ResponsiveGridLayoutWrapper id="responsive-grid-layout" {...props} />;
};

export function hideBottomTabBar() {
  const layout = document.getElementById('responsive-grid-layout');
  layout?.classList.add('hide-tabbar');
}

export function showBottomTabBar() {
  const layout = document.getElementById('responsive-grid-layout');
  layout?.classList.remove('hide-tabbar');
}

export const mainScrollingAreaId = 'main-scroll-container';

const ResponsiveMainContainerWrapper = styled('div', {
  // height: 'calc( 100% - $$tabbarHeight + env(safe-area-inset-bottom) )',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
  // padding: '0 $space$4',

  '@large': {
    gridArea: 'main',
  },
});

export const ResponsiveMainContainer = (
  props: React.ComponentProps<typeof ResponsiveMainContainerWrapper>
) => {
  return <ResponsiveMainContainerWrapper id={mainScrollingAreaId} {...props} />;
};

ResponsiveMainContainerWrapper.displayName = 'ResponsiveMainContainer';

export const ResponsiveNavContainer = styled('div', {
  height: 'calc($$tabbarHeight + var(--sab))',

  '@large': {
    gridArea: 'sidebar',
    height: '100%',
  },
});

export const GlobalGrid = styled('div', {
  height: '100%',
  '@large': {
    display: 'grid',
    gridTemplateColumns: '$$sidebarWidth auto',
  },
});

export const SidebarToggleControl = styled('div', {
  display: 'block',
  '@large': {
    display: 'none',
  },
});

export const ContentGridOuterContainer = styled('div', {
  // backgroundColor: '$yellow-100',
  // px: '16px',
  height: '100%',
  overflow: 'auto',
  '@large': {
    // px: '32px',
  },
  '@extraLarge': {
    px: '0',
  },
});

ContentGridOuterContainer.displayName = 'ContentGridOuterContainer';

export const ContentGridInnerContainer = styled('div', {
  // backgroundColor: '$blue-100',
  '@extraLarge': {
    margin: '0 auto',
    width: '960px', //maybe this should be a var somewhere
  },
});

export const ContentGrid = styled('div', {
  // backgroundColor: '$teal-100',
  display: 'grid',
  gridTemplateColumns: 'repeat(6,1fr)',
  columnGap: '24px',
  '@small': {
    gridTemplateColumns: 'repeat(12,1fr)',
  },
  '@large': {
    // background: '$blue-100',
    alignContent: 'baseline',
  },
});
