import { copyToClipboard } from '../../../common/hooks/use-copy-powers';
import { keyboardService } from 'lib/services/keyboard-service';
import {
  isSoundbiteModel,
  isStudyModel,
} from '../../models/player-model-handle';
import { PlayerModel } from 'player/models/player-model';
import React from 'react';
import { AppFactory } from '@app/app-factory';
import { toggleKeyboardOverlay } from 'components/ui/keyboard-help-overlay';
// import { scrollToCurrentLine } from 'study/views/fx/scrolling';
import { elementIds } from 'components/dom-utils/element-ids';
import { NotificationService } from '@app/notification-service';
import { reloadOrNativeReset } from '@core/lib/app-util';

import __ from '@core/lib/localization';

export function definePlayerKeyboardControls(model: PlayerModel) {
  keyboardService.addShortcutSet('player', {
    space: () => model.handleSpaceAction(),
    escape: () => model.cancelPendingPause(),
    left: () => model.rewind(),
    right: () => model.forward(),
    up: () => model.seekPreviousLine(),
    down: () => model.seekNextLine(),
    r: () => model.replayCurrentSentence(),
    s: () => model.snailReplayCurrentSentence(),
    'equal, shift+equal, numpadadd': () => model.increaseSpeedAction(),
    'minus, shift+minus, numpadsubtract': () => model.reduceSpeedAction(),
    'delete, backspace': () => model.sentenceUnredactAction(),
    enter: () => model.replayCurrentSentence(),
    'shift+enter': () => {
      model.snailReplayCurrentSentence();
    },
    // 'shift+tab': () => {
    //   if (isStudyModel(model)) {
    //     model.togglePlayerMode();
    //   }
    // },
    tab: () => {
      if (isStudyModel(model) && model.studyMode) {
        model.toggleCurrentSentenceInlineTranslation();
      } else if (isSoundbiteModel(model)) {
        if (!model.answerRevealed) {
          NotificationService.open({
            type: 'info',
            message: __(
              'Translation will be enabled after answer is revealed',
              'translationWillBeEnabledAfterAnswerIsRevealed'
            ),
          });
          return;
        } else {
          model.toggleCurrentSentenceInlineTranslation();
        }
      }
    },
    'shift+tab': () => {
      if (isSoundbiteModel(model)) {
        if (!model.answerRevealed) {
          NotificationService.open({
            type: 'info',
            message: __(
              'Translation will be enabled after answer is revealed',
              'translationWillBeEnabledAfterAnswerIsRevealed'
            ),
          });
          /// we return early here, but it's `not` really necessary since the model will no-op anyway
          return;
        }
      }
      model.toggleTranslations();
    },
    // slash: () => model.toggleHelpOverlay(),
    'shift+backquote': () => model.toggleDebugMode(),
    o: () => model.toggleDebugOverlay(),
    'shift+1': () => model.debugTogglePiMenu(), // flips both the persisted force enabled and transient force hide
    q: () => model.debugReset(),
    i: () => model.debugToggleOnloadModal(),
    z: () => model.debugSeekToEnd(),
    x: () => model.debugSeekToFinalCard(),
    a: () => model.debugToggleSoundbiteCompletion(),
    h: () => model.debugNavBack1(),
    l: () => model.debugNavForward1(),
    'k, shift+k': () => toggleKeyboardOverlay(),
    // k: () => model.debugNavBack25(),
    j: () => model.debugNavForward25(),
    c: () =>
      copyToClipboard(model.currentSentenceText).then(
        () => {
          NotificationService.open({
            type: 'success',
            message: __('Copied to clipboard', 'copiedToClipboard'),
          });
        },
        () => {
          NotificationService.open({
            type: 'error',
            message: __(
              'Failed to copy to clipboard',
              'failedToCopyToClipboard'
            ),
          });
        }
      ),
    v: () => {
      if (isStudyModel(model) && model.studyMode) {
        model.toggleCurrentSentenceInlineNotations();
        // scrollToCurrentLine();
      } else if (isSoundbiteModel(model)) {
        const element = document.getElementById(
          elementIds.SOUNDBITE_VOCABULARY_BUTTON
        );
        if (element) {
          element.click();
        }
      }
    },
    'shift+v': () => {
      const element = document.getElementById(elementIds.VOCAB_BUTTON);
      if (element) {
        element.click();
      }
    },
    'shift+period': () => AppFactory.root.storyManager.adjustCurrentDate(1),
    'shift+comma': () => AppFactory.root.storyManager.adjustCurrentDate(-1),
    // 'slash, shift+slash': () => openSoundbitesHelpDialog(), // todo: toggle soundbite help modal
    'shift+r': () => reloadOrNativeReset(),
  });
}

export function startPlayerKeyboardControls() {
  keyboardService.setCurrentShortcutSet('player');
}

export function stopPlayerKeyboardControls() {
  keyboardService.removeShortcutSet('player');
}

export function usePlayerKeyboardControls(model: PlayerModel) {
  React.useEffect(() => {
    definePlayerKeyboardControls(model);

    startPlayerKeyboardControls();

    return () => {
      stopPlayerKeyboardControls();
    };
  });
}
