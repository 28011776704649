import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { MarkSimple } from '@naan/primitives/text';
import { Button } from '@naan/primitives/button';
import { observer } from 'mobx-react';
import { useChapterReviewModel } from './review-ui';
import { BookmarkFilledIcon } from '@naan/icons/bookmark-icon';

import __ from '@core/lib/localization';

import bgImage from './assets/bg.svg';

const ReviewOverlayWrapper = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  backgroundColor: '$pink-500',
  backgroundImage: `url(${bgImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'opacity 0.3s ease-in-out',
  opacity: 0,
  display: 'flex',
  pointerEvents: 'none',

  variants: {
    visible: {
      true: {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },
});

ReviewOverlayWrapper.defaultProps = {
  id: 'review-overlay',
};

const ContentWrapper = styled('div', {
  margin: '0 auto',
  width: 'min(472px, 100%)',
  // background: 'yellow',
  // flex: 1,
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  paddingTop: 'var(--sat)',
  paddingBottom: 'var(--sab)',
  color: '$white',
  paddingInline: 16,

  '& .top': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 24,
    gap: 12,
    '@medium': {
      paddingTop: 40,
    },
    '& .heading': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      gap: 4,
    },
    '& .details': {
      display: 'grid',
      gridAutoFlow: 'column',
      alignSelf: 'stretch',
      padding: '12px 24px',
      borderTop: '1px solid $white-alpha-20',
      borderBottom: '1px solid $white-alpha-20',
      gap: 12,
      '& dl': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& dt': {
          textStyle: 'small-heading',
        },
        '& dd': {
          textStyle: 'small-text',
        },
      },
    },
  },

  '& .middle': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .middle-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,

      '& .title': {
        textStyle: 'medium-heading',
        '@medium': {
          textStyle: 'large-heading',
        },
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '$white',
        '& .position': {
          color: '$pink-200',
        },
      },

      '& .stats': {
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'center',
        gap: 8,
        '& dl': {
          width: 112,
          height: 96,
          background: '$colors$black-alpha-10',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 8,
          paddingTop: 14,
          '& dt': {
            textStyle: 'extra-large-heading',
            color: '$colors$white',
          },
          '& dd': {
            color: '$pink-200',
            textStyle: 'small-text',
            marginTop: -4,
          },
        },
      },
    },
  },

  '& .bottom': {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 12,
    paddingBottom: 28,
    '@medium': {
      paddingBottom: 44,
    },
  },
});

// "start of chapter" view
export const ReviewOverlay = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    // const model = AppFactory.chapterReviewModel;
    const model = useChapterReviewModel();
    const { vocabReviewModel } = model;
    const { remainingItemCount, remainingChapterCount } = vocabReviewModel;

    return (
      <ReviewOverlayWrapper visible={model.showingOverlay}>
        <ContentWrapper>
          <div className="top">
            <div className="heading">
              <BookmarkFilledIcon />
              {__(
                '%{items} in %{chapters} to review',
                'nItemsInNChaptersToReview',
                {
                  items: __(
                    {
                      one: '%{count} item',
                      other: '%{count} items',
                    },
                    'itemCount',
                    {
                      count: remainingItemCount,
                    }
                  ),
                  chapters: __(
                    {
                      one: '%{count} chapter',
                      other: '%{count} chapters',
                    },
                    'chapterCount',
                    {
                      count: remainingChapterCount,
                    }
                  ),
                }
              )}
            </div>
          </div>
          <div className="middle">
            <div className="middle-wrapper">
              <div className="title">
                <div className="position">
                  {__('Chapter %{position}', 'chapterPosition', {
                    position: model.chapter.position,
                  })}
                </div>
                <MarkSimple source={model.chapter.title} />
              </div>
              <div className="stats">
                <dl>
                  <dt>{model.pagesCount}</dt>
                  <dd>
                    {model.pagesCount === 1
                      ? __('Sentence', 'sentence')
                      : __('Sentences', 'sentences')}
                  </dd>
                </dl>
                <dl>
                  <dt>{vocabReviewModel.currentItemCount}</dt>
                  <dd>
                    {vocabReviewModel.currentItemCount === 1
                      ? __('Item', 'item')
                      : __('Items', 'items')}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="bottom">
            <Button
              label={__('Begin', 'begin')}
              fullWidth
              presentation={'teal'}
              size={'extraLarge'}
              onClick={() => {
                model.startReviewSession();
              }}
            />
            <Button
              label={__('Close', 'close')}
              fullWidth
              presentation={'whiteDarken'}
              size={'extraLarge'}
              onClick={() => onDismiss()}
            />
          </div>
        </ContentWrapper>
      </ReviewOverlayWrapper>
    );
  }
);
