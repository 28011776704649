import * as React from 'react';

export const BlockingInterstitial: React.FC<{
  passCondition: boolean;
  renderInterstitial: () => React.ReactNode;
  children: React.ReactNode;
}> = ({ passCondition: condition, renderInterstitial, children }) => {
  /// this weird <> </> syntax is to avoid a typescript error
  if (condition) {
    return <>{children}</>;
  }
  return <>{renderInterstitial()}</>;
};
