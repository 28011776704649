import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { Story } from '@core/models/story-manager';

export class ChapterPositionUiModel {
  public currentChapterPosition: number = 0;

  constructor(story: Story) {
    makeObservable(this, {
      currentChapterPosition: observable,
    });

    this.initWithStory(story);
  }

  public initWithStory(story: Story) {
    const { progress } = story;
    this.setChapterPosition(progress.currentChapterPosition);
  }

  public setChapterPosition(position: number) {
    // if the current chapter is clicked, close it
    if (this.currentChapterPosition === position) {
      this.currentChapterPosition = -1;
      return;
    }
    this.currentChapterPosition = position;
  }
}

const ChapterPositionUiContext =
  React.createContext<ChapterPositionUiModel | null>(null);

export const useChapterPositionUiContext = () => {
  const context = React.useContext(ChapterPositionUiContext);
  if (!context) {
    throw new Error(
      'useChapterPositionUiContext must be used within a ChapterPositionUiProvider'
    );
  }
  return context;
};

export const ChapterPositionUiProvider: React.FC<
  React.PropsWithChildren<{ story: Story }>
> = ({ children, story }) => {
  const model = new ChapterPositionUiModel(story);
  return (
    <ChapterPositionUiContext.Provider value={model}>
      {children}
    </ChapterPositionUiContext.Provider>
  );
};
