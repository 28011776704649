import * as React from 'react';
import { CIRCLE_RADIUS } from './circle-icon';

const SIZE = CIRCLE_RADIUS * 2;
export const ReviewChapterIcon = () => (
  <svg
    width={SIZE}
    height={SIZE}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#4D4D4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4366 5.90415C10.1047 6.23609 10.1047 6.77428 10.4366 7.10623L12.2751 8.94471C12.607 9.27665 13.1452 9.27665 13.4772 8.94471C13.8091 8.61276 13.8091 8.07457 13.4772 7.74263L13.1151 7.38058C15.2193 7.88283 16.7838 9.77538 16.7838 12.0333C16.7838 14.6751 14.6422 16.8167 12.0004 16.8167C9.35866 16.8167 7.21709 14.6751 7.21709 12.0333C7.21709 11.5639 6.83653 11.1833 6.36709 11.1833C5.89765 11.1833 5.51709 11.5639 5.51709 12.0333C5.51709 15.614 8.41978 18.5167 12.0004 18.5167C15.5811 18.5167 18.4838 15.614 18.4838 12.0333C18.4838 8.82811 16.1578 6.16613 13.1018 5.64317L13.4772 5.26775C13.8091 4.93581 13.8091 4.39762 13.4772 4.06567C13.1452 3.73373 12.607 3.73372 12.2751 4.06567L10.4366 5.90415Z"
      fill="white"
    />
  </svg>
);
