import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
  createPath,
} from 'react-router-dom';
import {
  version,
  curEnv,
  commit,
  buildTimestampIso,
  // @ts-expect-error
} from '@jw-spa-version';
import { createLogger } from '@common/log';
import App from './app';
// import { WelcomeScreen } from 'routes/auth/welcome-screen';
import { L2Choose } from 'routes/auth/welcome-screen/l2-choose';
import { L2Default } from 'routes/auth/welcome-screen/l2-default';
import { ReturnNavState } from 'components/nav/return-nav-state';

const log = createLogger('l2-router');

const buildTimestampLocal = new Date(buildTimestampIso).toString();

log.info(`${curEnv}; ${version}; ${commit}; ${buildTimestampLocal}`);

// ancient legacy paths. these can probably be retired now
// const redirects: Record<string, string> = {
//   // auth redirects
//   '/sign-in': '/auth/sign-in',
//   '/create-account': '/auth/create-account',
//   '/reset-password': '/auth/reset-password',
//   '/create-password': '/auth/create-password',

//   // profile redirects
//   '/account': '/app/profile/account', // should only be used by campaign monitor manage-account links now
//   // '/account': '/app/learn/es/home', // tentatively redirect our deep link accounts urls to the new dashboard
//   '/gift-coupons': '/app/profile/gift-coupons',
//   '/schools': '/app/profile/schools',
//   '/close-account': '/app/profile/close-account',

//   // classroom redirects
//   '/discover': '/app/teach/es/discover',
//   '/stories': '/app/learn/es/stories',
// };

const findRedirectMatch = (pathname: string) => {
  // let redirectPath = redirects[pathname];
  // if (redirectPath) {
  //   return redirectPath;
  // }

  // these redirectly are primarly needed to smoothly handle dangling 9.3 release links once 10.0 is released.
  // but /app/profile will continue to be utilized in transactional emails.
  // TODO: rethink the implications of this for users who follow email links on a new device and what our options are
  // we'll probably want to address this with the 10.1 release

  if (pathname.startsWith('/app/learn/es')) {
    return pathname.replace('/app/learn/es', '/es/app/learn');
  }
  if (pathname.startsWith('/app/teach/es')) {
    return pathname.replace('/app/teach/es', '/es/app/teach');
  }

  // @armando, perhaps just use startWith() tests for these also?
  const matchers = [
    /^\/app/,
    /^\/auth/,
    /^\/scripts/,
    /^\/students\/join/,
    /^\/redeem/,
    /^\/redeem-nav-gift/,
  ];
  if (matchers.some(matcher => matcher.test(pathname))) {
    return ReturnNavState.deepLinkBaseL2Path() + pathname;
    // return '/es' + pathname;
  }

  return null;
};

const FallbackLegacyRoute = () => {
  const location = useLocation();

  const redirectPath = findRedirectMatch(location.pathname);

  if (redirectPath) {
    return (
      <Navigate
        to={createPath({ ...location, pathname: redirectPath })}
        replace={true}
      />
    );
  }

  // return <NotFoundScreen />; // this page blows up w/o an app root and locale
  return <Navigate to="/" replace />;
};

export const L2Router = () => {
  const baseL2Path = ReturnNavState.deepLinkBaseL2Path();

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<L2Default />} />
        {/* <Route path="/welcome" element={<WelcomeScreen />} */}
        <Route path="/welcome" element={<L2Choose />} />
        {/* <Route path="/choose" element={<Navigate to="/welcome" replace />} /> */}
        <Route path="/es/*" element={<App l2="es" />} />
        <Route path="/en/*" element={<App l2="en" />} />

        {/* <Route path="/p/:localePair" element={<SelectLocalePair />} /> */}

        {/* redirect legacy paths */}
        {/* <Route
          path="/app/profile/*"
          element={<Navigate to={`${baseL2Path}/app/profile`} replace />}
        /> */}
        {/* @armando, what would be the best way if we wanted to preserve the full deep path in the redirect? */}
        {/* <Route path="/app/*" element={<Navigate to={baseL2Path} replace />} />
        <Route path="/auth/*" element={<Navigate to={baseL2Path} replace />} /> */}
        <Route
          path="/daily-soundbite"
          element={<Navigate to={baseL2Path} replace />}
        />
        <Route path="*" element={<FallbackLegacyRoute />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
