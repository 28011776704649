import React from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  children: React.ReactNode;
};

export class Portal extends React.Component<PortalProps> {
  element: HTMLElement | null = null;

  constructor(props: PortalProps) {
    super(props);
    this.element = document.createElement('DIV');
    document.body.appendChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}
