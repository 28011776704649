import * as React from 'react';

const { useCallback, useState } = React;

export type useRadioReturnType<T> = {
  setter: (value: T) => () => void;
  toggler: (value: any) => () => void;
  setCurrentTab: React.Dispatch<React.SetStateAction<T | null>>;
  isNotCurrent: (value: any) => boolean;
  isCurrent: (value: any) => boolean;
  resetCurrent: () => void;
  currentTab: T | null;
};

export function useRadio<T>(
  initialState: T | (() => T | null) | null
): useRadioReturnType<T> {
  const [currentValue, setCurrentValue] = useState<T | null>(initialState);

  const setter = useCallback((value: T) => () => setCurrentValue(value), []);

  const isCurrent = useCallback(
    (value: T) => currentValue === value,
    [currentValue]
  );

  const isNotCurrent = useCallback(
    (value: T) => currentValue !== value,
    [currentValue]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetCurrent = useCallback(() => setCurrentValue(initialState), []);

  const toggler = useCallback(
    (value: T) => () => {
      isCurrent(value) ? resetCurrent() : setCurrentValue(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentValue]
  );

  return {
    setter,
    toggler,
    setCurrentTab: setCurrentValue,
    isNotCurrent,
    isCurrent,
    resetCurrent,
    currentTab: currentValue,
  };
}
