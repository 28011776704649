import * as React from 'react';
import { TFilterQueryParameters } from '../filtering-core';
import { useLocation, useNavigate } from 'react-router';
import { TSortingKey } from './sorting-keys';
import { TFilterKey } from './filter-keys';
import { FilteringContext, TFilteringContext } from './filtering-context';
import { parseQueryString } from './parse-query-string';
import { stringifyQuery } from './stringify-query';
import { locationStringToFilteringState } from './location-string-to-filtering-state';
import { AppFactory } from 'app/app-factory';
import { createLogger } from 'app/logger';
import { computed } from 'mobx';

const log = createLogger('filtering-provider');

// const useHistory = () => {
//   const navigate = useNavigate();
//   return {
//     push: navigate,
//     replace: (arg: any) => navigate(arg, { replace: true }),
//   };
// };

export const FilteringProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { storyManager } = AppFactory.root;
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = React.useState(() =>
    locationStringToFilteringState(location.search)
  );

  const { filter, sorting } = state;

  React.useEffect(() => {
    setState(locationStringToFilteringState(location.search));
  }, [location]);

  log.debug(
    `before assign filteredUnits - state: ${JSON.stringify(state)}, sm.len: ${
      storyManager.stories?.length
    }`
  );
  /** memoized version of the story units, once all the filters got applied */
  // there still seems to be a redundant invoke here on first page load, the memoizer
  // does seem to avoid an unnecessary computation when navigating and changing filters
  const filteredUnits = React.useMemo(
    () =>
      computed(() => {
        const result = storyManager.filteredList(state.filter, state.sorting);
        log.info(
          `filteredUnits - inside useMemo - result count: ${
            result?.length
          }, state: ${JSON.stringify(state)}`
        );
        return result;
      }),
    [state, storyManager] // length dep seems to be needed (but only on netlify deployments)
  );

  /**
   * takes a query object and uses it to rewrite the locaion in the query string
   *
   * @param query - the new Query object
   * @param replaceHistory - if true, the history will be replaced, otherwise a new entry will be created
   * */
  const applyQuery = React.useCallback(
    (query: TFilterQueryParameters, replaceHistory = false) => {
      const newSearch = stringifyQuery(query);
      // let navigationMethod = replaceHistory ? history.replace : history.push;
      navigate({ ...location, search: newSearch }, { replace: replaceHistory });
    },
    [navigate, location]
  );

  /**
   * Changes the sorting key in the query string and gets the filter state reevaluated
   * @param key - the new sorting key
   */
  const setSortingKey = React.useCallback(
    (sortingKey: TSortingKey) => {
      const query = parseQueryString(location.search);
      const newSearch = stringifyQuery({ ...query, sorting: sortingKey });

      navigate({ ...location, search: newSearch }, { replace: true });
    },
    [location, navigate]
  );

  /** Clears all the filters and cleans the query line*/
  const resetAllFilters = React.useCallback(() => {
    // maybe we should have an alternative to `replace` here?
    navigate({ ...location, search: '' }, { replace: true });
  }, [navigate, location]);

  // /**
  //  * Special function that sets the filter state directly
  //  * instead of using the query string.
  //  * This is for the live search functionality.
  //  */
  // const setSearch = React.useCallback(
  //   (search?: string) => {
  //     if (typeof search !== 'string') {
  //       setState({ filter: null, sorting: state.sorting });
  //       return;
  //     }

  //     const filter = convertQueryObjectToFilter({
  //       filterType: 'search',
  //       filterValue: [search],
  //       sorting: null,
  //     });
  //     setState({ filter, sorting: state.sorting });
  //   },
  //   [state.sorting]
  // );

  /**
   * This will either add a new value to the current filter
   * or set a new filter state. It will also update the query string.
   *
   * @param filterType - If it's the same as the current filter type, the value will be added to the current filter.
   * @param filterValue - the value to add to the filter
   *
   */
  const addFilter = React.useCallback(
    (key: TFilterKey, value: string, replaceHistory = false) => {
      const query = parseQueryString(location.search);
      let { filterType, filterValue } = query;
      if (filterType === key) {
        filterValue?.push(value);
      } else {
        filterValue = [value];
      }
      applyQuery(
        {
          ...query,
          filterType: key,
          filterValue,
        },
        replaceHistory
      );
    },
    [location, applyQuery]
  );

  const replaceFilter = React.useCallback(
    (key: TFilterKey, value: string, replaceHistory = false) => {
      const query = parseQueryString(location.search);

      applyQuery(
        {
          ...query,
          filterType: key,
          filterValue: [value],
        },
        replaceHistory
      );
    },
    [location, applyQuery]
  );

  /**
   * This will remove a value from the comma separated list of values in the query string.
   * if the value is the last one in the list, it will also remove the filter from the query string.
   *
   * @param filterValue - the value to remove
   * @param replace - if true, the history will be replaced, otherwise a new entry will be created
   */
  const removeValueFromFilter = React.useCallback(
    (value: string, replaceHistory = false) => {
      const query = parseQueryString(location.search);
      let { filterValue } = query;

      if (!filterValue) {
        return;
      }
      const newValues = filterValue.filter(v => v !== value);

      if (newValues.length === 0) {
        resetAllFilters();
        return;
      }

      applyQuery(
        {
          ...query,
          filterValue: newValues,
        },
        replaceHistory
      );
    },
    [location, applyQuery, resetAllFilters]
  );

  /**
   * @deprecated
   */
  const getFilter = React.useCallback(
    (key: TFilterKey) => {
      const query = parseQueryString(location.search);
      if (query.filterType === key) {
        return query.filterValue;
      }

      return null;
    },
    [location]
  );

  // function getQuery() {
  //   return parseQueryString(location.search);
  // }

  const context: TFilteringContext = React.useMemo(() => {
    return {
      setSortingKey,
      addFilter,
      replaceFilter,
      removeValueFromFilter,
      getFilter,
      resetAllFilters,
      // setSearch,
      // getQuery,
      filter,
      sorting,
      get filteredUnits() {
        return filteredUnits.get();
      },
    };
  }, [
    addFilter,
    filter,
    filteredUnits,
    getFilter,
    removeValueFromFilter,
    replaceFilter,
    resetAllFilters,
    setSortingKey,
    sorting,
  ]);

  (window as any).filters = context;

  return (
    <FilteringContext.Provider value={context}>
      {children}
    </FilteringContext.Provider>
  );
};
