import { styled } from '@naan/stitches.config';

export const SectionHeaderButton = styled('button', {
  $$_color: '$colors$primaryColor',
  $$_hoverColor: '$colors$primaryColor',
  appearance: 'none',
  border: 'none',
  textStyle: 'small-caps',
  background: 'none',
  color: '$$_color',
  cursor: 'pointer',
  '&:hover': {
    color: '$$_hoverColor',
  },
  variants: {
    presentation: {
      purple: {
        $$_color: '$colors$purple-500',
        $$_hoverColor: '$colors$purple-600',
      },
      teal: {
        $$_color: '$colors$teal-500',
        $$_hoverColor: '$colors$teal-600',
      },
    },
  },
});
