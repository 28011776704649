import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { CloseControl } from './controls/close-control';
import { HeaderContainer } from './study-layout';
import { lineScrollObserver } from './fx/scrolling';
import { VocabListStoryButton } from 'components/story-status/vocab-list-button';
import { CurrentStoryProvider } from 'components/story-detail/current-story-context';
import { AppFactory } from '@app/app-factory';
import { styled } from '@naan/stitches.config';
import { useReaction } from '@common/hooks/use-reaction';
import { toggleClassname } from '@utils/toggle-class';
import {
  ListenFromHereButton,
  StudyFromHereButton,
} from 'player/views/player-controls/study-from-here-button';
import { IconButton } from '@naan/primitives/button';
import { SettingsIcon } from '@naan/icons/settings-icon';
import { presentSettingsModal } from 'components/settings';
import { SmartPauseOnboardingTip } from 'components/ui/onboarding/smart-pause-tip';

const RightWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 8,
});

const TitleContainer = styled('div', {
  width: '100%',
  textWrap: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: '$white',
  textStyle: 'body-bold',
  transform: 'translateY(16px)',
  opacity: 0,
  transition: 'opacity 0.1s ease-out, transform 0.1s ease-out',
  '&.show': {
    opacity: 1,
    transform: 'translateY(0)',
  },
});

const ListenButtonWrapper = styled('div', {
  position: 'absolute',
  top: 64,
  right: 8,
  zIndex: 100,
  transition: 'transform 0.3s ease-out',
  '@playerMedium': {
    right: 'calc(50% - 300px)',
    '&.translations-shown': {
      transform: 'translateX(196px)',
    },
  },
  '@playerLarge': {
    right: 'calc(50% - 300px)',
  },
});

// const ModeTogglerWrapper = styled('div', {
//   position: 'absolute',
//   top: 8,
//   left: '50%',
//   transform: 'translateX(-50%)',
//   '&.highlight > *': {
//     outline: '6px solid $teal-500',
//     borderRadius: 12,
//   },
// });

/// this was separated so it can be an observable
export const VocabButtonContainer = observer(() => {
  const model = AppFactory.studyModel;
  const story = model?.chapter?.story;
  const fluentListenMode = model?.fluentListenMode;

  if (fluentListenMode === true) {
    return null;
  }

  return (
    <CurrentStoryProvider story={story}>
      <VocabListStoryButton story={story} textColor={'$blue-500'} />
    </CurrentStoryProvider>
  );
});

const ID = 'STUDY_HEADER_CONTAINER';

const headerElement = () => document.getElementById(ID);

// @armando, what the React.memo important here? it's not clear how that
// would interplay with the mobx oberserver which is needed to dismiss the popover
// export const StudyHeader = React.memo(() => {
export const StudyHeader = observer(() => {
  // const popoverDismissed =
  //   OnboardingService.instance.isDismissed('naturalListen');
  // const showPopover = OnboardingService.instance.showNaturalListenTip;

  const model = AppFactory.studyModel;

  useReaction(
    () => model.isPlaying,
    () => {
      toggleClassname(headerElement(), 'playing', model.isPlaying);
    }
  );

  return (
    <>
      <HeaderContainer id={ID}>
        <div className="left">
          <CloseControl />
        </div>
        <div className="center">
          <TitleContainer
            className={lineScrollObserver.isScriptTitleVisible ? '' : 'show'}
          >
            {`${model.chapter?.position}. ${model.chapter?.title}`}
          </TitleContainer>
        </div>
        {/* {model.enableModeToggle ? (
        <OnboardingPopover
          showPopover={showPopover}
          dismissed={popoverDismissed}
          onResolvePopover={
            OnboardingService.instance.naturalListenTipDismissed
          }
          renderAnchor={({ ref, getProps }) => (
            <ModeTogglerWrapper
              ref={ref}
              {...getProps()}
              data-id="popover-wrapper"
              className={showPopover ? 'highlight' : null}
            >
              <ModeToggler />
            </ModeTogglerWrapper>
          )}
          renderBody={() => {
            return <NaturalListenTip />;
          }}
        />
      ) : null} */}

        <div className="right">
          <RightWrapper>
            <SmartPauseOnboardingTip>
              <IconButton
                testId="player-settings-button"
                icon={<SettingsIcon />}
                presentation="whiteTransparent"
                onClick={() => presentSettingsModal('Player')}
              />
            </SmartPauseOnboardingTip>
            <VocabButtonContainer />
          </RightWrapper>
        </div>
        <ListenButtonWrapper
          className={classNames({
            'translations-shown': model.translationsShown,
          })}
        >
          <StudyFromHereButton />
          <ListenFromHereButton />
        </ListenButtonWrapper>
      </HeaderContainer>
    </>
  );
});
