import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { ChevronRightExtraSmallIcon } from '@naan/icons/chevron-right-icon';

const Wrapper = styled('div', {
  $$textColor: '$colors$textPrimary',
  $$highlightColor: '$colors$green-500',
  $$iconColor: '$$highlightColor',

  $$backgroundColor: '$colors$white',
  $$subtitleColor: '$colors$textSecondary',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$$backgroundColor',
  padding: '18px 16px 20px',
  borderRadius: 16,
  position: 'relative',
  color: '$$textColor',

  '& .title': {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    '& .icon': {
      color: '$$iconColor',
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .label': {
      textStyle: 'medium-heading',
    },
  },

  '& .subtitle': {
    color: '$$subtitleColor',
    textStyle: 'small-caps',
    marginTop: -2,
  },

  '& .action': {
    all: 'unset',
    cursor: 'pointer',
    color: '$$textColor',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    padding: '0 16px',
    borderRadius: 16,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
    '& svg path': {
      fill: '$$subtitleColor',
    },
  },

  variants: {
    presentation: {
      teal: {
        $$highlightColor: '$colors$teal-500',
      },
      green: {
        $$highlightColor: '$colors$green-500',
      },
      purple: {
        $$highlightColor: '$colors$purple-500',
      },
      orange: {
        $$highlightColor: '$colors$orange-500',
      },
    },

    disabled: {
      true: {
        $$iconColor: '$colors$gray-300',
        $$subtitleColor: '$colors$gray-300',
        $$textColor: '$colors$gray-300',
      },
    },

    promoted: {
      true: {
        $$backgroundColor: '$$highlightColor',
        $$iconColor: '$colors$white',
        $$subtitleColor: '$colors$white',
        $$textColor: '$colors$white',
      },
    },
  },
});

type StyleProps = React.ComponentProps<typeof Wrapper>;

export const SectionItem = ({
  icon,
  title,
  subtitle,
  action,
  ...props
}: {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  action?: () => void;
} & Pick<StyleProps, 'presentation' | 'disabled' | 'promoted'>) => {
  return (
    <Wrapper {...props}>
      <div className="title">
        <div className="icon">{icon}</div>
        <div className="label">{title}</div>
      </div>
      <div className="subtitle">{subtitle}</div>
      {action ? (
        <button className="action" onClick={action}>
          <ChevronRightExtraSmallIcon />
        </button>
      ) : null}
    </Wrapper>
  );
};
