import React from 'react';
import { observer } from 'mobx-react';
import { VocabMiniStoryCard } from 'components/stories/vocab-mini-story-card';
import {
  Story,
  vocabToReviewFilterLabelFn,
} from '@core/models/story-manager/story';
import { storiesTagFiltered } from 'components/nav/path-helpers';
import {
  SectionHeading,
  SectionHeadingLink,
} from './dashboard-section-heading';
import { isEmpty } from 'lodash';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';
import { Responsive } from '@naan/primitives/responsive';

const Wrapper = styled('div', {
  '--columns': 1,

  '& > .cards': {
    // marginTop: -16,
    display: 'grid',
    gridTemplateColumns: 'repeat(var(--columns), 1fr)',
    gap: 16,
  },

  '@small': {
    '--columns': 2,

    '& > .cards': {
      borderTop: '1px solid $colors$gray-100',
      paddingTop: 16,
    },
  },

  '@medium': {
    '--columns': 3,
  },
});

const VocabToReviewCards = observer(
  ({ stories, limit }: { stories: Story[]; limit: number }) => {
    return (
      <>
        {stories.slice(0, limit).map(story => (
          <VocabMiniStoryCard story={story} key={story.slug} />
        ))}
      </>
    );
  }
);

export const VocabToReviewWidget = observer(
  ({ stories, excluded }: { stories: Story[]; excluded: Story[] }) => {
    const filtered = stories.filter(story => !excluded.includes(story));
    if (isEmpty(filtered)) return null;

    const storiesPath =
      // todo: figure out what are options are for a special sorting
      storiesTagFiltered('vocab', vocabToReviewFilterLabelFn()); // + '&sort=vocab';

    return (
      <Wrapper>
        <SectionHeading
          title={__('Vocabulary to review', 'vocabularyToReview')}
          renderLinks={() => (
            <>
              <SectionHeadingLink to={storiesPath}>
                {__('Show all', 'showAll')}
              </SectionHeadingLink>
            </>
          )}
        />
        <div className="cards">
          <Responsive
            renderDefault={() => (
              <VocabToReviewCards stories={filtered} limit={1} />
            )}
            renderSmallAndUp={() => (
              <VocabToReviewCards stories={filtered} limit={2} />
            )}
            renderMediumAndUp={() => (
              <VocabToReviewCards stories={filtered} limit={3} />
            )}
          />
        </div>
      </Wrapper>
    );
  }
);
