import React from 'react';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import {
  ChapterReviewModel,
  NotationStatus,
} from 'vocab-review/chapter-review-model';
import { Button } from '@naan/primitives/button';
import { usePlayerModel } from 'player/views/player-model-context';
import { useAutoanimate } from '@common/hooks/use-autoanimate';
import __ from '@core/lib/localization';
import { achieve } from '@app/onboarding/achievements';

const ItemWrapper = styled('div', {});
const InnerWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 12,
  paddingTop: 12,
  paddingBottom: 4,
});

export const ItemButtonsContainer = observer(
  ({
    notation,
    focused,
    status,
  }: {
    notation: ClientNotation;
    focused: boolean;
    status: NotationStatus;
  }) => {
    const model = usePlayerModel() as ChapterReviewModel;
    const parent = useAutoanimate();

    const pending = status === 'pending';
    const gotIt = status === 'got-it';
    const notGotIt = status === 'not-got-it';

    const handleGotItClick = React.useCallback(() => {
      model.markLearned(notation.id);
      model.waitAndMoveToNextReviewItem();
      achieve('action:reviewed-an-item');
    }, [model, notation.id]);

    const handleNotGotItClick = React.useCallback(() => {
      model.markReviewAgain(notation.id);
      model.waitAndMoveToNextReviewItem();
      achieve('action:reviewed-an-item');
    }, [model, notation.id]);

    return (
      <ItemWrapper ref={parent}>
        {focused ? (
          <>
            <InnerWrapper>
              <Button
                size="extraLarge"
                presentation={pending || gotIt ? 'green' : 'grayLight'}
                label={__('Got it', 'gotIt')}
                // @armando, this seems to trigger a rerender of the entire review modal, please give some thought as to how
                // we should approach avoiding this and let's sync tomorrow
                onClick={handleGotItClick}
              />
              <Button
                size="extraLarge"
                presentation={pending || notGotIt ? 'red' : 'grayLight'}
                label={__("Didn't get it", 'didntGetIt')}
                onClick={handleNotGotItClick}
              />
            </InnerWrapper>
          </>
        ) : null}
      </ItemWrapper>
    );
  }
);
