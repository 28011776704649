import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { TranslationControlIcon } from '../../../study/views/player-icons';
import { paletteKey } from '../../../study/views/study-palette';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';

const TogglerWrapper = styled('span', {
  color: 'inherit',
  variants: {
    active: {
      true: {
        color: paletteKey('$$transport__buttonColor_active'),
      },
    },
  },
});

const TranslationToggler: React.FC<React.PropsWithChildren<unknown>> = observer(
  ({ children }) => {
    const model = usePlayerModel();
    const active = model.translationsShown;
    return <TogglerWrapper active={active}>{children}</TogglerWrapper>;
  }
);

export const TranslationButton = observer(
  ({ disabled }: { disabled?: boolean }) => {
    const model = usePlayerModel();
    return (
      <ControlButton
        onClick={() => model.toggleTranslations()}
        disabled={disabled}
      >
        <TranslationToggler>
          <TranslationControlIcon />
        </TranslationToggler>
      </ControlButton>
    );
  }
);
