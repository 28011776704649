import * as React from 'react';
import { ArrowLeftExtraSmallIcon } from '@naan/icons/arrow-left-icon';
import { ArrowRightExtraSmallIcon } from '@naan/icons/arrow-right-icon';
import { MinusExtraSmallIcon } from '@naan/icons/minus-icon';
import { PlusExtraSmallIcon } from '@naan/icons/plus-icon';
import { ShortcutSection } from './ui/shortcut-section';
import { ShortcutItem } from './ui/shortcut-item';

import __ from '@core/lib/localization';

export const ReviewShortcuts = () => {
  return (
    <>
      <ShortcutSection title={__('Playback', 'playback')}>
        <ShortcutItem
          title={__('Play/pause', 'playSlashPause')}
          shortcut={__('Space', 'keyboard.space')}
        />
        <ShortcutItem
          title={__('Slow speed/full speed', 'slowSpeedSlashFullSpeed')}
          shortcut={[<MinusExtraSmallIcon />, <PlusExtraSmallIcon />]}
        />
        <ShortcutItem
          title={__('Rewind/fast-forward', 'rewindSlashFastForward')}
          shortcut={[<ArrowLeftExtraSmallIcon />, <ArrowRightExtraSmallIcon />]}
        />
      </ShortcutSection>

      <ShortcutSection title={__('View', 'view')}>
        <ShortcutItem
          title={__('Show other words', 'showOtherWords')}
          shortcut={__('Delete', 'keyboard.delete')}
        />
      </ShortcutSection>

      <ShortcutSection title={__('Help', 'help')}>
        <ShortcutItem
          title={__('Show/hide this panel', 'showSlashHideThisPanel')}
          shortcut={__('K', 'keyboard.k')}
        />
      </ShortcutSection>
    </>
  );
};
