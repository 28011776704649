import React from 'react';
import { observer } from 'mobx-react';
import { NotationsListItem } from './notations-list-item';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import { usePlayerModel } from 'player/views/player-model-context';
import { StudyModel } from 'study/models/study-model';

import __ from '@core/lib/localization';
import { SentenceId } from '@tikka/basic-types';

const ListWrapper = styled('div', {
  paddingLeft: '$space$4',
  paddingRight: '$space$4',
  paddingBottom: '$space$1',

  // '& > * + *': {
  //   borderTop: '1px solid $colors$gray-100',
  // },
  '& > * ': {
    borderTop: '1px solid $colors$gray-100',
  },
});

const ZeroStateWrapper = styled('div', {
  height: '100%',
  display: 'grid',
  placeItems: 'center',
  textAlign: 'center',
  textStyle: 'body-bold',
  color: '$colors$gray-300',
  flex: 1,
});

const ZeroState = observer(() => {
  return (
    <ZeroStateWrapper>
      {__('No vocabulary for this sentence', 'noVocabularyForThisSentence')}
    </ZeroStateWrapper>
  );
});

export const NotationsList = observer(
  ({ sentenceId }: { sentenceId: SentenceId }) => {
    const model = usePlayerModel() as StudyModel;
    const notations = model.getNotationsForSentence(sentenceId);

    if (notations.length === 0) {
      return <ZeroState />;
    }

    return (
      <ListWrapper>
        {notations.map((notation: ClientNotation, index: number) => {
          const showOnboardingTip =
            index === 0 && model.currentSentenceId === sentenceId;
          return (
            <NotationsListItem
              key={notation.id}
              notation={notation}
              withOnboardingTip={showOnboardingTip}
            />
          );
        })}
      </ListWrapper>
    );
  }
);
