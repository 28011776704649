import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { Button as NaanButton } from '@naan/primitives/button';
import { observer } from 'mobx-react';
// import { useReviewModel } from 'vocab-review/review-placeholder-model';
import { ArrowRightSmallIcon } from '@naan/icons/arrow-right-icon';
import { usePlayerModel } from '../../../../player/views/player-model-context';
import { ChapterReviewModel } from 'vocab-review/chapter-review-model';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
  bottom: 8,
  margin: '0 auto',
  width: 200,
  height: 0,
  overflow: 'visible',
  transition: 'height .4s, margin .4s',
  '& button': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility .4s, opacity .4s',
  },
  '&.active': {
    height: 56,
    marginTop: 24,
    '& button': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  '&.active.next, &.active.next button': {
    transitionDelay: '.8s',
  },
});

type NaanButtonProps = React.ComponentProps<typeof NaanButton>;
const Button = (props: Omit<NaanButtonProps, 'size'>) => (
  <NaanButton
    {...props}
    size="extraLarge"
    css={{
      borderRadius: 28,
    }}
  />
);

interface buttonProps {
  model: ChapterReviewModel;
  active: boolean;
}

const NextButton = observer(({ model, active }: buttonProps) => {
  const handleClick = React.useCallback(() => {
    model.nextPage();
  }, [model]);

  return (
    <Wrapper className={active ? 'next active' : 'next'}>
      <Button
        presentation="teal"
        label={__('Next', 'next')}
        rightIcon={<ArrowRightSmallIcon />}
        onClick={handleClick}
      />
    </Wrapper>
  );
});

const RevealButton = observer(({ model, active }: buttonProps) => {
  const handleClick = React.useCallback(() => {
    model.setCurrentSentenceStatus('working');
    model.waitAndMoveToNextReviewItem();
  }, [model]);

  return (
    <Wrapper className={active ? 'active' : null}>
      <Button
        presentation="purple"
        label={__('Reveal', 'reveal')}
        onClick={handleClick}
      />
    </Wrapper>
  );
});

export const ReviewSentenceButtons = observer(
  ({ finishedPlaying }: { finishedPlaying: boolean }) => {
    const reviewModel = usePlayerModel() as ChapterReviewModel;
    const revealed = reviewModel.isCurrentSentenceRevealed;
    const anyPendingNotations =
      reviewModel.anyPendingNotationsInCurrentSentence;
    return (
      <>
        <RevealButton
          model={reviewModel}
          active={finishedPlaying && !revealed}
        />
        <NextButton
          model={reviewModel}
          active={finishedPlaying && revealed && !anyPendingNotations}
        />
      </>
    );
  }
);
