import * as React from 'react';

export const BookmarkFilledIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 2C5.94772 2 5.5 2.42045 5.5 2.93909V21.0596C5.5 21.7766 6.32119 22.2291 6.98776 21.8794L12.0122 19.243C12.3154 19.084 12.6846 19.084 12.9878 19.243L18.0122 21.8794C18.6788 22.2291 19.5 21.7766 19.5 21.0595V2.93909C19.5 2.42044 19.0523 2 18.5 2H6.5Z"
      fill={color}
    />
  </svg>
);

export const BookmarkFilledSmallIcon = ({
  width = 16,
  height = 16,
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.0002 1.6001C3.55837 1.6001 3.2002 1.95827 3.2002 2.4001L3.2002 13.7007C3.2002 14.3406 3.91415 14.7215 4.44566 14.3652L7.55473 12.2809C7.82423 12.1003 8.17616 12.1003 8.44566 12.2809L11.5547 14.3652C12.0862 14.7215 12.8002 14.3406 12.8002 13.7007L12.8002 2.4001C12.8002 1.95827 12.442 1.6001 12.0002 1.6001L4.0002 1.6001Z"
      fill={color}
    />
  </svg>
);

export const BookmarkIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4V19.5915L10.5367 17.6155C11.4461 17.1074 12.5539 17.1074 13.4633 17.6155L17 19.5915V4H7ZM6 2C5.44772 2 5 2.44772 5 3V21.2958C5 22.0593 5.82119 22.5412 6.48776 22.1687L11.5122 19.3614C11.8154 19.1921 12.1846 19.1921 12.4878 19.3614L17.5122 22.1687C18.1788 22.5412 19 22.0593 19 21.2958V3C19 2.44772 18.5523 2 18 2H6Z"
      fill={color}
    />
  </svg>
);

export const BookmarkLargeIcon = ({
  width = 28,
  height = 28,
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5C5 3.34315 6.34314 2 8 2H20C21.6569 2 23 3.34315 23 5V23.999C23 25.6831 21.0458 26.6132 19.7388 25.5513L14 20.8885L8.26118 25.5513C6.95415 26.6132 5 25.6831 5 23.999V5ZM8 4C7.44772 4 7 4.44772 7 5V23.999L12.7388 19.3362C13.4736 18.7392 14.5264 18.7392 15.2612 19.3362L21 23.999V5C21 4.44772 20.5523 4 20 4H8Z"
      fill={color}
    />
  </svg>
);

// export const BookmarkFilledLargeIcon = ({
//   width = 28,
//   height = 28,
//   color = 'currentColor',
// }) => (
//   <svg
//     width={width}
//     height={height}
//     viewBox="0 0 28 28"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M5 5C5 3.34315 6.34314 2 8 2H20C21.6569 2 23 3.34315 23 5V23.999C23 25.6831 21.0458 26.6132 19.7388 25.5513L14 20.8885L8.26118 25.5513C6.95415 26.6132 5 25.6831 5 23.999V5Z"
//       fill={color}
//     />
//   </svg>
// );

export const BookmarkCheckmarkIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 19.5915V4H17V19.5915L13.4633 17.6155C12.5539 17.1074 11.4461 17.1074 10.5367 17.6155L7 19.5915ZM5 3C5 2.44772 5.44772 2 6 2H18C18.5523 2 19 2.44772 19 3V21.2958C19 22.0593 18.1788 22.5412 17.5122 22.1687L12.4878 19.3614C12.1846 19.1921 11.8154 19.1921 11.5122 19.3614L6.48776 22.1687C5.82119 22.5412 5 22.0593 5 21.2958V3ZM11.7851 13.42L15.6284 7.86846L14.374 7L11.3516 11.3657L9.29666 10.1075L8.5 11.4087L11.7851 13.42Z"
      fill={color}
    />
  </svg>
);

export const BookmarkCheckmarkLargeIcon = ({
  width = 28,
  height = 28,
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C6.34314 2 5 3.34315 5 5V23.999C5 25.6831 6.95415 26.6132 8.26118 25.5513L14 20.8885L19.7388 25.5513C21.0458 26.6132 23 25.6831 23 23.999V5C23 3.34315 21.6569 2 20 2H8ZM7 5C7 4.44772 7.44772 4 8 4H20C20.5523 4 21 4.44772 21 5V23.999L15.2612 19.3362C14.5264 18.7392 13.4736 18.7392 12.7388 19.3362L7 23.999V5ZM14.3222 14.5692L18.8222 8.06921L17.1778 6.93079L13.2159 12.6535L10.5222 11.0043L9.47785 12.71L12.9778 14.8529L13.7841 15.3465L14.3222 14.5692Z"
      fill={color}
    />
  </svg>
);

export function BookmarkFilledLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5C5 3.34315 6.34314 2 8 2H20C21.6569 2 23 3.34315 23 5V23.999C23 25.6831 21.0458 26.6132 19.7388 25.5513L14 20.8885L8.26118 25.5513C6.95415 26.6132 5 25.6831 5 23.999V5Z"
        fill={color}
      />
    </svg>
  );
}

export function BookmarkPlusLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C6.34314 2 5 3.34315 5 5V23.999C5 25.6831 6.95415 26.6132 8.26118 25.5513L14 20.8885L19.7388 25.5513C21.0458 26.6132 23 25.6831 23 23.999V5C23 3.34315 21.6569 2 20 2H8ZM13 17V13H8.99997V11H13V6.99996H15V11H19V13H15V17H13Z"
        fill={color}
      />
    </svg>
  );
}

export function BookmarkQuestionmarkLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C6.34314 2 5 3.34315 5 5V23.999C5 25.6831 6.95415 26.6132 8.26118 25.5513L14 20.8885L19.7388 25.5513C21.0458 26.6132 23 25.6831 23 23.999V5C23 3.34315 21.6569 2 20 2H8ZM15.598 5.71517C15.156 5.58276 14.697 5.5 14.221 5.5C13.609 5.5 13.065 5.61586 12.606 5.81448C12.13 6.02966 11.756 6.29448 11.45 6.60897C11.144 6.94 10.94 7.28759 10.804 7.68483C10.668 8.08207 10.6 8.47931 10.6 8.86H12.691C12.691 8.36345 12.827 7.96621 13.116 7.66828C13.388 7.37035 13.779 7.22138 14.255 7.22138C14.697 7.22138 15.037 7.35379 15.309 7.61862C15.581 7.88345 15.717 8.26414 15.717 8.76069C15.717 9.00897 15.683 9.22414 15.615 9.40621C15.547 9.58828 15.462 9.75379 15.377 9.88621C15.292 10.0186 15.19 10.1676 15.054 10.3C14.918 10.4324 14.646 10.6807 14.272 11.0117C13.881 11.3428 13.592 11.6407 13.422 11.889C13.252 12.1538 13.133 12.4021 13.082 12.6338C13.014 12.8655 12.997 13.0972 12.997 13.2959V13.5H15.224V13.3952C15.224 13.3621 15.224 13.3124 15.241 13.2131C15.241 13.1138 15.275 13.0145 15.326 12.8986C15.377 12.7828 15.462 12.6669 15.564 12.5345C15.666 12.4021 15.87 12.2034 16.193 11.9055C16.499 11.6076 16.754 11.3593 16.941 11.1607C17.111 10.9621 17.281 10.7138 17.434 10.4159C17.57 10.1345 17.672 9.83655 17.74 9.53862C17.791 9.24069 17.825 8.94276 17.825 8.67793C17.825 8.19793 17.723 7.75103 17.536 7.35379C17.349 6.95655 17.094 6.60897 16.754 6.34414C16.414 6.07931 16.04 5.86414 15.598 5.71517ZM15.071 15C14.799 14.7517 14.493 14.6193 14.119 14.6193C13.728 14.6193 13.405 14.7517 13.133 15C12.861 15.2483 12.742 15.5462 12.742 15.9103C12.742 16.2745 12.861 16.589 13.133 16.8372C13.405 17.1021 13.728 17.2179 14.119 17.2179C14.493 17.2179 14.799 17.1021 15.071 16.8372C15.343 16.589 15.479 16.2745 15.479 15.9103C15.479 15.5462 15.343 15.2483 15.071 15Z"
        fill={color}
      />
    </svg>
  );
}

export function BookmarkXLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C6.34314 2 5 3.34315 5 5V23.999C5 25.6831 6.95415 26.6132 8.26118 25.5513L14 20.8885L19.7388 25.5513C21.0458 26.6132 23 25.6831 23 23.999V5C23 3.34315 21.6569 2 20 2H8ZM17.5 16.6667L14 13.1667L10.5 16.6667L9.33331 15.5L12.8333 12L9.33331 8.50004L10.5 7.33337L14 10.8334L17.5 7.33337L18.6666 8.50004L15.1666 12L18.6666 15.5L17.5 16.6667Z"
        fill={color}
      />
    </svg>
  );
}
