import * as React from 'react';
import { styled } from 'naan/stitches.config';

const Wrapper = styled('div', {
  $$centerColumnWidth: '$$maxWidth',
  $$backgroundColor: 'transparent',
  $$centerColumnGap: '$space$4',

  backgroundColor: '$$backgroundColor',
  display: 'grid',
  gridTemplateColumns:
    '1fr min($$centerColumnWidth, calc( 100% - calc( $$centerColumnGap * 2) )) 1fr',

  '& > .center-column-inner': {
    gridColumn: '2',
    // background: 'rgba(0,0,0,.4)',
  },

  '@medium': {
    $$centerColumnGap: '$space$6',
  },

  variants: {
    isolate: {
      true: {
        isolation: 'isolate',
      },
      false: {
        isolation: 'auto',
      },
    },
    narrow: {
      true: {
        $$centerColumnWidth: '600px',
      },
    },
  },

  defaultVariants: {
    isolate: true,
  },
});

Wrapper.displayName = 'CenterColumnLayout';

export const CenterColumnLayout: React.FC<
  React.PropsWithChildren<{
    backgroundColor?: string;
    isolate?: boolean;
    narrow?: boolean;
  }> &
    React.ComponentProps<typeof Wrapper>
> = ({ isolate, children, narrow, backgroundColor, css = {}, ...props }) => {
  if (backgroundColor) {
    css['$$backgroundColor'] = backgroundColor;
  }

  return (
    <Wrapper isolate={isolate} narrow={narrow} css={css} {...props}>
      <div className="center-column-inner">{children}</div>
    </Wrapper>
  );
};
