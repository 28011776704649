import { appConfig } from '@app/config';
import { ChapterCatalogData } from '@core/models/catalog/chapter-catalog-data';
import { Story, SoundbiteFilterValues } from '@core/models/story-manager/story';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';
import { AppFactory } from 'app/app-factory';
import { decorateUrl, UrlOptions } from './decorate-url';
import { ReturnNavState } from './return-nav-state';
import { LocaleCode } from '@utils/util-types';

// const { forcedL2 } = appConfig;

const { baseUrl } = appConfig.website;

export const l2Path = (l2?: LocaleCode) =>
  `/${/*forcedL2 ||*/ l2 || ReturnNavState.l2}`;
// export const l2Url = (l2?: LocaleCode) => `${baseUrl}${l2Path(l2)}`;
export const l2Url = (l2?: LocaleCode) => `${baseUrl}${l2Path(l2)}`;
export const l2l1Url = ({ l2, l1 }: { l2: LocaleCode; l1: LocaleCode }) =>
  `${l2Url(l2)}?selectedL1=${l1}`;

export const authPath = () => `${l2Path()}/auth`;
export const adminPath = () => `${l2Path()}/admin`;
export const appPath = () => `${l2Path()}/app`;
export const profilePath = () => `${appPath()}/profile`;
export const teachPath = () => `${appPath()}/teach`;
export const learnPath = () => `${appPath()}/learn`;
// used by BrandedBackLink
export const appModePath = () => `${appPath()}/${ReturnNavState.appMode}`;

export const discoverPath = () => `${teachPath()}/discover`;
export const favoritesPath = () => `${teachPath()}/favorites`;
export const classroomsPath = () => `${teachPath()}/classrooms`;

export const teachHomePath = () =>
  AppFactory.root.userManager.hasClassrooms ? classroomsPath() : discoverPath();

export const learnHomePath = (urlOptions: UrlOptions = {}) =>
  decorateUrl(`${learnPath()}/home`, urlOptions);
export const learnStoriesPath = (urlOptions: UrlOptions = {}) =>
  decorateUrl(`${learnPath()}/stories`, urlOptions);
export const learnProgressPath = () => `${learnPath()}/progress`;

export const basicScriptPath = (story: Story) =>
  `${l2Path()}/scripts/basic/${story.slug}`;
export const scaffoldedScriptPath = (story: Story) =>
  `${l2Path()}/scripts/scaffolded/${story.slug}`;

// need to build fully qualified URLs for native external links to work
export const basicScriptUrl = (story: Story) =>
  `${baseUrl}${basicScriptPath(story)}`;
export const scaffoldedScriptUrl = (story: Story) =>
  `${baseUrl}${scaffoldedScriptPath(story)}`;

export const supportPath = () => `${appPath()}/support`;

export const soundbitesPath = (urlOptions: UrlOptions = {}) =>
  decorateUrl(`${learnPath()}/soundbites`, urlOptions);

export const calendarSoundbitePath = (
  slug: string,
  urlOptions: UrlOptions = {}
) => decorateUrl(`${learnPath()}/soundbites/${slug}`, urlOptions);

export const storySoundbitePath = (
  storySlug: string,
  soundbiteSlug: string,
  urlOptions: UrlOptions = {}
) =>
  decorateUrl(
    `${learnStorySlugPath(storySlug)}/soundbites/${soundbiteSlug}`,
    urlOptions
  );

// export const soundbiteOrientationPath = (continuePath: string) =>
//   `/soundbite-orientation?continue=${continuePath}`;

export const collectionsPath = () => `${learnPath()}/collections`;
export const collectionDetailPath = (slug: string) =>
  `${collectionsPath()}/${slug}`;

// todo: figure out jason's fancy string match typing
export const storiesPrimaryFiltered = (filterKey: PrimaryFilterKeys | string) =>
  `${learnStoriesPath()}?primary=${filterKey}`;

export const storiesTagFiltered = (type: string, value: string) =>
  `${learnStoriesPath({})}?filter_type=${type}&filter_value=${value}`;

export const storiesSoundbiteFiltered = (
  soundbiteFilter: keyof typeof SoundbiteFilterValues
) => {
  const filterValue = SoundbiteFilterValues[soundbiteFilter]?.() ?? null;
  if (filterValue) {
    return `${learnStoriesPath()}?filter_type=sb&filter_value=${filterValue}`;
  }
  return '';
};

export const learnStoryPath = (story: Story) => learnStorySlugPath(story?.slug);

export const learnStorySlugPath = (
  storySlug: string,
  tab?: 'story' | 'soundbites'
) => `${learnStoriesPath()}/${storySlug}${tab ? `/${tab}` : ''}`;

export const discoverStoryPath = (story: Story) =>
  `${discoverPath()}/v/${story.slug}`;

export const checkoutSuccessUrlFn = () =>
  `${l2Url()}/app/learn/home?checkout=success`;
export const checkoutCancelUrlFn = () => `${l2Url()}/app/profile/subscribe`;

// tmp
export const vocabDevPath = (story: Story) =>
  `${learnStoryPath(story)}/vocab-dev`;

export const studyBasePath = (chapter: ChapterCatalogData) =>
  `${learnStoriesPath()}/${chapter.storySlug}/study/${chapter.unitNumber}/${
    chapter.position
  }`;

export const soundbitePreviewByUrl = (url: string) =>
  `${adminPath()}/preview?url=${url}`;
export const soundbitePreviewBySlug = (slug: string) =>
  `${adminPath()}/preview?slug=${slug}`;

export const profileHomePath = () => `${profilePath()}/account`;
export const l2AccountUrl = (l2: LocaleCode) =>
  `${l2Url(l2)}/app/profile/account`;

export const giftCouponsPath = () => `${profilePath()}/gift-coupons`; // deprecated
export const closeAccountPath = () => `${profilePath()}/close-account`;
export const accountClosedPath = () => `${l2Path()}/account-closed`;

export const devToolsPath = () => `${profilePath()}/dev-tools`;

export const homePath = () => {
  if (AppFactory.root.userManager.hasClassrooms) {
    return teachHomePath();
  } else {
    return learnHomePath();
  }
};

export const pagePath = (page: string) => `${appPath()}/page/${page}`;

export const defaultPath = () => {
  return AppFactory.root.defaultLandingRoute;
};

// todo: revisit how to detect and handle the apple review mode
// for now the welcome screen just goes to '/'
// // where the welcome screen 'get started' button navigates to
// export const getStartedPath = () => {
//   // const { accountCreationDisabled } = AppFactory.root;
//   // return AppFactory.root.accountCreationDisabled ? signInPath() : homePath();
// };

/// @elliottjf I think we'll need to do something special to track the marketing parameters in the auth pages
const decorateAuthPath = (path: string, opts: { after?: string }) => {
  return decorateUrl(`${authPath()}/${path}`, { search: opts });
};

type AuthSearchParams = {
  after?: string;
  flow?: string;
};

export const createAccountPath = (opts: AuthSearchParams = {}) =>
  decorateAuthPath('create-account', opts);

export const multiStepSignupPath = (opts: AuthSearchParams = {}) =>
  decorateAuthPath('create-account/name', opts);

export const signInPath = (opts: AuthSearchParams = {}) =>
  decorateAuthPath('sign-in', opts);

export const signInCodePath = (opts: AuthSearchParams = {}) =>
  decorateAuthPath('sign-in-code', opts);

export const emailCodePath = (opts: AuthSearchParams = {}) =>
  decorateAuthPath('email-code', opts);

export const logoutPath = () => `${l2Path()}/logout`;

// handles flow of authenticated user deep linking to other l2
// export const l2SwitchPath = () => '/switch';

// export const welcomePath = (opts: AuthSearchParams = {}) =>
//   authPath('/welcome', opts);
// export const welcomePath = () => '/welcome';

// for now we simply send anonymous users to the create account page when attempting to follow
// links to destinations which require an authenticated context
export const noAuthRedirect = createAccountPath;

// just sent beta users to the create account screen since we haven't designed the subscribe interstitial yet
// export const subscribePath = createAccountPath; // () => `/app/profile/subscribe`;

export const subscribePath = (
  options: { flow?: string; backTo?: string } = {}
) => {
  // if (AppFactory.root?.userManager?.accountData?.betaMode) {
  //   return createAccountPath(); // hack out for beta users until designed
  // } else {
  return decorateUrl(`${profilePath()}/subscribe`, {
    search: options,
  });
  // }
};

// export const dailySoundbitePath = (
//   options: {
//     orientation?: 'skip';
//     flow?: string;
//   } = {}
// ) => {
//   return decorateUrl('/daily-soundbite', {
//     search: options,
//   });
// };

export const marketingSite = () => appConfig.website.urls.marketingSite;

export const contactUs = () => `${marketingSite()}/contact-us`; // todo: qualify with L1 once PT page exists

// these pages will only exist in English
export const tosUrl = () => `${marketingSite()}/en/legal/terms-of-service`;
export const privacyUrl = () => `${marketingSite()}/en/legal/privacy-policy`;
export const eulaUrl = () => `${marketingSite()}/en/legal/eula`;

// loose dependency needed by user-manager.ts
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    checkoutSuccessUrlFn: () => string;
    checkoutCancelUrlFn: () => string;
  }
}

window.checkoutSuccessUrlFn = checkoutSuccessUrlFn;
window.checkoutCancelUrlFn = checkoutCancelUrlFn;
