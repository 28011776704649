import * as React from 'react';

import { observer } from 'mobx-react';
import { VocabRowData } from '@core/models/ui/vocab-list-model';
import { styled } from '@naan/stitches.config';
import { IconButton } from '@naan/primitives/button';
import classNames from 'classnames';
import { PlainMarkdown } from '@naan/primitives/text';
import { vocabUsageText } from '@core/models/user-manager/story-progress';
import {
  BookmarkCheckmarkLargeIcon,
  BookmarkFilledLargeIcon,
  BookmarkLargeIcon,
} from '@naan/icons/bookmark-icon';
// import { useCurrentStory } from '../current-story-context';

const Wrapper = styled('div', {
  marginLeft: 40,
  padding: '8px 56px 12px 0',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  '& > .headword': {
    textStyle: 'serif',
  },
  '& > .note': {
    textStyle: 'small-text',
    color: '$textSecondary',
  },
  '& > .action': {
    position: 'absolute',
    right: 4,
    top: 10,
  },
  '&.removing > .headword': {
    color: '$gray-100',
  },
  '&.removing > .note': {
    color: '$gray-100',
  },
});

export const VocabRow = observer(({ data }: { data: VocabRowData }) => {
  // const text = data.hasUsageText
  //   ? `${data.usageText} (${data.headword})`
  //   : data.headword;
  // const story = useCurrentStory();
  const text = vocabUsageText(data.notation);
  const handleClick = React.useCallback(() => {
    data.toggle();
  }, [data]);

  // const progress = story.progress;
  // const previouslyLearned = progress.vocabLearned(data.notation.id);
  const { previouslyLearned, unlearned } = data;

  const removed = data.remove;

  return (
    <Wrapper className={classNames({ removing: data.remove })}>
      <PlainMarkdown className="headword" source={text} />
      <PlainMarkdown className="note" source={data.note} />
      <div className="action">
        {removed ? (
          <IconButton
            presentation={'grayTransparent'}
            size={'large'}
            onClick={handleClick}
            icon={<BookmarkLargeIcon />}
            testId={`vocab-list-row-remove-btn-${data.slug}`}
          />
        ) : previouslyLearned && !unlearned ? (
          <IconButton
            presentation={'greenTransparent'}
            size={'large'}
            onClick={handleClick}
            icon={<BookmarkCheckmarkLargeIcon />}
            testId={`vocab-list-row-remove-btn-${data.slug}`}
          />
        ) : (
          <IconButton
            presentation={'purple'}
            size={'large'}
            onClick={handleClick}
            icon={<BookmarkFilledLargeIcon />}
            testId={`vocab-list-row-remove-btn-${data.slug}`}
          />
        )}
      </div>
    </Wrapper>
  );
});
