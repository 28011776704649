import * as React from 'react';
import * as Interstitial from './onboarding-interstitial';
import { AppFactory } from '@app/app-factory';
import { useNavigate } from 'react-router';
// import { observer } from 'mobx-react';
import { namedObserver } from 'lib/named-observer';
import { Story } from '@core/models/story-manager/story';
import {
  learnStoriesPath,
  multiStepSignupPath,
  // storySoundbitePath,
} from 'components/nav/path-helpers';
import { PlainMarkdown } from '@naan/primitives/text';
// import { VSpacer } from '@naan/primitives/spacer';
import { track } from '@app/track';

import __ from '@core/lib/localization';

import runImage from './assets/run.png';

export const BeforeAuthInterstitial = namedObserver(
  'BeforeAuthInterstitial',
  ({ story }: { story: Story }) => {
    const navigate = useNavigate();

    React.useEffect(() => {
      track('onboarding__interstitial_shown', { stage: 'soundbite' });
    }, []);

    const handleButtonClick = () => {
      const { userManager } = AppFactory.root;
      const { userSettings } = userManager.userData;

      if (!userManager.authenticated) {
        const after = `${learnStoriesPath()}/${story.slug}`;
        navigate(multiStepSignupPath({ after }), { replace: true });
      }
      userSettings.dismissTip('storyInterstitial');
    };

    return (
      <Interstitial.Container presentation="teal">
        <Interstitial.CloseButton />
        {/* <div className="img" role={'presentation'}></div> */}
        <Interstitial.Image src={runImage} />
        <Interstitial.Content>
          <Interstitial.TextSection>
            <Interstitial.Title>
              {__("And we're off!", 'andWereOffBang')}
            </Interstitial.Title>
            <Interstitial.Copy>
              <PlainMarkdown
                source={__(
                  "In the next step you'll listen and study Chapter 1 in full. But first let's create an account to save your progress.",
                  'inTheNextStepYoullListenAndStudyChapter1InFull'
                )}
              />
            </Interstitial.Copy>
          </Interstitial.TextSection>
          <Interstitial.Button
            presentation="teal"
            label={__('Create free account', 'createFreeAccount')}
            onClick={handleButtonClick}
          />
        </Interstitial.Content>
      </Interstitial.Container>
    );
  }
);
