import { ModelTreeNode } from 'ts-state-tree/tst-core';

export const VOCAB_REVIEW_ITEM_POINTS = 5;

export type ActivityLogKind = 'VOCAB_REVIEW';

/**
 * ActivityLog
 *
 * holds review session points for a given day and story
 */
export class ActivityLog extends ModelTreeNode {
  static CLASS_NAME = 'ActivityLog' as const;

  static create(snapshot: any) {
    return super.create(ActivityLog, snapshot) as ActivityLog;
  }

  static mapKey(
    storySlug: string,
    date: string,
    kind: ActivityLogKind
  ): string {
    return [storySlug, date, kind].join('.');
  }

  kind: ActivityLogKind;

  // granularity: string = undefined; // 'daily' implied when undefined, MONTH for coalesced data
  date: string = ''; // iso date (w/ time part truncated now after migration)
  storySlug: string = ''; // story or soundbite slug

  count: number = 0;
  points: number = 0;

  get mapKey(): string {
    return ActivityLog.mapKey(this.storySlug, this.date, this.kind);
  }

  // allows shared interface for stat functions
  get listenedMillis(): number {
    return 0;
  }
}
