import * as React from 'react';

export function RelistenedIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.7845 7.85806C12.9795 8.02829 12.9996 8.32441 12.8294 8.51945C12.6592 8.71449 12.3631 8.73461 12.168 8.56438L10.7803 7.35316C10.5858 7.18346 10.5651 6.88852 10.734 6.69334L11.9503 5.28718C12.1197 5.09139 12.4157 5.06997 12.6115 5.23933C12.8073 5.4087 12.8287 5.70472 12.6594 5.90052L12.1683 6.46814C15.1659 6.54009 17.6715 8.88401 17.8848 11.9349C18.1113 15.1739 15.6693 17.983 12.4304 18.2095C9.19186 18.436 6.38276 15.9939 6.15628 12.7551C6.13822 12.4968 6.33293 12.2728 6.59119 12.2548C6.84944 12.2367 7.07343 12.4314 7.09149 12.6897C7.28186 15.412 9.64299 17.4647 12.365 17.2743C15.0874 17.0839 17.14 14.7228 16.9496 12.0003C16.7733 9.47901 14.7344 7.53213 12.2712 7.41005L12.7845 7.85806Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function RelistenCircleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM10.5733 7.63487C10.3262 7.30239 10.3955 6.8326 10.7279 6.58554L12.741 5.08966C13.0735 4.8426 13.5433 4.91185 13.7903 5.24432C14.0374 5.57679 13.9681 6.04659 13.6357 6.29364L13.2517 6.57893C15.721 7.14678 17.5625 9.35836 17.5625 12C17.5625 15.0721 15.0721 17.5625 12 17.5625C8.92792 17.5625 6.4375 15.0721 6.4375 12C6.4375 11.5858 6.77329 11.25 7.1875 11.25C7.60171 11.25 7.9375 11.5858 7.9375 12C7.9375 14.2437 9.75634 16.0625 12 16.0625C14.2437 16.0625 16.0625 14.2437 16.0625 12C16.0625 9.99974 14.6168 8.33711 12.7133 7.99995L13.2731 8.75329C13.5202 9.08576 13.4509 9.55556 13.1185 9.80261C12.786 10.0497 12.3162 9.98042 12.0691 9.64795L10.5733 7.63487Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
export function RelistenCircleExtraExtraLargeIcon({
  width = 56,
  height = 56,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.5 28C49.5 39.5979 40.0979 49 28.5 49C16.902 49 7.49995 39.5979 7.49995 28C7.49995 16.402 16.902 6.99995 28.5 6.99995C40.0979 6.99995 49.5 16.402 49.5 28ZM52.2999 28C52.2999 41.1443 41.6443 51.7999 28.5 51.7999C15.3556 51.7999 4.69995 41.1443 4.69995 28C4.69995 14.8556 15.3556 4.19995 28.5 4.19995C41.6443 4.19995 52.2999 14.8556 52.2999 28ZM25.6016 18.0919C25.0548 17.5451 25.0548 16.6587 25.6016 16.112L29.2479 12.4657C29.7946 11.9189 30.681 11.9189 31.2278 12.4657C31.7745 13.0124 31.7745 13.8988 31.2278 14.4456L30.0817 15.5917C36.2793 16.3691 41.0738 21.6577 41.0738 28.066C41.0738 35.0098 35.4447 40.6388 28.501 40.6388C21.5572 40.6388 15.9282 35.0098 15.9282 28.066C15.9282 27.2928 16.555 26.666 17.3282 26.666C18.1014 26.666 18.7282 27.2928 18.7282 28.066C18.7282 33.4634 23.1036 37.8388 28.501 37.8388C33.8983 37.8388 38.2738 33.4634 38.2738 28.066C38.2738 23.1284 34.612 19.0461 29.8559 18.3864L31.2278 19.7583C31.7745 20.305 31.7745 21.1915 31.2278 21.7382C30.681 22.2849 29.7946 22.2849 29.2479 21.7382L25.6016 18.0919Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
