type ExitCopy = {
  Heading: string;
  Body: string;
};

export type ExitMessageCopyTiers = {
  consolation: Array<ExitCopy>;
  perfect: Array<ExitCopy>;
  regular: Array<ExitCopy>;
};

export const en: ExitMessageCopyTiers = {
  consolation: [
    {
      Heading: 'Good try!',
      Body: "You'll get them next time.",
    },
    {
      Heading: 'Good work!',
      Body: "Embrace the struggle; it's the path to mastery.",
    },
    {
      Heading: 'Great effort!',
      Body: 'Challenges make you stronger.',
    },
    {
      Heading: 'Everything counts.',
      Body: 'The ones you miss are teaching you the most.',
    },
  ],
  perfect: [
    {
      Heading: 'Perfect!',
      Body: "You're doing great, keep it up.",
    },
    {
      Heading: 'You nailed it!',
      Body: "Maybe you're ready for a more challenging story?",
    },
    {
      Heading: 'Excellent!',
      Body: 'Your hard work is paying off. Keep at it.',
    },
    {
      Heading: "You're unstoppable!",
      Body: "You're well on the path to mastery.",
    },
    {
      Heading: "You're good at this!",
      Body: "Keep challenging yourself. That's how you grow.",
    },
  ],
  regular: [
    {
      Heading: 'Everything counts.',
      Body: 'The ones you miss are teaching you the most.',
    },
    {
      Heading: "You're doing great!",
      Body: 'Keep adding to your vocabulary lists.',
    },
    {
      Heading: 'A winning strategy!',
      Body: 'The way to improve is to keep challenging yourself.',
    },
    {
      Heading: 'See you tomorrow?',
      Body: 'A daily habit is the path to fluency.',
    },
    {
      Heading: 'Good progress!',
      Body: 'Come back soon. Consistency is the key.',
    },
    {
      Heading: 'Nice work!',
      Body: 'You’re making solid progress.',
    },
    {
      Heading: 'Good job!',
      Body: 'Your studying is paying off.',
    },
  ],
};

export const pt: ExitMessageCopyTiers = {
  consolation: [
    {
      Heading: 'Boa tentativa!',
      Body: 'Você vai mandar bem na próxima!',
    },
    {
      Heading: 'Bom trabalho!',
      Body: 'Não tenha medo do difícil. Esse é o caminho para a maestria.',
    },
    {
      Heading: 'Valeu o esforço!',
      Body: 'Desafios fazem você mais forte.',
    },
    {
      Heading: 'Errar faz parte!',
      Body: 'Os erros que você comete são os melhores professores.',
    },
  ],
  perfect: [
    {
      Heading: 'Perfeito!',
      Body: 'Você está mandando bem. Continue assim.',
    },
    {
      Heading: 'Mandou bem!',
      Body: 'Talvez você esteja pronto para histórias mais difíceis.',
    },
    {
      Heading: 'Excelente!',
      Body: 'Seu trabalho duro está valendo a pena. Siga assim.',
    },
    {
      Heading: 'Incrível!',
      Body: 'Você está no caminho certo rumo à excelência.',
    },
    {
      Heading: 'Ótimo trabalho!',
      Body: 'Continue se desafiando. É assim que você progride.',
    },
  ],
  regular: [
    {
      Heading: 'Tudo conta.',
      Body: 'Errar e acertar é parte de uma grande jornada.',
    },
    {
      Heading: 'Está mandando bem!',
      Body: 'Continue adicionando palavras a sua lista de vocabulário.',
    },
    {
      Heading: 'Esse é o caminho!',
      Body: 'Para progredir, basta continuar se desafiando.',
    },
    {
      Heading: 'Vejo você amanhã?',
      Body: 'Manter a rotina é o melhor caminho para a fluência.',
    },
    {
      Heading: 'Bom progresso!',
      Body: 'Volte logo. Consistência é o segredo.',
    },
    {
      Heading: 'Bom trabalho!',
      Body: 'Você está fazendo um ótimo progresso.',
    },
    {
      Heading: 'Siga firme!',
      Body: 'Seus estudos vão valer a pena.',
    },
  ],
};

export const es: ExitMessageCopyTiers = {
  consolation: [
    {
      Heading: '¡Buen intento!',
      Body: 'Sigue intentando, ¡te irá mejor en la próxima!',
    },
    {
      Heading: '¡Buen trabajo!',
      Body: 'Asume el reto, es el camino hacia la superación.',
    },
    {
      Heading: '¡Te esforzaste!',
      Body: 'Los desafíos te hacen más fuerte.',
    },
    {
      Heading: 'Todos los esfuerzos cuentan.',
      Body: 'De los errores se aprende más.',
    },
    {
      Heading: '¡Muy bien!',
      Body: 'Nadie nace sabiendo. ¡Sigue intentándolo!',
    },
  ],
  perfect: [
    {
      Heading: '¡Perfecto!',
      Body: 'Te está yendo muy bien, sigue así.',
    },
    {
      Heading: '¡Le diste en el clavo!',
      Body: '¿Quizás es hora de pasar a una historia más desafiante?',
    },
    {
      Heading: '¡Excelente!',
      Body: 'Se notan los resultados de tu esfuerzo. No te detengas.',
    },
    {
      Heading: '¡Eres imparable!',
      Body: 'Sigue así y pronto serás un experto.',
    },
    {
      Heading: '¡Eres bueno en esto!',
      Body: 'Sigue desafiándote a ti mismo. Así es como mejoras.',
    },
    {
      Heading: '¡Atinaste!',
      Body: 'Cada día estás más cerca de tu meta.',
    },
    {
      Heading: '¡Increíble!',
      Body: 'Sigue superando tus propios límites.',
    },
    {
      Heading: '¡Bravo!',
      Body: 'Estás progresando a pasos agigantados.',
    },
  ],
  regular: [
    {
      Heading: 'Todos los esfuerzos cuentan.',
      Body: 'De los errores se aprende más.',
    },
    {
      Heading: '¡Muy bien!',
      Body: 'Sigue añadiéndole a tus listas de vocabulario.',
    },
    {
      Heading: '¡Una estrategia ganadora!',
      Body: 'Sigue desafiándote y verás que lo lograrás.',
    },
    {
      Heading: '¿Nos vemos mañana?',
      Body: 'Practicar a diario es el secreto para dominar el idioma.',
    },
    {
      Heading: '¡Impresionante progreso!',
      Body: 'La constancia es importante para la fluidez, así que, ¡vuelve pronto!',
    },
    {
      Heading: '¡Buen trabajo!',
      Body: 'Vas avanzando a paso firme.',
    },
    {
      Heading: '¡Bien hecho!',
      Body: 'Se nota que tus esfuerzos están valiendo la pena.',
    },
    {
      Heading: '¡Sigue así!',
      Body: 'Cada paso te acerca más a tu objetivo.',
    },
    {
      Heading: '¡Sigues mejorando!',
      Body: 'Recuerda, la persistencia es la clave del éxito.',
    },
    {
      Heading: '¡Vas bien!',
      Body: 'Sigue esforzándote, pronto verás los resultados.',
    },
  ],
};
