import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { VocabListHeading } from './vocab-list-heading';
import { ZeroStateView } from 'components/ui/zero-state-view';
import { unwidow } from '@utils/string-utils';
import { BookmarkLargeIcon } from '@naan/icons/bookmark-icon';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  overflowY: 'auto',
  marginBottom: '$4',

  '& > .vocab-rows': {
    // borderTop: '1px solid $gray-100',
    '& > * + *': {
      borderTop: '1px solid $gray-100',
    },
  },
});

Wrapper.displayName = 'VocabListWrapper';

export const VocabListZeroState = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    return (
      <>
        <VocabListHeading count={0} onClose={onDismiss} />
        <ZeroStateView
          icon={<BookmarkLargeIcon width={40} height={40} />}
          title={unwidow(
            __('No vocabulary for this story', 'noVocabularyForThisStory')
          )}
          description={unwidow(
            __(
              'Tap the bookmark button next to a vocabulary item to add it to this list.',
              'tapBookmarkButtonToAddVocabulary'
            )
          )}
        ></ZeroStateView>
      </>
    );
  }
);
