import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { homePath } from 'components/nav/path-helpers';
import { styled } from '@naan/stitches.config';
import largeBackground from './bg.svg';
import { BrandedBackButton } from 'components/branding/branded-back-link';
import { Insets } from 'native-support/insets';
// import { createLogger } from '@common/log';
// const log = createLogger('subscription-layout');

const Wrapper = styled('div', {
  minHeight: '100%',
  backgroundColor: 'tan',
  backgroundImage: `url(${largeBackground})`,
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${Insets.top + 96}px 0 48px 0`,

  '@medium': {
    padding: '80px 0',
    paddingTop: `${Insets.top + 80}px`,
  },

  '& .content': {
    width: 'min( 632px, calc(100% - 32px) )',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .title': {
      color: '$colors$white',
      textStyle: 'medium-heading',
      marginTop: 40,
      marginBottom: 32,
      '@medium': {
        textStyle: 'extra-large-heading',
        marginTop: 80,
        marginBottom: 72,
      },
    },
  },
});

const BackButtonContainer = styled('div', {
  all: 'unset',
  color: 'white',
  position: 'absolute',
  top: Insets.top + 16,
  left: '16px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',

  '@medium': {
    top: Insets.top + 28,
    left: '24px',
  },
});

const Back = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const backTo = params.get('backTo');
  const fromMarketing = params.get('from') === 'website';

  return (
    <BackButtonContainer>
      <BrandedBackButton
        presentation="white"
        tabIndex={-1}
        onClick={() => {
          if (fromMarketing) {
            // log.debug('fromMarketing true - window.history.back()');
            window.history.back();
            return;
          }

          if (backTo) {
            // log.debug(`navigating to backTo: ${backTo}`);
            navigate(backTo);
            return;
          }

          // log.debug(
          //   `canPopFromSubscribe: ${String(
          //     ReturnNavState.canPopFromSubscribe()
          //   )}`
          // );
          if (ReturnNavState.canPopFromSubscribe()) {
            navigate(-1);
          } else {
            navigate(homePath());
          }
        }}
      ></BrandedBackButton>
    </BackButtonContainer>
  );
};

export const SubscriptionLayout: React.FC<React.PropsWithChildren<unknown>> =
  observer(({ children }) => {
    return (
      <Wrapper>
        <Back />
        <div className="content">{children}</div>
      </Wrapper>
    );
  });
