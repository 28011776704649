/// This is a mechanism to use descriptive css variables
/// so we can reuse most of the player styles and just switch the colors
/// when necessary.
/// This is different from the global theming mechanism which will work just by swapping palettes.
export const palette = {
  $$script__backgroundColor_default: '$colors$gray-75',
  $$script__backgroundColor_playing: '$colors$gray-300',

  $$header__backgroundColor: '$colors$blue-500',

  $$chapterTitle__color: '$colors$textPrimary',
  $$chapterTitle__positionColor: '$colors$textSecondary',

  $$passageHint__borderColor: '$colors$black-alpha-06',
  $$passageHint__color_unvisited: '$colors$black-alpha-06',
  $$passageHint__color_visited: '$colors$textPrimary',

  $$sentence__backgroundColor_default: '$colors$transparent',
  $$sentence__backgroundColor_hover: '$colors$white-alpha-30',
  $$sentence__backgroundColor_current: '$colors$white',

  $$sentence_toolbar__backgroundColor: '$colors$gray-25',
  $$sentence_toolbar__shadowColor: '$colors$black-alpha-20',
  $$sentence_toolbar__color: '$colors$gray-800',

  $$word__color_default: '$colors$black-alpha-10',
  $$word__color_visited: '$colors$textPrimary',
  $$word__color_sic_visited: '$colors$textSecondary',
  $$word__color_pendingCurrent: '$colors$orange-500',

  $$redacted__backgroundColor_unvisited: '$colors$black-alpha-06',
  $$redacted__backgroundColor_visited: '$colors$gray-400',
  $$redacted__backgroundColor_current: '$colors$teal-400',
  $$redacted__backgroundColor_remaining: '$colors$orange-100',
  $$redacted__backgroundColor_pendingCurrent: '$colors$orange-400',

  $$sentence__inlineTranslationColor: '$colors$gray-500',

  $$spacer__backgroundColor: '$colors$yellow-500',
  $$spacer__backgroundColor_unvisited: '$colors$black-alpha-06',
  $$spacer__backgroundColor_current: '$colors$teal-500',
  $$spacer__backgroundColor_remaining: '$colors$orange-100',
  $$spacer__backgroundColor_pendingCurrent: '$colors$orange-400',
  $$spacer__backgroundColor_pauseIcon: '$colors$orange-400',

  $$speakerLabel__backgroundColor_unvisited: '$colors$transparent',
  $$speakerLabel__color_unvisited: '$colors$black-alpha-06',
  $$speakerLabel__borderColor_unvisited: '$colors$black-alpha-06',

  $$speakerLabel__backgroundColor_current: '$colors$white',
  $$speakerLabel__color_current: '$colors$textPrimary',
  $$speakerLabel__borderColor_current: '$colors$transparent',

  $$speakerLabel__backgroundColor_visited: '$colors$transparent',
  $$speakerLabel__color_visited: '$colors$textPrimary',
  $$speakerLabel__borderColor_visited: '$colors$black-alpha-06',

  $$translation__color: '$colors$black-alpha-06',
  $$translation__color_visited: '$colors$gray-500',

  $$transport__backgroundColor: '$colors$gray-25',
  $$transport__buttonColor: '$colors$gray-800',
  $$transport__buttonColor_disabled: '$colors$gray-100',
  $$transport__buttonColor_active: '$colors$teal-500',
  $$transport__progressBackgroundColor: '$colors$gray-75',
  $$transport__progressFurthestColor: '$colors$teal-500-alpha-30',
  $$transport__progressCurrentColor: '$colors$teal-400',
  $$transport__pendingPauseColor: '$colors$gray-100',
  $$transport__pendingPauseProgressColor: '$colors$orange-400',

  $$keyboardButton__iconColor: '$colors$black-alpha-30',
  $$keyboardButton__hoverBackground: '$colors$black-alpha-06',

  $$endCard__backgroundColor: '$colors$white',
  $$endCard__primaryColor: '$colors$textPrimary',
  $$endCard__secondaryColor: '$colors$textSecondary',
  $$endCard__dividerColor: '$colors$gray-100',

  /// this is not a color and I would object to have more than one of these
  $$spacer__mixBlendMode: 'multiply',
} as const;

export type PaletteShape = typeof palette;

export const darkPalette: Record<keyof PaletteShape, string> = {
  $$script__backgroundColor_default: '$colors$gray-900',
  $$script__backgroundColor_playing: '$colors$gray-900',

  $$header__backgroundColor: '$colors$gray-800',

  $$chapterTitle__color: '$colors$gray-300',
  $$chapterTitle__positionColor: '$colors$gray-500',

  $$passageHint__borderColor: '$colors$white-alpha-06',
  $$passageHint__color_unvisited: '$colors$white-alpha-06',
  $$passageHint__color_visited: '$colors$gray-300',

  $$sentence__backgroundColor_default: '$colors$transparent',
  $$sentence__backgroundColor_hover: '$colors$black-alpha-30',
  $$sentence__backgroundColor_current: '$colors$gray-800',

  $$sentence_toolbar__backgroundColor: '$colors$black-alpha-15',
  $$sentence_toolbar__shadowColor: 'transparent',
  $$sentence_toolbar__color: '$colors$white',

  $$sentence__inlineTranslationColor: '$colors$gray-500',

  $$word__color_default: '$colors$white-alpha-03',
  $$word__color_visited: '$colors$white-alpha-10',
  $$word__color_sic_visited: '$colors$white-alpha-10',
  $$word__color_pendingCurrent: '$colors$orange-500',

  $$redacted__backgroundColor_unvisited: '$colors$white-alpha-03',
  $$redacted__backgroundColor_visited: '$colors$white-alpha-10',
  $$redacted__backgroundColor_current: '$colors$teal-500-alpha-30',
  $$redacted__backgroundColor_remaining: '$colors$orange-500-alpha-10',
  $$redacted__backgroundColor_pendingCurrent: '$colors$orange-800',

  $$spacer__backgroundColor: 'rgba(251, 197, 0, 0.2)',
  $$spacer__backgroundColor_unvisited: '$colors$white-alpha-03',
  $$spacer__backgroundColor_current: 'rgba(25, 187, 212, 0.5)',
  $$spacer__backgroundColor_remaining: '$colors$orange-500-alpha-10',
  $$spacer__backgroundColor_pendingCurrent: '$colors$orange-400',
  $$spacer__backgroundColor_pauseIcon: '$colors$orange-800',

  $$speakerLabel__backgroundColor_visited: '$colors$transparent',
  $$speakerLabel__color_visited: '$colors$white-alpha-20',
  $$speakerLabel__borderColor_visited: '$colors$white-alpha-10',

  $$speakerLabel__backgroundColor_current: '$colors$gray-400',
  $$speakerLabel__color_current: '$colors$gray-900',
  $$speakerLabel__borderColor_current: '$colors$transparent',

  $$speakerLabel__backgroundColor_unvisited: '$colors$transparent',
  $$speakerLabel__color_unvisited: '$colors$white-alpha-06',
  $$speakerLabel__borderColor_unvisited: '$colors$white-alpha-06',

  $$translation__color: '$colors$gray-800',
  $$translation__color_visited: '$colors$gray-800',

  $$transport__backgroundColor: '$colors$gray-800',
  $$transport__buttonColor: '$colors$white',
  $$transport__buttonColor_disabled: '$colors$gray-700',
  $$transport__buttonColor_active: '$colors$teal-500',
  $$transport__progressBackgroundColor: '$colors$gray-600',
  $$transport__progressFurthestColor: '$colors$teal-500-alpha-50',
  $$transport__progressCurrentColor: '$colors$teal-400',
  $$transport__pendingPauseColor: '$colors$gray-700',
  $$transport__pendingPauseProgressColor: '$colors$orange-700',

  $$keyboardButton__iconColor: '$colors$white-alpha-50',
  $$keyboardButton__hoverBackground: '$colors$black-alpha-30',

  $$endCard__backgroundColor: '$colors$gray-800',
  $$endCard__primaryColor: '$colors$white',
  $$endCard__secondaryColor: '$colors$gray-300',
  $$endCard__dividerColor: '$colors$gray-700',

  $$spacer__mixBlendMode: 'normal',
};

// doesn't really do anything, just a TS helper
export function paletteKey(key: keyof PaletteShape) {
  return key;
}
