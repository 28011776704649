import * as React from 'react';
import * as Onboarding from './components';
import { PlainMarkdown } from '@naan/primitives/text';
import { AppFactory } from '@app/app-factory';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { observer } from 'mobx-react';
import { OnboardingPopover } from './onboarding-popover';
import { styled } from '@naan/stitches.config';

import __ from 'core/lib/localization';

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: '50%',
    '@large': {
      borderRadius: 12,
    },
  },
});

const SpeedControlTip = () => {
  const l2 = AppFactory.root.l2Localized;
  return (
    <Onboarding.Container>
      <Onboarding.Title>
        {__('Check your speed', 'speedControlTipTitle')}
      </Onboarding.Title>
      <Onboarding.Content>
        <PlainMarkdown
          source={__(
            `Jiveworld features real, naturally spoken %{l2} — what you'll encounter in the real world. We've slowed the speed down to 0.8x, but you can change it here.`,
            'speedControlTipBody',
            { l2 }
          )}
        />
      </Onboarding.Content>
    </Onboarding.Container>
  );
};

export const PlaybackRateControlTip: React.FC<
  React.PropsWithChildren<unknown>
> = observer(({ children }) => {
  const dismissed = OnboardingService.instance.isDismissed('speedControl');
  const showPopover = OnboardingService.instance.showSpeedControlTip;

  return (
    <>
      <OnboardingPopover
        dismissed={dismissed}
        showPopover={showPopover}
        renderAnchor={({ ref, getProps }) => (
          <ToolipPositioner
            ref={ref}
            {...getProps()}
            className={showPopover ? 'highlight' : null}
          >
            {children}
          </ToolipPositioner>
        )}
        renderBody={() => {
          // @armando. this seems to only render from the study player, not the soundbite player
          return <SpeedControlTip />;
        }}
        onResolvePopover={OnboardingService.instance.speedControlTipDismissed}
      />
      {/* <SpeedControlTipDialog /> */}
    </>
  );
});
