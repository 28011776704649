import * as React from 'react';
import * as Onboarding from './components';
import { PlainMarkdown } from '@naan/primitives/text';
import { OnboardingPopover } from './onboarding-popover';
import { styled } from '@naan/stitches.config';
import {
  dismissCurrentTip,
  isCurrentTip,
  isDismissed,
  OnboardingService,
} from '@app/onboarding/onboarding-service';

import __ from 'core/lib/localization';
import { observer } from 'mobx-react';
import { OnboardingDialog } from './onboarding-dialogs';
import { BookmarkFilledLargeIcon } from '@naan/icons/bookmark-icon';
import { unwidow } from '@utils/string-utils';

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: '50%',
  },
});

export const AddToVocabTip: React.FC<{
  children: React.ReactNode;
}> = observer(({ children }) => {
  const popoverDismissed = isDismissed('addToVocab');
  const showPopover = isCurrentTip('addToVocab');

  return (
    <OnboardingPopover
      showPopover={showPopover}
      dismissed={popoverDismissed}
      onResolvePopover={OnboardingService.instance.addToVocabTipDismissed}
      renderAnchor={({ ref, getProps }) => (
        <ToolipPositioner
          ref={ref}
          {...getProps()}
          data-id="popover-wrapper"
          className={showPopover ? 'highlight' : null}
        >
          {children}
        </ToolipPositioner>
      )}
      renderBody={() => {
        return (
          <Onboarding.Container>
            <Onboarding.Content>
              <PlainMarkdown
                source={__(
                  'Tap to save any unfamiliar words to your vocabulary list.',
                  'tapHereToSaveUnfamiliarWordsToYourVocabularyList'
                )}
              />
            </Onboarding.Content>
          </Onboarding.Container>
        );
      }}
    />
  );
});

const Content = styled(Onboarding.Content, {
  padding: 16,
  textAlign: 'center',
  '& .icon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '$colors$purple-500',
    paddingInline: 16,
    marginBottom: 16,
    svg: {
      width: 48,
      height: 48,
    },
  },
  '& .heading': {
    textStyle: 'medium-heading',
    paddingInline: 16,
    marginBottom: 16,
  },
});

export const AddedFirstVocabOnboardingDialog = observer(() => {
  const showDialog = isCurrentTip('savedFirstVocabModal');

  if (!showDialog) {
    return null;
  }

  return (
    <OnboardingDialog
      // renderAsSheetOnMobile
      body={
        <Onboarding.Container>
          <Content>
            <span className="icon">
              <BookmarkFilledLargeIcon />
            </span>
            <div className="heading">
              {unwidow(__('Vocabulary saved', 'vocabularySaved'))}
            </div>
            {unwidow(
              __(
                'You’ll be able to test yourself on your saved vocabulary later.',
                'youllBeAbleToTestYourselfOnYourSavedVocabularyLater'
              )
            )}
          </Content>
        </Onboarding.Container>
      }
      onResolve={() => dismissCurrentTip()}
    />
  );
});
