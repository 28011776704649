import * as React from 'react';
import * as exitCopyLocales from './review-exit-copy';

import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { observer } from 'mobx-react';
import { useChapterReviewModel } from '../review-ui';
import { createLogger } from '@common/log';
import { AppFactory } from '@app/app-factory';
import { ProgressGaugeBar } from 'vocab-review/review-end-card/progress-gauge-bar';
import { ProgressCard } from 'vocab-review/review-end-card/progress-card';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { VocabReviewModel } from '@core/models/ui/vocab-review-model';

import bgImage from '../assets/bg.svg';

import __, { pickLocalizedValue } from '@core/lib/localization';

function getMessage(model: VocabReviewModel) {
  const localizedCopyItems = pickLocalizedValue(
    exitCopyLocales
  ) as exitCopyLocales.ExitMessageCopyTiers;

  const { sessionLearnedCount, anyItemsReviewed } = model;

  const isPerfect =
    anyItemsReviewed > 5 && sessionLearnedCount === anyItemsReviewed;
  const isBad = sessionLearnedCount === 0;

  const messageTier = isPerfect
    ? localizedCopyItems.perfect
    : isBad
    ? localizedCopyItems.consolation
    : localizedCopyItems.regular;

  const randomMessage =
    messageTier[Math.floor(Math.random() * messageTier.length)];

  return randomMessage;
}

const log = createLogger('review-exit-overlay');

const ReviewOverlayWrapper = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  backgroundColor: '$pink-500',
  backgroundImage: `url(${bgImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'opacity 0.3s ease-in-out',
  opacity: 0,
  display: 'flex',
  pointerEvents: 'none',
  variants: {
    visible: {
      true: {
        opacity: 1,
        pointerEvents: 'all',
      },
    },
  },
});

ReviewOverlayWrapper.defaultProps = {
  id: 'review-overlay',
};

const ContentWrapper = styled('div', {
  margin: '0 auto',
  width: 'min(472px, 100%)',
  // flex: 1,
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  paddingTop: 'var(--sat)',
  paddingBottom: 'var(--sab)',
  color: '$white',
  paddingInline: 16,

  '& .top': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 24,
    gap: 12,
    '& h1': {
      textStyle: 'small-caps',
      textAlign: 'center',
    },
    '& .details': {
      display: 'grid',
      gridAutoFlow: 'column',
      alignSelf: 'stretch',
      padding: '12px 24px',
      borderTop: '1px solid $white-alpha-20',
      borderBottom: '1px solid $white-alpha-20',
      gap: 12,
      '& dl': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& dt': {
          textStyle: 'small-heading',
        },
        '& dd': {
          textStyle: 'small-text',
        },
      },
    },
  },

  '& .middle': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .middle-wrapper': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,

      '& .gauge-card': {
        padding: '0 16px',
        background: '$colors$black-alpha-20',
        borderRadius: 16,
        backdropFilter: 'blur(8px)',

        '& .gauge-title': {
          padding: '40px 24px',
          color: '$colors$white',
          textStyle: 'medium-heading',
          textAlign: 'center',
        },

        '& .complete-or-resume': {
          display: 'flex',
          flexDirection: 'row',
          gap: 8,
          alignItems: 'center',
          justifyContent: 'center',
          height: 64,
          textStyle: 'small-text-bold',
        },
      },
    },
  },

  '& .bottom': {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 12,
    paddingBottom: 28,
    '@medium': {
      paddingBottom: 44,
    },
  },
});

export const ReviewExitOverlay = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const chapterReviewModel = useChapterReviewModel();
    const { vocabReviewModel } = chapterReviewModel;

    log.debug(
      `ReviewExitOverlay rendered - showing: ${String(
        vocabReviewModel.showingSummary
      )}`
    );

    const {
      sessionLearnedCount,
      sessionReviewAgainCount,
      sessionJustAddedCount,
      sessionPendingCount,
    } = vocabReviewModel;
    const itemsReviewed = sessionLearnedCount + sessionReviewAgainCount;

    const sessionPoints = itemsReviewed * 5;

    const { userData } = AppFactory.root.userManager;
    const { todayPoints, sevenDayPoints } = userData;
    const newTodayPoints = todayPoints + sessionPoints;
    const newSevenDayPoints = sevenDayPoints + sessionPoints;
    const message = getMessage(vocabReviewModel);

    const onDone = () => {
      const storyProgress = vocabReviewModel.story.progress;
      storyProgress.recordVocabReviewSession(itemsReviewed);
      if (!vocabReviewModel.reviewComplete) {
        // record current position to allow resuming later
        if (chapterReviewModel.endOfChapter) {
          const { nextChapterRef } = vocabReviewModel;
          storyProgress.saveVocabReviewPointer({
            ...nextChapterRef,
            sentenceId: null,
            sentenceAddress: 0,
          });
        } else {
          const chapterRef = vocabReviewModel.currentChapter?.chapterRef;
          const sentenceId = chapterReviewModel.currentSentenceElementId;
          const sentenceAddress =
            chapterReviewModel.currentSentenceElementAddress;
          storyProgress.saveVocabReviewPointer({
            ...chapterRef,
            sentenceId, // not currently relevant
            sentenceAddress,
          });
        }
      } else {
        storyProgress.clearVocabReviewPointer();
      }
      onDismiss();
    };

    return (
      <ReviewOverlayWrapper visible={vocabReviewModel.showingSummary}>
        <ContentWrapper>
          <div className="top"></div>
          <div className="middle">
            <div className="middle-wrapper">
              <div className="gauge-card">
                <div className="gauge-title">
                  <div>{message.Heading}</div>
                  <div>{message.Body}</div>
                </div>
                <div className="gauge-graph">
                  <ProgressGaugeBar
                    presentation={'dark'}
                    learned={sessionLearnedCount}
                    toReview={sessionReviewAgainCount}
                    added={sessionJustAddedCount}
                  />
                </div>
                <div className="complete-or-resume">
                  {vocabReviewModel.reviewComplete ? (
                    <>
                      <CheckmarkCircleIcon />
                      <div>{__('Review complete', 'reviewComplete')}</div>
                    </>
                  ) : (
                    <>
                      <div>
                        {__('%{n} not reviewed', 'nNotReviewed', {
                          n: sessionPendingCount,
                        })}
                      </div>
                      <div>
                        <Button
                          label={__('Resume', 'resume')}
                          presentation={'whiteDarken'}
                          size={'small'}
                          onClick={() => {
                            vocabReviewModel.hideSummary();
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <ProgressCard
                itemsReviewed={itemsReviewed}
                itemPoints={sessionPoints}
                today={newTodayPoints}
                last7Days={newSevenDayPoints}
              />
            </div>
          </div>
          <div className="bottom">
            <Button
              label={__('Done', 'done')}
              fullWidth
              presentation={'teal'}
              size={'extraLarge'}
              onClick={onDone}
            />
          </div>
        </ContentWrapper>
      </ReviewOverlayWrapper>
    );
  }
);
