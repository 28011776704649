import * as React from 'react';
import classNames from 'classnames';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
import { Link } from 'react-router-dom';
import { learnStorySlugPath } from 'components/nav/path-helpers';
import { BookmarkFilledSmallIcon } from '@naan/icons/bookmark-icon';

const Wrapper = styled(Link, {
  $$color: '$colors$purple-500',
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  // gap: 4,
  color: '$$color',
  textStyle: 'small-text-bold',
  '&.completed': {
    $$color: '$colors$textSecondary',
  },
  '&.disabled': {
    $$color: '$colors$gray-200',
    cursor: 'default',
  },
});

export const VocabStoryBadge = ({ story }: { story: Story }) => {
  const count = story.vocabCount;
  if (count === 0) {
    return null;
  }

  const link = learnStorySlugPath(story.slug);

  return (
    <Wrapper to={link} className={classNames('soundbites-badge')}>
      <BookmarkFilledSmallIcon />
      {count}
    </Wrapper>
  );
};
