import * as React from 'react';
import Media from 'react-media';
import { breakpoints } from 'naan/tokens/breakpoints';

export const TableWrapper: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ className, ...props }) => {
  return (
    <Media queries={breakpoints}>
      {matches => {
        if (matches.small) {
          return <table {...props} className="table" />;
        } else {
          return <div {...props} className="table" />;
        }
      }}
    </Media>
  );
};

export const Row: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ className, ...props }) => {
  return (
    <Media queries={breakpoints}>
      {matches => {
        if (matches.small) {
          return <tr {...props} className="tr" />;
        } else {
          return <div {...props} className="tr tr-small" />;
        }
      }}
    </Media>
  );
};

export const Cell: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ className, ...props }) => {
  return (
    <Media queries={breakpoints}>
      {matches => {
        if (matches.small) {
          return <td {...props} className="td" />;
        } else {
          return <div {...props} className="td td-small" />;
        }
      }}
    </Media>
  );
};

export const HeaderCell: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ className, ...props }) => {
  return (
    <Media queries={breakpoints}>
      {matches => {
        if (matches.small) {
          return <th {...props} className="th" />;
        } else {
          return <div {...props} className="th th-small" />;
        }
      }}
    </Media>
  );
};

export const HeaderContainer: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ className, ...props }) => {
  return (
    <Media queries={breakpoints}>
      {matches => {
        if (matches.small) {
          return <thead {...props} className="thead" />;
        } else {
          return <div {...props} className="thead" />;
        }
      }}
    </Media>
  );
};

export const BodyContainer: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ className, ...props }) => {
  return (
    <Media queries={breakpoints}>
      {matches => {
        if (matches.small) {
          return <tbody {...props} className="tbody" />;
        } else {
          return <div {...props} className="tbody" />;
        }
      }}
    </Media>
  );
};
