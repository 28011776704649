import * as React from 'react';
import { Root, Portal, Trigger } from '@radix-ui/react-dialog';

import { Overlay } from './dialog-overlay';
import { Content } from './dialog-content';

type RootProps = React.ComponentProps<typeof Root>;
type ContentProps = React.ComponentProps<typeof Content>;

type ContainerProps = { onDismiss: () => void } & Omit<
  RootProps,
  'onOpenChange'
> &
  Pick<ContentProps, 'css'>;

export const PureContainer: React.FC<
  React.PropsWithChildren<
    ContainerProps & { trigger: React.ReactNode; defaultOpen?: boolean }
  >
> = ({ open, onDismiss, children, trigger, defaultOpen, ...props }) => {
  const handleOpenChange = React.useCallback(
    (open: boolean) => {
      if (open === false) {
        onDismiss();
      }
    },
    [onDismiss]
  );

  return (
    <Root onOpenChange={handleOpenChange} defaultOpen={defaultOpen}>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        <Overlay css={{ zIndex: 1001 }}>
          <Content onInteractOutside={onDismiss} {...props}>
            {children}
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};
