import * as React from 'react';

export const BodyProxy: React.FC<
  React.PropsWithChildren<{
    className: string;
  }>
> = ({ className }) => {
  React.useEffect(() => {
    /// Adds the class to the body element when mounted
    document.body.classList.add(className);

    return () => {
      /// Removes the class from the body element when unmounted
      document.body.classList.remove(className);
    };
  }, [className]);

  return null;
};
