import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
// import { PlayerMode } from '@common/misc-types';
// import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
// import { SoundbitesChapterBadge } from 'components/stories/soundbite-story-badge';
import { keyframes, styled } from '@naan/stitches.config';
import { ChapterSoundbites } from './chapter-item-contents/chapter-soundbites';
import autoAnimate from '@formkit/auto-animate';
import { ChevronUpIcon } from '@naan/icons/chevron-icon';
import { Box } from '@naan/primitives/box';
import { useChapterPositionUiContext } from './chapter-item-contents/chapter-position-ui-model';
import { ChapterSoundbiteBadge } from './components/chapter-soundbite-badge';
import { ChapterItemContents } from './chapter-item-contents/chapter-item-contents';
import { CompletedCheckmark } from './components/completed-checkmark';
import { ChapterCompleteCard } from './chapter-item-contents/chapter-complete-card';
import { useChapterRowUiContext } from './chapter-item-contents/chapter-row-ui-model';

const spinOnceAnimation = keyframes({
  '0%': {
    transform: 'rotateX(90deg)',
  },
  '100%': {
    transform: 'rotateX(0deg)',
  },
});

const Accessory = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  '& .chevron': {
    color: '$gray-400',
    // run animation once
    animation: `${spinOnceAnimation} .2s linear`,
  },
});

// // unfactored this component. It has too many subtle variatons to make it worth having a separate component.
// const BadgeWrapper = styled('div', {
//   all: 'unset',
//   cursor: 'pointer',
//   display: 'flex',
//   alignItems: 'center',
//   color: '$gray-400',
//   textStyle: 'small-text-bold',
// });

export const Completed = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const positionUiModel = useChapterPositionUiContext();
    const expanded =
      positionUiModel.currentChapterPosition === chapter.position;
    const parent = React.useRef<HTMLDivElement>(null);
    const chapterRowModel = useChapterRowUiContext();

    React.useEffect(() => {
      const anyIncompleteSoundbites = chapter.hasAnyIncompleteSoundbite;
      if (anyIncompleteSoundbites) {
        chapterRowModel.setCurrentStage('soundbites');
      } else {
        chapterRowModel.setCurrentStage('study');
      }
    }, [chapter, chapterRowModel, expanded]);

    React.useEffect(() => {
      parent.current &&
        autoAnimate(parent.current, {
          duration: 150,
          easing: 'ease-out',
        });
    }, [parent]);

    return (
      <div style={{ overflowY: 'clip' }}>
        <div
          onClick={() => {
            positionUiModel.setChapterPosition(chapter.position);
          }}
        >
          <ChapterListHeader
            chapter={chapter}
            stationIcon={<CompletedCheckmark />}
            accessory={
              <Accessory>
                {expanded ? (
                  <div className="chevron">
                    <ChevronUpIcon />
                  </div>
                ) : (
                  <>
                    <ChapterSoundbiteBadge chapter={chapter} />
                  </>
                )}
              </Accessory>
            }
          />
        </div>
        <ChapterItemContents ref={parent}>
          {expanded ? (
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
            >
              <ChapterSoundbites chapter={chapter} />
              <ChapterCompleteCard chapter={chapter} />
            </Box>
          ) : null}
        </ChapterItemContents>
      </div>
    );
  }
);
